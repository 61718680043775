import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

//import './Pricingdummycss.css';
import Navbar from '../navbar';

import '../Resources/dashboard.css';
import Footer3 from '../../Supportingfiles/footer3';

function Bt_paidplans(){



    //SETTING PRICING VARIABLES
    //plan days
    const [plan_days, setplan_days] = useState(365);


    //DEFAULT PLAN DETAILS

    //BASIC PLAN DETAILS
    const [basic_plan_heading, setbasic_plan_heading] = useState("BASIC YEARLY PLAN");
    const [basic_plan_type, setbasic_plan_type] = useState("yearly");
    const [basic_plan_code, setbasic_plan_code] = useState("BASYEAR1");
    const [basic_plan_trans, setbasic_plan_trans] = useState(12000);
    const [basic_plan_business, setbasic_plan_business] = useState(1);
    const [basic_plan_counters, setbasic_plan_counters] = useState(2);
    const [basic_plan_team, setbasic_plan_team] = useState(3);
    const [basic_plan_customers, setbasic_plan_customers] = useState(10000);
    const [basic_plan_suppliers, setbasic_plan_suppliers] = useState(10000);
    const [basic_plan_products, setbasic_plan_products] = useState(10000);
    const [basic_plan_mrp, setbasic_plan_mrp] = useState(3999);
    const [basic_plan_sp, setbasic_plan_sp] = useState(1999);

    //STANDARD PLAN DETAILS
    const [standard_plan_heading, setstandard_plan_heading] = useState("STANDARD YEARLY PLAN");
    const [standard_plan_type, setstandard_plan_type] = useState("yearly");
    const [standard_plan_code, setstandard_plan_code] = useState("STANYEAR1");
    const [standard_plan_trans, setstandard_plan_trans] = useState(50000);
    const [standard_plan_business, setstandard_plan_business] = useState(3);
    const [standard_plan_counters, setstandard_plan_counters] = useState(9);
    const [standard_plan_team, setstandard_plan_team] = useState(12);
    const [standard_plan_customers, setstandard_plan_customers] = useState(30000);
    const [standard_plan_suppliers, setstandard_plan_suppliers] = useState(30000);
    const [standard_plan_products, setstandard_plan_products] = useState(30000);
    const [standard_plan_mrp, setstandard_plan_mrp] = useState(4999);
    const [standard_plan_sp, setstandard_plan_sp] = useState(2499);

    //PREMIUM PLAN DETAILS
    const [premium_plan_heading, setpremium_plan_heading] = useState("PREMIUM YEARLY PLAN");
    const [premium_plan_type, setpremium_plan_type] = useState("yearly");
    const [premium_plan_code, setpremium_plan_code] = useState("PREMYEAR1");
    const [premium_plan_trans, setpremium_plan_trans] = useState(150000);
    const [premium_plan_business, setpremium_plan_business] = useState(7);
    const [premium_plan_counters, setpremium_plan_counters] = useState(25);
    const [premium_plan_team, setpremium_plan_team] = useState(42);
    const [premium_plan_customers, setpremium_plan_customers] = useState(100000);
    const [premium_plan_suppliers, setpremium_plan_suppliers] = useState(100000);
    const [premium_plan_products, setpremium_plan_products] = useState(100000);
    const [premium_plan_mrp, setpremium_plan_mrp] = useState(7199);
    const [premium_plan_sp, setpremium_plan_sp] = useState(3599);

    //SETTING PLAN DETAILS FUNCTION START
    function updateplans(){

    const input_plan_days = document.getElementById("plan-input").value;
    //YEARLY PLAN
    if(input_plan_days==365){

    //Update Basic Plan Details
    setbasic_plan_heading("BASIC YEARLY PLAN"); setbasic_plan_type("yearly"); setbasic_plan_code("BASYEAR1"); setbasic_plan_trans(12000); setbasic_plan_business(1); setbasic_plan_counters(2); setbasic_plan_team(3); setbasic_plan_customers(10000); setbasic_plan_suppliers(10000); setbasic_plan_products(10000); setbasic_plan_mrp(3999); setbasic_plan_sp(1999);
    //Update Standard Plan Details
    setstandard_plan_heading("STANDARD YEARLY PLAN"); setstandard_plan_type("yearly"); setstandard_plan_code("STANYEAR1"); setstandard_plan_trans(50000); setstandard_plan_business(3); setstandard_plan_counters(9); setstandard_plan_team(12); setstandard_plan_customers(30000); setstandard_plan_suppliers(30000); setstandard_plan_products(30000); setstandard_plan_mrp(4999); setstandard_plan_sp(2499);
    //Update Premium Plan Details
    setpremium_plan_heading("PREMIUM YEARLY PLAN"); setpremium_plan_type("yearly"); setpremium_plan_code("PREMYEAR1"); setpremium_plan_trans(150000); setpremium_plan_business(7); setpremium_plan_counters(25); setpremium_plan_team(42); setpremium_plan_customers(100000); setpremium_plan_suppliers(100000); setpremium_plan_products(100000); setpremium_plan_mrp(7199); setpremium_plan_sp(3599);

    }

     //HALF YEARLY PLAN
     if(input_plan_days==180){

        //Update Basic Plan Details
        setbasic_plan_heading("BASIC HALF YEARLY PLAN"); setbasic_plan_type("half-yearly"); setbasic_plan_code("BASHYEAR1"); setbasic_plan_trans(6000); setbasic_plan_business(1); setbasic_plan_counters(2); setbasic_plan_team(3); setbasic_plan_customers(5000); setbasic_plan_suppliers(5000); setbasic_plan_products(5000); setbasic_plan_mrp(2799); setbasic_plan_sp(1399);
        //Update Standard Plan Details
        setstandard_plan_heading("STANDARD HALF YEARLY PLAN"); setstandard_plan_type("half-yearly"); setstandard_plan_code("STANHYEAR1"); setstandard_plan_trans(25000); setstandard_plan_business(3); setstandard_plan_counters(9); setstandard_plan_team(12); setstandard_plan_customers(15000); setstandard_plan_suppliers(15000); setstandard_plan_products(15000); setstandard_plan_mrp(3599); setstandard_plan_sp(1799);
        //Update Premium Plan Details
        setpremium_plan_heading("PREMIUM HALF YEARLY PLAN"); setpremium_plan_type("half-yearly"); setpremium_plan_code("PREMHYEAR1"); setpremium_plan_trans(75000); setpremium_plan_business(7); setpremium_plan_counters(25); setpremium_plan_team(42); setpremium_plan_customers(50000); setpremium_plan_suppliers(50000); setpremium_plan_products(50000); setpremium_plan_mrp(4999); setpremium_plan_sp(2499);
    
    }

     //QUARTERLY YEARLY PLAN
     if(input_plan_days==90){

        //Update Basic Plan Details
        setbasic_plan_heading("BASIC QUARTERLY PLAN"); setbasic_plan_type("quarterly"); setbasic_plan_code("BASQUART1"); setbasic_plan_trans(3000); setbasic_plan_business(1); setbasic_plan_counters(2); setbasic_plan_team(3); setbasic_plan_customers(3000); setbasic_plan_suppliers(3000); setbasic_plan_products(3000); setbasic_plan_mrp(1799); setbasic_plan_sp(899);
        //Update Standard Plan Details
        setstandard_plan_heading("STANDARD QUARTERLY PLAN"); setstandard_plan_type("quarterly"); setstandard_plan_code("STANQUART1"); setstandard_plan_trans(12000); setstandard_plan_business(3); setstandard_plan_counters(9); setstandard_plan_team(12); setstandard_plan_customers(7500); setstandard_plan_suppliers(7500); setstandard_plan_products(7500); setstandard_plan_mrp(2399); setstandard_plan_sp(1199);
        //Update Premium Plan Details
        setpremium_plan_heading("PREMIUM QUARTERLY PLAN"); setpremium_plan_type("quarterly"); setpremium_plan_code("PREMQUART1"); setpremium_plan_trans(36000); setpremium_plan_business(7); setpremium_plan_counters(25); setpremium_plan_team(42); setpremium_plan_customers(24000); setpremium_plan_suppliers(24000); setpremium_plan_products(24000); setpremium_plan_mrp(3599); setpremium_plan_sp(1799);
    
    }

    //MONTHLY PLAN
    if(input_plan_days==30){

       //Update Basic Plan Details
       setbasic_plan_heading("BASIC MONTHLY PLAN"); setbasic_plan_type("monthly"); setbasic_plan_code("BASMONTH1"); setbasic_plan_trans(1000); setbasic_plan_business(1); setbasic_plan_counters(2); setbasic_plan_team(3); setbasic_plan_customers(1000); setbasic_plan_suppliers(1000); setbasic_plan_products(1000); setbasic_plan_mrp(799); setbasic_plan_sp(399);
       //Update Standard Plan Details
       setstandard_plan_heading("STANDARD MONTHLY PLAN"); setstandard_plan_type("monthly"); setstandard_plan_code("STANMONTH1"); setstandard_plan_trans(4000); setstandard_plan_business(3); setstandard_plan_counters(9); setstandard_plan_team(12); setstandard_plan_customers(2500); setstandard_plan_suppliers(2500); setstandard_plan_products(2500); setstandard_plan_mrp(999); setstandard_plan_sp(499);
       //Update Premium Plan Details
       setpremium_plan_heading("PREMIUM MONTHLY PLAN"); setpremium_plan_type("monthly"); setpremium_plan_code("PREMMONTH1"); setpremium_plan_trans(12000); setpremium_plan_business(7); setpremium_plan_counters(25); setpremium_plan_team(42); setpremium_plan_customers(8000); setpremium_plan_suppliers(8000); setpremium_plan_products(8000); setpremium_plan_mrp(1999); setpremium_plan_sp(999);
        
       }


    }
    //SETTING PLAN DETAILS FUNCTION END



      return(
      <>


<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Get your startup services relating to website development, consultancy, taxation, business registration, software services, intern hiring, social media management, digital marketing and much more.  "></meta>
    
   {/* <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />*/}
        <link rel="icon" href="./images/startupconnect_icon.ico" />
    <link rel="apple-touch-icon" href="./images/startupconnect_icon.png" />

      {/* plugins:css */}
      <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="./images/startupconnect_icon.png" />


    {/*} Bootstrap CSS */}
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />
 
    
    <title>Startup Connect | Business Tools and Services</title>
  </head>
  <body>
{/*}
<h1>New Body Heading</h1>
<h2>No one here</h2>
<h3>Go oemme</h3>
<h4>OKNSSNMNJ djc hehrhrh </h4>
<h5>jguy uugyr yfr645</h5>
<h6>jhuyt 6565dx uty565d45d343</h6>
<p>jhdwuicyu GGY yfydddfl</p>
*/}

<div class="container-scroller">

<div class="container-fluid page-body-wrapper">

  
 
    {/*} partial */}
    <div class="main-panel">

    <div class="content-wrapper pb-0">


    <div class="row">

    <h1><b>Business Tools Pricing</b></h1>
    <p>Plans that suites the need of individual business owners, Retail and chain stores and large enterprises with multiple businesses.</p>


                        {/* INDIVIDUAL PLAN CARDS START */}
                        <div className="col-sm-12 stretch-card grid-margin" style={{marginTop:"5rem"}}>
                        <div className="card" id="individaul-tag">
                       <div className="card-body">
               
                   {/*}   <h3>Individual Plans</h3>
                      <p>Best for Small and Medium Sized Businesses with a single shop and counter with moderate customer footfall.</p>

                   <hr />*/}

                      <div class="row d-flex justify-content-right">
                      <div class="dropdown">
                        <button class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuOutlineButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Select Plan Duration</button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuOutlineButton1">
                          <h6 class="dropdown-header">Select Plan Duration</h6>
                          <a class="dropdown-item" onClick={()=>{ setplan_days(365); document.getElementById("plan-input").value=365; updateplans(); }}>365 Days (Yearly)</a>
                          <a class="dropdown-item" onClick={()=>{ setplan_days(180); document.getElementById("plan-input").value=180; updateplans(); }}>180 Days (Half Yearly)</a>
                          <a class="dropdown-item" onClick={()=>{ setplan_days(90); document.getElementById("plan-input").value=90; updateplans(); }}>90 Days (Quarterly)</a>
                          <a class="dropdown-item" onClick={()=>{ setplan_days(30); document.getElementById("plan-input").value=30; updateplans(); }}>30 Days (Monthly)</a>
                        </div>
                      </div> 
                      </div>


                      <div className="row ">

                   
                     

                        {/* PRICING CARDS SECTION SATRT */}
                        <div id="individaul-28days" class="row col-12 grid-margin d-flex justify-content-center">

                        {/* BASIC PLAN CARD START */}
                        <div id="i-basic-28" className="col-md-3"  style={{marginTop:"3rem", border:"0.2rem solid #0078b5", boxShadow:"1px 1px 1px 2px #c6cbcd",borderRadius:"15px",padding:"1rem"}}>
                        <div style={{borderBottom:" 0.2rem solid #0078b5", color:"#0078b5", borderRadius:"15px",padding:"0.5rem"}}>
                        <h4><b>BOOK-KEEPING BASIC</b></h4>
                        <p style={{color:"#000000"}}>{basic_plan_type} plan ({plan_days} days)<br/>
                        <span class="text-muted">#{basic_plan_code}</span></p>
                        </div><div><div></div>
                        <ul style={{textAlign:"left",fontSize:"105%", listStyleType:"none"}}>      
                        <br/>           
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>{basic_plan_trans} Transactions</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>{basic_plan_business} Active Business</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>{basic_plan_counters} Active Counter</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>{basic_plan_team} Team Accounts</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>{basic_plan_customers} Customers in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>{basic_plan_suppliers} Suppliers in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>{basic_plan_products} Products in List Access</li> 
                        <li><button class="btn btn-light" id="basic-view-features-btn" style={{backgroundColor:"#ffffff"}} onClick={()=>{
                            document.getElementById("basic-view-features-btn").style.display="none";
                            document.getElementById("basic-hide-features-btn").style.display="block";
                            document.getElementById("basic-all-features").style.display="block";
                        }}><p class="text-primary"> view all features...</p></button></li>
                        <div id="basic-all-features"  style={{display:"none"}}>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Create and Manage Invoices</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Cash Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Multiple Bank Ledger Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Contra Entries</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Incomes and Expenses Management</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Record and Manage Outstanding Incomes and Expenses</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Debtors Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Creditors Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Record and Manage Sales, Purchases and Returns</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>GST and Tax Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>GSTIN wise Tax Summary</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Financial Analysis</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Product Groups in Catalog</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Interactive Charts for Data Visualization</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Multiple Filters in Reports</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Transactions and Updation Summaries</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Generate QR code for products in catalog</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Automated Double Entry</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Export Data as pdf or excel</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Manage transactions online</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#0078b5", fontSize:"110%",marginRight:"1rem"}}></i>Manage from any device any time connected over internet</li> 
                        <li><button class="btn btn-light" id="basic-hide-features-btn" style={{display:"none", backgroundColor:"#ffffff"}}  onClick={()=>{
                            document.getElementById("basic-view-features-btn").style.display="block";
                            document.getElementById("basic-hide-features-btn").style.display="none";
                            document.getElementById("basic-all-features").style.display="none";
                        }}><p class="text-primary"> hide features...</p></button></li>
                        </div>
                        </ul>
                        <hr />
                        <h4 style={{color:"#18728b"}}><b>₹ {basic_plan_sp}/- for {plan_days} days</b></h4>
                        <label style={{textDecoration:"line-through"}}>₹ {basic_plan_mrp}/- for {plan_days} days</label>
                        <br/>
                        <p style={{textAlign:"right",color:"#0091b9"}}><b>50% off</b></p>
                        <hr/>
                        <button class="btn btn-lg btn-info" style={{backgroundColor:"#0078b5",border:"none"}} onClick={()=>{
                        
                        // Assign value to pricingplan
                        sessionStorage.setItem("pricingplan00", basic_plan_code); sessionStorage.setItem("planname00", basic_plan_heading);
                        window.location.replace("/businesstools-user-paidplanpage");
                       }}><b>BUY NOW</b></button>
                        </div>
                        </div>
                          {/* BASIC PLAN PLAN CARD END */}


                        {/* STANDARD PLAN CARD START */}
                        <div id="i-basic-28" className="col-md-3"  style={{marginTop:"3rem", border:"0.2rem solid #26c78c", boxShadow:"1px 1px 1px 2px #c6cbcd",borderRadius:"15px",padding:"1rem"}}>
                        <div style={{borderBottom:" 0.2rem solid #26c78c", color:"#26c78c", borderRadius:"15px",padding:"0.5rem"}}>
                        <h4><b>BOOK-KEEPING STANDARD</b></h4>
                        <p style={{color:"#000000"}}>{standard_plan_type} plan ({plan_days} days)<br/>
                        <span class="text-muted">#{standard_plan_code}</span></p>
                        </div><div><div></div>
                        <ul style={{textAlign:"left",fontSize:"105%", listStyleType:"none"}}>      
                        <br/>           
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>{standard_plan_trans} Transactions</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>{standard_plan_business} Active Business</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>{standard_plan_counters} Active Counter</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>{standard_plan_team} Team Accounts</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>{standard_plan_customers} Customers in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>{standard_plan_suppliers} Suppliers in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>{standard_plan_products} Products in List Access</li> 
                        <li><button class="btn btn-light" id="standard-view-features-btn" style={{backgroundColor:"#ffffff"}} onClick={()=>{
                            document.getElementById("standard-view-features-btn").style.display="none";
                            document.getElementById("standard-hide-features-btn").style.display="block";
                            document.getElementById("standard-all-features").style.display="block";
                        }}><p class="text-primary"> view all features...</p></button></li>
                        <div id="standard-all-features"  style={{display:"none"}}>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Create and Manage Invoices</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Cash Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Multiple Bank Ledger Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Contra Entries</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Incomes and Expenses Management</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Record and Manage Outstanding Incomes and Expenses</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Debtors Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Creditors Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Record and Manage Sales, Purchases and Returns</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>GST and Tax Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>GSTIN wise Tax Summary</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Financial Analysis</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Product Groups in Catalog</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Interactive Charts for Data Visualization</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Multiple Filters in Reports</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Transactions and Updation Summaries</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Generate QR code for products in catalog</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Automated Double Entry</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Export Data as pdf or excel</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Manage transactions online</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>Manage from any device any time connected over internet</li> 
                        <li><button class="btn btn-light" id="standard-hide-features-btn" style={{display:"none", backgroundColor:"#ffffff"}}  onClick={()=>{
                            document.getElementById("standard-view-features-btn").style.display="block";
                            document.getElementById("standard-hide-features-btn").style.display="none";
                            document.getElementById("standard-all-features").style.display="none";
                        }}><p class="text-primary"> hide features...</p></button></li>
                        </div>
                        </ul>
                        <hr />
                        <h4 style={{color:"#26c78c"}}><b>₹ {standard_plan_sp}/- for {plan_days} days</b></h4>
                        <label style={{textDecoration:"line-through"}}>₹ {standard_plan_mrp}/- for {plan_days} days</label>
                        <br/>
                        <p style={{textAlign:"right",color:"#26c78c"}}><b>50% off</b></p>
                        <hr/>
                        <button class="btn btn-lg btn-info" style={{backgroundColor:"#26c78c",border:"none"}} onClick={()=>{
                        
                        // Assign value to pricingplan
                        sessionStorage.setItem("pricingplan00", standard_plan_code); sessionStorage.setItem("planname00", standard_plan_heading);
                        window.location.replace("/businesstools-user-paidplanpage");
                       }}><b>BUY NOW</b></button>
                        </div>
                        </div>
                          {/* STANDARD PLAN PLAN CARD END */}

                        {/* PREMIUM PLAN CARD START */}
                        <div id="i-basic-28" className="col-md-3"  style={{marginTop:"3rem", border:"0.2rem solid #f49938", boxShadow:"1px 1px 1px 2px #c6cbcd",borderRadius:"15px",padding:"1rem"}}>
                        <div style={{borderBottom:" 0.2rem solid #f49938", color:"#f49938", borderRadius:"15px",padding:"0.5rem"}}>
                        <h4><b>BOOK-KEEPING PREMIUM</b></h4>
                        <p style={{color:"#000000"}}>{premium_plan_type} plan ({plan_days} days)<br/>
                        <span class="text-muted">#{premium_plan_code}</span></p>
                        </div><div><div></div>
                        <ul style={{textAlign:"left",fontSize:"105%", listStyleType:"none"}}>      
                        <br/>           
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>{premium_plan_trans} Transactions</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>{premium_plan_business} Active Business</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>{premium_plan_counters} Active Counter</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>{premium_plan_team} Team Accounts</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>{premium_plan_customers} Customers in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>{premium_plan_suppliers} Suppliers in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>{premium_plan_products} Products in List Access</li> 
                        <li><button class="btn btn-light" id="premium-view-features-btn" style={{backgroundColor:"#ffffff"}} onClick={()=>{
                            document.getElementById("premium-view-features-btn").style.display="none";
                            document.getElementById("premium-hide-features-btn").style.display="block";
                            document.getElementById("premium-all-features").style.display="block";
                        }}><p class="text-primary"> view all features...</p></button></li>
                        <div id="premium-all-features"  style={{display:"none"}}>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Create and Manage Invoices</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Cash Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Multiple Bank Ledger Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Contra Entries</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Incomes and Expenses Management</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Record and Manage Outstanding Incomes and Expenses</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Debtors Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Creditors Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Record and Manage Sales, Purchases and Returns</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>GST and Tax Management</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>GSTIN wise Tax Summary</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Financial Analysis</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Product Groups in Catalog</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Interactive Charts for Data Visualization</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Multiple Filters in Reports</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Transactions and Updation Summaries</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Generate QR code for products in catalog</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Automated Double Entry</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Export Data as pdf or excel</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Manage transactions online</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#f49938", fontSize:"110%",marginRight:"1rem"}}></i>Manage from any device any time connected over internet</li> 
                        <li><button class="btn btn-light" id="premium-hide-features-btn" style={{display:"none", backgroundColor:"#ffffff"}}  onClick={()=>{
                            document.getElementById("premium-view-features-btn").style.display="block";
                            document.getElementById("premium-hide-features-btn").style.display="none";
                            document.getElementById("premium-all-features").style.display="none";
                        }}><p class="text-primary"> hide features...</p></button></li>
                        </div>
                        </ul>
                        <hr />
                        <h4 style={{color:"#f49938"}}><b>₹ {premium_plan_sp}/- for {plan_days} days</b></h4>
                        <label style={{textDecoration:"line-through"}}>₹ {premium_plan_mrp}/- for {plan_days} days</label>
                        <br/>
                        <p style={{textAlign:"right",color:"#f49938"}}><b>50% off</b></p>
                        <hr/>
                        <button class="btn btn-lg btn-info" style={{backgroundColor:"#f49938",border:"none"}} onClick={()=>{
                        
                        // Assign value to pricingplan
                        sessionStorage.setItem("pricingplan00", premium_plan_code); sessionStorage.setItem("planname00", premium_plan_heading);
                        window.location.replace("/businesstools-user-paidplanpage");
                       }}><b>BUY NOW</b></button>
                        </div>
                        </div>
                          {/* PREMIUM PLAN PLAN CARD END */}

                      
                     {/*}   <p>Currently Showing for {ip_days} Days Plan (all prices excluding GST)</p> */}

                    
                        </div>
                       {/* PRICING CARDS SECTION END */}
                        </div>
                        </div>
                        </div>
                        </div>
                        {/* INDIVIDUAL PLAN CARDS END */}





    </div>
    </div>
                        
    <input type="hidden" value="365" id="plan-input" />
    

      </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    <Footer3 />
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
      );

}

export default Bt_paidplans;