import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';


import '../Resources/dashboard.css';
import Navbar from '../navbar';
import Sidebar from '../Sidebar';
import Create_salesreturn from './create_salesreturn';

import Chart from "chart.js/auto";
import { Line, Bar, Pie } from "react-chartjs-2";

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';

//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function UserSalesreturn(){


 //Report Print Function
 const componentRef = useref();
 const handlePrint = useReactToPrint({
   content: () => componentRef.current,
 });
  
 
  // Function to download the data as an Excel file
  const downloadExcel = () => {
    // Convert JSON data to Excel worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    
    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
    // Convert the workbook to a binary array
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    
    // Create a Blob from the binary data and trigger a download
    const excel_data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(excel_data, "salesreturn_data.xlsx");
  };
  
  
  
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");


 
 

 {/* SALESRETURN TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
{/* SALESRETURN TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES END */}


 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 /*if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{*/


    
    
      
//Variables
const[ecustomers,setecustomers] = useState([],);//SetData Variable
const[ecdefault,setecdefault] = useState(0);
const[banks,setbanks] = useState([]);//SetData Variable
const[data,setData] = useState([]);//SetData Variable

//Error Variable
const[errorvar,seterrorvar] = useState();



//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[filter_start_date,setfilter_start_date] = useState('1970-01-01'); const updatefilter_start_date = (event) => { setfilter_start_date(event.target.value);}; const[filter_start_date_er,setfilter_start_date_er]=useState();
const[filter_end_date,setfilter_end_date] = useState('2100-12-12'); const updatefilter_end_date = (event) => { setfilter_end_date(event.target.value);}; const[filter_end_date_er,setfilter_end_date_er]=useState();
const[filter_trno,setfilter_trno] = useState(); const updatefilter_trno = (event) => { setfilter_trno(event.target.value);}; const[filter_trno_er,setfilter_trno_er]=useState();
const[filter_type,setfilter_type] = useState(); const updatefilter_type = (event) => { setfilter_type(event.target.value);}; const[filter_type_er,setfilter_type_er]=useState();
const[filter_amount_sign,setfilter_amount_sign] = useState(); const updatefilter_amount_sign = (event) => { setfilter_amount_sign(event.target.value);}; const[filter_amount_sign_er,setfilter_amount_sign_er]=useState();
const[filter_amount,setfilter_amount] = useState(); const updatefilter_amount = (event) => { setfilter_amount(event.target.value);}; const[filter_amount_er,setfilter_amount_er]=useState();
const[filter_voucher_no,setfilter_voucher_no] = useState(); const updatefilter_voucher_no = (event) => { setfilter_voucher_no(event.target.value);}; const[filter_voucher_no_er,setfilter_voucher_no_er]=useState();
const[filter_gstin,setfilter_gstin] = useState(); const updatefilter_gstin = (event) => { setfilter_gstin(event.target.value);}; const[filter_gstin_er,setfilter_gstin_er]=useState();
const[filter_customer_name,setfilter_customer_name] = useState(); const updatefilter_customer_name = (event) => { setfilter_customer_name(event.target.value);}; const[filter_customer_name_er,setfilter_customer_name_er]=useState();


//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

useEffect(()=>{
   
  getData();
  
  },[])
 

 //SETTING DEFAULT STATE VARIABLES FOR CHART */
 
 //LINECHART VARIABLES
 const[lc_summary,setlc_summary] = useState([],);
 const[lc_amount,setlc_amount] = useState([],);
 /*const[lc_outflow,setlc_outflow] = useState([],);
 const[lc_netcashflow,setlc_netcashflow] = useState([],);*/

 //Line Chart
 const[lc_date,setlc_date] = useState();
 const[lc_default,setlc_default] = useState(0);
 const[lc_start_date,setlc_start_date] = useState(0);const updatelc_start_date = (event) => { setlc_start_date(event.target.value); }; 
 const[lc_end_date,setlc_end_date] = useState(0);const updatelc_end_date = (event) => { setlc_end_date(event.target.value); }; 
 const[lc_date_error,setlc_date_error] = useState();

 //Pie Chart
 const[pc_default,setpc_default] = useState(0);
 const[pc_start_date,setpc_start_date] = useState(0);const updatepc_start_date = (event) => { setpc_start_date(event.target.value); }; 
 const[pc_end_date,setpc_end_date] = useState(0);const updatepc_end_date = (event) => { setpc_end_date(event.target.value); }; 
 const[pc_date_error,setpc_date_error] = useState();
 const[pc_cash,setpc_cash] = useState();
 const[pc_bank,setpc_bank] = useState();
 const[pc_credit,setpc_credit] = useState();

    
//******************* GET SALESRETURN DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
    
  //Fetching Filter Variables
  const $filter_start_date = filter_start_date;const $filter_end_date = filter_end_date;const $filter_trno = filter_trno;const $filter_type = filter_type;
  const $filter_amount_sign = filter_amount_sign;const $filter_amount = filter_amount;const $filter_voucher_no = filter_voucher_no;const $filter_gstin = filter_gstin;const $filter_customer_name = filter_customer_name;
   

 axios.post("bt-get-salesreturn-default", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0,
  //Line Chart
  lc_default:lc_default,
  lc_start_date:moment(lc_start_date).format('YYYY-MM-DD'),
  lc_end_date:moment(lc_end_date).format('YYYY-MM-DD'),
  //Bar Chart
  pc_default:pc_default,
  pc_start_date:moment(pc_start_date).format('YYYY-MM-DD'),
  pc_end_date:moment(pc_end_date).format('YYYY-MM-DD'),
   //Filters
   Filter_val : filter_val,
   Filter_start_date : moment($filter_start_date).format('YYYY-MM-DD'),
   Filter_end_date : moment($filter_end_date).format('YYYY-MM-DD'),
   Filter_trno : $filter_trno,
   Filter_type : $filter_type,
   Filter_amount_sign : $filter_amount_sign,
   Filter_amount : $filter_amount,
   Filter_voucher_no : $filter_voucher_no,
   Filter_gstin : $filter_gstin,
   Filter_customer_name : $filter_customer_name,
     
   //Code Edits for pagination
   'page' : pageNumber

 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setbanks(response.data.banks);
   setData(response.data.data.data);
   const lc_summary = response.data.lc_summary;
   // Extract data for line chart
   setlc_date(lc_summary.map((entry) => entry.Date));
   setlc_amount(lc_summary.map((entry) => entry.Amount));
   //setlc_outflow(lc_summary.map((entry) => entry.Outflow)); 
   //extract data for bar chart
   setpc_credit(response.data.pc_credit);
   setpc_bank(response.data.pc_bank);
   setpc_cash(response.data.pc_cash);
  
  }
   //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    setfilter_start_date_er(response.data.validatorerror.Filter_start_date);
    setfilter_end_date_er(response.data.validatorerror.Filter_end_date);
    setfilter_trno_er(response.data.validatorerror.Filter_trno);
    setfilter_type_er(response.data.validatorerror.Filter_type);
    setfilter_amount_sign_er(response.data.validatorerror.Filter_amount_sign);
    setfilter_amount_er(response.data.validatorerror.Filter_amount);
    setfilter_voucher_no_er(response.data.validatorerror.Filter_voucher_no);
    setfilter_gstin_er(response.data.validatorerror.Filter_gstin);
    setfilter_customer_name_er(response.data.validatorerror.Filter_customer_name);
  
  }

  //Data Violation
  if(response.data.error==102){ 
  
    setfilter_start_date_er(response.data.filter_start_date_er);
    setfilter_end_date_er(response.data.filter_end_date_er);
    setfilter_trno_er(response.data.filter_trno_er);
    setfilter_type_er(response.data.filter_type_er);
    setfilter_amount_sign_er(response.data.filter_amount_sign_er);
    setfilter_amount_er(response.data.filter_amount_er);
    setfilter_voucher_no_er(response.data.filter_voucher_no_er);
    setfilter_gstin_er(response.data.filter_gstin_er);
    setfilter_customer_name_er(response.data.filter_customer_name_er);
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET SALESRETURN DATA FUNCTION END  ********************//




 /* LINE CHART START */
 const data1 = {
  labels: lc_date,
  datasets: [
    {
      label: "Transaction Summary",
      backgroundColor: ["rgb(255, 99, 132)" ],
      borderColor: "rgb(255, 99, 132)",
      data: lc_amount,
    },
   /* {
      label: "Bank to Cash",
      backgroundColor: ["rgb(54, 160, 255)"],
      borderColor: "rgb(54, 160, 255)",
      data: lc_inflow,
    },
    {
      label: "Cash to Bank",
      backgroundColor: ["rgb(54, 160, 255)"],
      borderColor: "rgb(54, 160, 255)",
      data: lc_inflow,
    },*/
  ],
};
/* LINE CHART END */ 

  /* PIE CHART VARIABLES START */
const data2 = {
  labels: ["cash", "bank", "credit"],
  datasets: [
    {
      label: "# transactions summary",
      data: [pc_cash, pc_bank, pc_credit],
      backgroundColor: [ "#3fe077", "#0b3190", "#d76721"],
      borderColor: [ "#ffffff", "#ffffff", "#ffffff"],
      borderWidth: 1,
    },
  ],
};
  /* PIE CHART VARIABLES END */
 
  
//******************* GET CUSTOMER DATA BY NAME FUNCTION START  ********************//
function getecustomerbyname(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
     const ecname = document.getElementById("ecustomer_name").value;
     const ecnlength = ecname.length;
     if((ecdefault==0)&&(ecnlength>=3)){
     
 
 axios.post("bt-get-customers-spr", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Name:document.getElementById("ecustomer_name").value,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setecustomers(response.data.customers);
   document.getElementById("edit-customers-list-box").style.display="block";
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
}
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }

 }
  
 //******************* GET CUSTOMER DATA BY NAME FUNCTION END  ********************//

 
 
  //******************* GET SALESRETURN DATA BY ID FUNCTION START  ********************//



//edate
const[edate,setedate]=useState();const updateedate = (event) => { setedate(event.target.value);}; const[edate_er,setedate_er]=useState();
//etype
const[etype,setetype]=useState();const updateetype = (event) => { setetype(event.target.value);}; const[etype_er,setetype_er]=useState();
//evoucher_no
const[evoucher_no,setevoucher_no]=useState();const updateevoucher_no = (event) => { setevoucher_no(event.target.value);}; const[evoucher_no_er,setevoucher_no_er]=useState();
//egstin
const[egstin,setegstin]=useState();const updateegstin = (event) => { setegstin(event.target.value);}; const[egstin_er,setegstin_er]=useState();
//elicense_no
const[elicense_no,setelicense_no]=useState();const updateelicense_no = (event) => { setelicense_no(event.target.value);}; const[elicense_no_er,setelicense_no_er]=useState();
//ebank_id
const[ebank_id,setebank_id]=useState();const updateebank_id = (event) => { setebank_id(event.target.value);}; const[ebank_id_er,setebank_id_er]=useState();
//ebank_name
const[ebank_name,setebank_name]=useState();const updateebank_name = (event) => { setebank_name(event.target.value);}; const[ebank_name_er,setebank_name_er]=useState();
//ecustomer_id
const[ecustomer_id,setecustomer_id]=useState();const updateecustomer_id = (event) => { setecustomer_id(event.target.value);}; const[ecustomer_id_er,setecustomer_id_er]=useState();
//ecustomer_name
const[ecustomer_name,setecustomer_name]=useState();const updateecustomer_name = (event) => { setecustomer_name(event.target.value);getecustomerbyname(); }; const[ecustomer_name_er,setecustomer_name_er]=useState();
//ecustomer_contact
const[ecustomer_contact,setecustomer_contact]=useState();const updateecustomer_contact = (event) => { setecustomer_contact(event.target.value);}; const[ecustomer_contact_er,setecustomer_contact_er]=useState();
//ecustomer_wtp
const[ecustomer_wtp,setecustomer_wtp]=useState();const updateecustomer_wtp = (event) => { setecustomer_wtp(event.target.value);}; const[ecustomer_wtp_er,setecustomer_wtp_er]=useState();
//ecustomer_email
const[ecustomer_email,setecustomer_email]=useState();const updateecustomer_email = (event) => { setecustomer_email(event.target.value);}; const[ecustomer_email_er,setecustomer_email_er]=useState();
//ecustomer_address
const[ecustomer_address,setecustomer_address]=useState();const updateecustomer_address = (event) => { setecustomer_address(event.target.value);}; const[ecustomer_address_er,setecustomer_address_er]=useState();
//ecustomer_city
const[ecustomer_city,setecustomer_city]=useState();const updateecustomer_city = (event) => { setecustomer_city(event.target.value);}; const[ecustomer_city_er,setecustomer_city_er]=useState();
//ecustomer_state
const[ecustomer_state,setecustomer_state]=useState();const updateecustomer_state = (event) => { setecustomer_state(event.target.value);}; const[ecustomer_state_er,setecustomer_state_er]=useState();
//ecustomer_country
const[ecustomer_country,setecustomer_country]=useState();const updateecustomer_country = (event) => { setecustomer_country(event.target.value);}; const[ecustomer_country_er,setecustomer_country_er]=useState();
//ecustomer_pincode
const[ecustomer_pincode,setecustomer_pincode]=useState();const updateecustomer_pincode = (event) => { setecustomer_pincode(event.target.value);}; const[ecustomer_pincode_er,setecustomer_pincode_er]=useState();



//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();

//Edit Function
function getsalesreturndatabyId(){
 

  const editid=sessionStorage.getItem("Editid00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(busyid>0)&&(counterid>0)&&(editid>0)){
 
 
 axios.post("bt-salesreturn-details-byid", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Salesreturn_id:editid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0

 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Editid00");


     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data
   setedate(response.data.date);
   setetype(response.data.type);
   setebank_id(response.data.bank_id);
   setebank_name(response.data.bank_name);
   setegstin(response.data.customer_gstin);
   setevoucher_no(response.data.voucher_no);
   setelicense_no(response.data.customer_license_no);
   setecustomer_id(response.data.customer_id);
   setecustomer_name(response.data.customer_name);
   setecustomer_email(response.data.customer_email);
   setecustomer_contact(response.data.customer_contact);
   setecustomer_wtp(response.data.customer_wtp);
   setecustomer_address(response.data.customer_address);
   setecustomer_city(response.data.customer_city);
   setecustomer_state(response.data.customer_state);
   setecustomer_country(response.data.customer_country);
   setecustomer_pincode(response.data.customer_pincode);
  
  }
  //Cannot Fetch Data
  else{


      document.getElementById("edit-form").style.display="none";

     //Setting Variables to empty 
     setedate_er(" ");setetype_er(" ");setebank_id_er(" ");setebank_name_er(" ");setegstin_er(" ");setevoucher_no_er(" ");setelicense_no_er(" ");setecustomer_id_er(" ");setecustomer_name_er(" ");setecustomer_email_er(" ");
     setecustomer_contact_er(" ");setecustomer_wtp_er(" ");setecustomer_address_er();setecustomer_city_er();setecustomer_state_er();setecustomer_country_er();setecustomer_pincode_er();seterror_msg(" ");
     setedate(" ");setetype(" ");setebank_id(" ");setebank_name(" ");setegstin(" ");setevoucher_no(" ");setelicense_no(" ");setecustomer_id(" ");setecustomer_name(" ");setecustomer_email(" ");
     setecustomer_contact(" ");setecustomer_wtp(" ");setecustomer_address();setecustomer_city();setecustomer_state();setecustomer_country();setecustomer_pincode();seterror_msg(" ");
   
     

      document.getElementById("edit-error-card").style.display="block"; 
      sessionStorage.removeItem("Editid00");
  }
 
 
 });
 
 }
 //User not Logedin
 else{

     //Checking if user loggedin
     if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))){
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("EditId00");

   window.location.replace("businesstools-login");
     }
 
  }
 
 }
  
 //******************* GET SALESRETURN DATA BY ID FUNCTION END  ********************//



  
 //******************* EDIT SALESRETURN FUNCTION START ********************//
function editsalesreturn(){

  //Setting Error Variables
  setedate_er(" ");setetype_er(" ");setebank_id_er(" ");setebank_name_er(" ");setegstin_er(" ");setevoucher_no_er(" ");setelicense_no_er(" ");setecustomer_id_er(" ");setecustomer_name_er(" ");setecustomer_email_er(" ");
  setecustomer_contact_er(" ");setecustomer_wtp_er(" ");setecustomer_address_er();setecustomer_city_er();setecustomer_state_er();setecustomer_country_er();setecustomer_pincode_er();seterror_msg(" ");

  const editid = sessionStorage.getItem("Editid00");
  const trno = sessionStorage.getItem("Trno00");

  
  //Check if related fields selected right
  //Bank Transation
  if((etype=="bank")&&((ebank_id==" ")||(ebank_id==null))){ seterror_msg("Select Bank for Bank Sales Return !");return; }
  //Credit Transation
  if((etype=="credit")&&((ecustomer_name==" ")||(ecustomer_name==null))){ seterror_msg("Enter Customer Name for Credit Transaction !");return; }



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((editid!=null)||(editid!=" "))&&((trno!=null)||(trno!=" "))){
 
 
  axios.post("bt-salesreturn-edit", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Salesreturn_id:editid,
   Tr_no:trno,
   Date : moment(edate).format('YYYY-MM-DD'),
   Type:etype,
   Bank_id:ebank_id,
   Voucher_no : evoucher_no,
   Gstin : egstin,
   License_no:elicense_no,
   Customer_id:ecustomer_id,
   Customer_name:ecustomer_name,
   Customer_email:ecustomer_email,
   Customer_contact:ecustomer_contact,
   Customer_wtp:ecustomer_wtp,
   Customer_address:ecustomer_address,
   Customer_city:ecustomer_city,
   Customer_state:ecustomer_state,
   Customer_country:ecustomer_country,
   Customer_pincode:ecustomer_pincode
 })
 .then((response) => {
  
  //Invalid Entries
  if(response.data.error==105){     seterror_msg("Invoice Number Already Exists ! ");  return; }
  if(response.data.error==104){     seterror_msg("Select Bank! "); return; }
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  return; }

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Trno00");
     sessionStorage.removeItem("Editid00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){

  setedate_er(response.data.validatorerror.Date);
  setetype_er(response.data.validatorerror.Type);
  setevoucher_no_er(response.data.validatorerror.Voucher_no);
  setegstin_er(response.data.validatorerror.Gstin);
  setelicense_no_er(response.data.validatorerror.License_no);
  setecustomer_id_er(response.data.validatorerror.Customer_id);
  setecustomer_name_er(response.data.validatorerror.Customer_name);
  setecustomer_email_er(response.data.validatorerror.Customer_email);
  setecustomer_contact_er(response.data.validatorerror.Customer_contact);
  setecustomer_wtp_er(response.data.validatorerror.Customer_wtp);
  setecustomer_address_er(response.data.validatorerror.Customer_address);
  setecustomer_city_er(response.data.validatorerror.Customer_city);
  setecustomer_state_er(response.data.validatorerror.Customer_state);
  setecustomer_country_er(response.data.validatorerror.Customer_country);
  setecustomer_pincode_er(response.data.validatorerror.Customer_pincode);
  
  return;



}
//Invalid Entries
if(response.data.error==102){ 
  
  setedate_er(response.data.date_er);
  setetype_er(response.data.type_er);
  setevoucher_no_er(response.data.voucher_no_er);
  setegstin_er(response.data.gstin_er);
  setelicense_no_er(response.data.license_no_er);
  setecustomer_id_er(response.data.customer_id_er);
  setecustomer_name_er(response.data.customer_name_er);
  setecustomer_email_er(response.data.customer_email_er);
  setecustomer_contact_er(response.data.customer_contact_er);
  setecustomer_wtp_er(response.data.customer_wtp_er);
  setecustomer_address_er(response.data.customer_address_er);
  setecustomer_city_er(response.data.customer_city_er);
  setecustomer_state_er(response.data.customer_state_er);
  setecustomer_country_er(response.data.customer_country_er);
  setecustomer_pincode_er(response.data.customer_pincode_er);

 
  seterror_msg("Cannot Update Transaction!");  }



   //Transaction Updated Successfully
   if(response.data.successvar==1){        
     //Setting Variables to empty 
     setedate_er(" ");setetype_er(" ");setebank_id_er(" ");setebank_name_er(" ");setegstin_er(" ");setevoucher_no_er(" ");setelicense_no_er(" ");setecustomer_id_er(" ");setecustomer_name_er(" ");setecustomer_email_er(" ");
     setecustomer_contact_er(" ");setecustomer_wtp_er(" ");setecustomer_address_er();setecustomer_city_er();setecustomer_state_er();setecustomer_country_er();setecustomer_pincode_er();seterror_msg(" ");
     setedate(" ");setetype(" ");setebank_id(" ");setebank_name(" ");setegstin(" ");setevoucher_no(" ");setelicense_no(" ");setecustomer_id(" ");setecustomer_name(" ");setecustomer_email(" ");
     setecustomer_contact(" ");setecustomer_wtp(" ");setecustomer_address();setecustomer_city();setecustomer_state();setecustomer_country();setecustomer_pincode();seterror_msg(" ");
      document.getElementById("edit-form").style.display="none"; 
     
      // document.getElementById("edit-success-card").style.display="block"; 
      window.location.replace("/businesstools-user-salesreturn-addproducts-edit");
     }

   //Cannot Update Transaction
   else{  seterror_msg("Cannot Update Transaction !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("Trno00");
   sessionStorage.removeItem("Edit00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT SALESRETURN FUNCTION END ********************//




 
 //******************* DELETE SALESRETURN FUNCTION START ********************//
 function deletesalesreturn(){

  const editId=sessionStorage.getItem("DEditId00");
  const dtrno=sessionStorage.getItem("DTrno00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((editId!=null)||(editId!=" "))&&((dtrno!=null)||(dtrno!=" "))){
 
 axios.post("bt-salesreturn-delete", {
   Busy_id:busyid,
   Counter_id:counterid,
   Log_id:logid,
   Token:token,
   Salesreturn_id:editId,
   Tr_no:dtrno
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("DEditId00");
     sessionStorage.removeItem("DTrno00");

     window.location.replace("businesstools-login");
  }

  //Counter Deleted Successfully
   if(response.data.successvar==1){        

      document.getElementById("delete-card").style.display="none";
      document.getElementById("delete-success-card").style.display="block";  
      sessionStorage.removeItem("DEditId");
      sessionStorage.removeItem("DTrno00");

  }
  
      //Cannot Delete Counter
    else{  
    document.getElementById("delete-card").style.display="none";
    document.getElementById("delete-error-card").style.display="block";   }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("DEditId00");
   sessionStorage.removeItem("DTrno00");


   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* DELETE SALESRETURN FUNCTION END ********************//



return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

      <Sidebar />
    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">

             {/* SUCCESS CARD START */}
             <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">New Transaction Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="businesstools-user-salesreturn-addproducts"><button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* SUCCESS CARD END */}

                   {/* EDIT SUCCESS CARD START */}
             <div class="d-flex justify-content-center">
             <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Transaction Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-success-card").style.display="none"; /* window.location.replace("/businesstools-user-salesreturn-addproducts-edit"); */ }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT SUCCESS CARD END */}
               {/* EDIT ERROR CARD START */}
            <div class="d-flex justify-content-center">
            <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Fetch Data !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT ERROR CARD END */}

                      {/* DELETE CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Are you sure you want to delete this transaction?</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> This action cannot be reversed. You will lose all your data related to this transaction if you wish to continue this action.</p>
                      <button class="mt-3 btn btn-outline-success" onClick={deletesalesreturn}>Yes, I Know! Delete Transaction.</button>
                      <button class="mt-3 btn btn-outline-danger" style={{marginLeft:"1rem"}} onClick={()=>{  
                      //Cancel Delete and remove edit_id
                      sessionStorage.removeItem("DEditId00");     sessionStorage.removeItem("DTrno00");  document.getElementById('delete-card').style.display = 'none'; }}>No, Don't Delete Transaction.</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE CARD END */}
              {/* DELETE SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Transaction Deleted Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE SUCCESS CARD END */}
               {/*  DELETE ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Delete Transaction !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE ERROR CARD END */}

          <div class="content-wrapper pb-0">

          <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form">
              <Create_salesreturn /> 
              </div>
          </div>

            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Salesreturn</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                   {/*} <p class="m-0">ADE-00234</p> */}
                  </a>
                </div>
                <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i class="mdi mdi-plus-circle"></i> New Transaction </button>
              </div>
            </div>

      {/*} chart row starts here */}
      <div class="row">
                       {/* LINECHART START */}
                       <div className="col-sm-8 stretch-card grid-margin">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                    <div className="card-title"> Sales Return
                      </div>                  
                      <div className="d-flex text-muted font-20">
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("lc-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("lc-date-search-box").style.display="none"; }}></i>                     
                      </div>
                    </div>
                    {/* LINE CHART DATE FILTER START */}
                    <div id="lc-date-search-box" style={{display:"none"}}>
                    <hr />
                    <p style={{marginTop:"2rem"}}>Make sure start date and end date are not more than 10 days apart for best results</p>
                    <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="lc_start_date" value={lc_start_date} onChange={updatelc_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="lc_end_date" value={lc_end_date} onChange={updatelc_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setlc_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* LINE CHART DATE FILTER END */}
                    <div className="line-chart-wrapper">
                  {/* CASH INFLOW OUTFLOW LINE CHART START */}
                  <Line data={data1} /> 
                  {/* CASH INFLOW OUTFLOW LINE CHART END */}                   
                    </div>
                  </div>
                </div>
              </div>
               {/* LINE CHART END */}
               {/* PIE CHART START */}
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Transactions Summary
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("pc-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("pc-date-search-box").style.display="none"; }}></i>
                      
                      </div>
                    </div>
                        {/*BAR CHART DATE FILTER START */}
                        <div id="pc-date-search-box" style={{display:"none"}}>
                    <hr />
                    <div className="row">
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="pc_start_date" value={pc_start_date} onChange={updatepc_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="pc_end_date" value={pc_end_date} onChange={updatepc_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setpc_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* BAR CHART DATE FILTER END */}
                       
                    
                   
             
                    <div class="bar-chart-wrapper">
                    <Pie data={data2} style={{width:"600px",height:"400px"}} maintainAspectRatio={false} />
                    </div>
                  </div>
                </div>
              </div>
               {/* PIE CHART END */}
        
            </div>



                 {/* CUSTOMERS LIST BOX START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-4 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"-25rem",display:"none"}} id="edit-customers-list-box">
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div>
                    <h4 style={{padding:'1rem'}}>Customers</h4>
                    </div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ setecdefault(1); document.getElementById("edit-customers-list-box").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    
                    <table class="table table-hover">
                      <thead>
                        <tr>
                        {/*}  <th>Customer Id</th> */}
                          <th>Customer Name</th>
                         {/*} <th>Email</th> */}
                          <th>Contact</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                       ecustomers.map((curElm6,ind)=>{

                         return(
                         <>
                        <tr key={curElm6.Customer_id}>
                          {/* <td>{curElm6.Customer_id}</td> */}
                          <td>{curElm6.Customer_name}</td>
                         {/*} <td>{curElm6.Email}</td> */}
                          <td>{curElm6.Contact}</td>
                          <td><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Customer Details for 
                        setecustomer_name(curElm6.Customer_name);setecustomer_id(curElm6.Customer_id);setecustomer_contact(curElm6.Contact);setecustomer_wtp(curElm6.Whastapp);setecustomer_email(curElm6.Email);setegstin(curElm6.Gstin);setelicense_no(curElm6.License_no);
                        setecustomer_wtp(curElm6.Whatsapp);setecustomer_address(curElm6.Address);setecustomer_city(curElm6.City);setecustomer_state(curElm6.State);setecustomer_country(curElm6.Country);setecustomer_pincode(curElm6.Pincode);
                        document.getElementById("edit-customers-list-box").style.display="none";
                              }}></i></td>

                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                      </tbody>
                    </table>
              
                </div>
              </div>
              </div>
              {/* CUSTOMERS LIST BOX END */}

               {/* EDIT SALESreturn FORM START */}
             <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("edit-form").style.display="none";
                        //Setting Variables to empty
                        setedate_er(" ");setetype_er(" ");setebank_id_er(" ");setebank_name_er(" ");setegstin_er(" ");setevoucher_no_er(" ");setelicense_no_er(" ");setecustomer_id_er(" ");setecustomer_name_er(" ");setecustomer_email_er(" ");
                        setecustomer_contact_er(" ");setecustomer_wtp_er(" ");setecustomer_address_er();setecustomer_city_er();setecustomer_state_er();setecustomer_country_er();setecustomer_pincode_er();seterror_msg(" ");
                        setedate(" ");setetype(" ");setebank_id(" ");setebank_name(" ");setegstin(" ");setevoucher_no(" ");setelicense_no(" ");setecustomer_id(" ");setecustomer_name(" ");setecustomer_email(" ");
                        setecustomer_contact(" ");setecustomer_wtp(" ");setecustomer_address();setecustomer_city();setecustomer_state();setecustomer_country();setecustomer_pincode();seterror_msg(" ");
                        sessionStorage.removeItem("Editid00");
                        sessionStorage.removeItem("Trno00"); }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Edit Salesreturn</h4>
                    <div class="form-sample">
                      <p class="card-description">edit your salesreturn details here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="edate" value={edate} onChange={updateedate} />
                              <code>{edate_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Invoice No.<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="VN87766" id="evoucher_no" value={evoucher_no} onChange={updateevoucher_no} />
                            <code>{evoucher_no_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="etype" value={etype} onChange={updateetype} >
                              <option>Select Type</option>
                              <option value="cash">Cash</option>
                              <option value="bank">Bank</option>
                              <option value="credit">Credit</option>
                              </select>
                              <code>{etype_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} placeholder="Select Bank" id="ebank_id" value={ebank_id} onChange={updateebank_id}>
                              <option value={ebank_id}>{ebank_name}</option>
                              {
                              banks.map((curElm4,ind)=>{

                              return(
                              <>
                              <option value={curElm4.Bank_id}>{curElm4.Bank_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{ebank_id_er}</code>
                            </div>
                          </div>
                        </div>

                      </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Customer Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Suman Soni Sharma" id="ecustomer_name" value={ecustomer_name} onChange={updateecustomer_name}/>
                              <code>{ecustomer_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 8000000000" id="ecustomer_contact" value={ecustomer_contact} onChange={updateecustomer_contact} />
                            <code>{ecustomer_contact_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Sumansonisharma34@gmail.com" id="ecustomer_email" value={ecustomer_email} onChange={updateecustomer_email} />
                            <code>{ecustomer_email_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">WhatsApp</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 8000000000" id="ecustomer_wtp" value={ecustomer_wtp} onChange={updateecustomer_wtp} />
                            <code>{ecustomer_wtp_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">GSTIN</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="HGSSSFS55S4S4S4" id="egstin" value={egstin} onChange={updateegstin} />
                              <code>{egstin_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">License No.</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="LIC9900000" id="elicense_no" value={elicense_no} onChange={updateelicense_no} />
                              <code>{elicense_no_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Address</label>
                        <textarea class="form-control" rows="2"  id="ecustomer_address" value={ecustomer_address} onChange={updateecustomer_address} ></textarea>
                        <code>{ecustomer_address_er}</code>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Lucknow" id="ecustomer_city" value={ecustomer_city} onChange={updateecustomer_city} />
                              <code>{ecustomer_city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Uttar Pradesh" id="ecustomer_state" value={ecustomer_state} onChange={updateecustomer_state} />
                              <code>{ecustomer_state_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="India"  id="ecustomer_country" value={ecustomer_country} onChange={updateecustomer_country}/>
                              <code>{ecustomer_country_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" pincode="420110" id="ecustomer_pincode" value={ecustomer_pincode} onChange={updateecustomer_pincode} />
                              <code>{ecustomer_pincode_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={editsalesreturn}>Edit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
              </div>
              </div>
              {/* EDIT SALESreturn FORM END */}
                  

                  {/* SALESreturn TRANSACTIONS LIST START */}
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Salesreturn Transactions</h4>
                    <p class="card-description">salesreturn transaction details
                    </p>

                                {/* FILTER SECTION START */}
                                <hr/>
                            <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Start Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control" id="filter_start_date" value={filter_start_date} onChange={updatefilter_start_date}  />
                              <code>{filter_start_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">End Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control"  id="filter_end_date" value={filter_end_date} onChange={updatefilter_end_date}  />
                              <code>{filter_end_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Tr No.</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="12345"   id="filter_trno" value={filter_trno} onChange={updatefilter_trno}  />
                              <code>{filter_trno_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Amount</label>
                            <div class="col-sm-4">
                              <select class="form-control" style={{padding:"1rem"}} id="filter_amount_sign" value={filter_amount_sign} onChange={updatefilter_amount_sign}  >
                              <option className="dropdown-item" value=" ">All</option>
                              <option className="dropdown-item" value="equals">equals</option>
                              <option className="dropdown-item" value="more than" >more than</option>
                              <option className="dropdown-item" value="less than">less than</option>
                              <option className="dropdown-item" value="more than or equals" >more than or equals</option>
                              <option className="dropdown-item" value="less than or equals" >less than or equals</option>
                              </select>
                              <code>{filter_amount_sign_er}</code>
                            </div>
                            <div class="col-sm-4">
                              <input type="text" class="form-control" placeholder="5000.00" id="filter_amount" value={filter_amount} onChange={updatefilter_amount}   />
                              <code>{filter_amount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Type</label>
                            <div class="col-sm-8">
                             <select class="form-control" style={{padding:"1rem"}} id="filter_type" value={filter_type} onChange={updatefilter_type} >
                             <option value=" ">Select Type</option>
                             <option value="cash">cash</option>
                             <option value="bank">bank</option>
                             <option value="credit">credit</option>
                           
                             </select>                              
                             <code>{filter_type_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Invoice No.</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="IV123456" id="filter_voucher_no" value={filter_voucher_no} onChange={updatefilter_voucher_no}  />
                              <code>{filter_voucher_no_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">GSTIN</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="GST838833333"  id="filter_gstin" value={filter_gstin} onChange={updatefilter_gstin}  />
                              <code>{filter_gstin_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Customer Name</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="Sanjeet Soni"   id="filter_customer_name" value={filter_customer_name} onChange={updatefilter_customer_name}  />
                              <code>{filter_customer_name_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                      <div style={{marginBottom:"1rem"}}>
                  
                         <button class="btn btn-primary" onClick={()=>{ setfilter_val(1); getData(); }}>Apply Filters</button>
                         <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val(0); getData(); }}>Remove Filters</button>
                       
                      </div>
                            <hr/>                      
                            </div>
                            
                            {/* FILTER SECTION END */}


                    <div class="col-12" style={{marginBottom:"2rem"}}>
                    <div class="template-demo d-flex justify-content-between">
                    <div></div>

                     {/* COLUMNS MANAGEMENT DROPDOWN START */}
                     <div class="dropdown">
                            <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                            <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tr No.</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Invoice No.</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">GSTIN</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Type</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Customer Name</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Amount</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">View</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated By</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated At</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 12 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c12_yes" onClick={()=>{setcd_col12_val(1); document.getElementById("cd_c12_yes").style.display="none"; document.getElementById("cd_c12_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c12_no" style={{ display:"none"}} onClick={()=>{ setcd_col12_val(0); document.getElementById("cd_c12_yes").style.display="block"; document.getElementById("cd_c12_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 13 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-danger">Delete</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c13_yes" onClick={()=>{setcd_col13_val(1); document.getElementById("cd_c13_yes").style.display="none"; document.getElementById("cd_c13_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c13_no" style={{ display:"none"}} onClick={()=>{ setcd_col13_val(0); document.getElementById("cd_c13_yes").style.display="block"; document.getElementById("cd_c13_no").style.display="none"; }}></i>
                              </div></div></li> 
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>

                    <div class="table-responsive" id="reports" ref={componentRef} >
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Sn</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Tr No.</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1} >Date</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1} >Invoice No.</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col5_val==1} >GSTIN</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Type</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1} >Customer Name</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col8_val==1} >Amount</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1} ></th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col10_val==1} >Updated By</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col11_val==1} >Updated At</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col12_val==1} ></th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col13_val==1} ></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                      
                        <tr>
                            <td hidden={(logtype == "user")||(cd_col1_val == 1)}>{curElm.Admin_sn}</td>
                            <td hidden={(logtype == "admin")||(cd_col1_val == 1)}>{curElm.User_sn}</td>
                            <td hidden={cd_col2_val==1} >{curElm.Tr_no}</td>
                            <td hidden={cd_col3_val==1} >{curElm.Date}</td>
                            <td hidden={cd_col4_val==1} >{curElm.Voucher_no}</td>
                            <td hidden={cd_col5_val==1} >{curElm.Customer_gstin}</td>
                            <td hidden={cd_col6_val==1} >
                            <label hidden={(curElm.Type=="cash")||(curElm.Type=="bank")} class="badge badge-warning">credit</label>
                            <label class="badge badge-success" hidden={(curElm.Type == "bank")||(curElm.Type == "credit")}>{curElm.Type}</label>
                            <label class="badge badge-primary" hidden={(curElm.Type == "cash")||((curElm.Type == "credit"))}>{curElm.Type} ({curElm.Bank_name})</label>
                            </td>
                            <td hidden={cd_col7_val==1} >{curElm.Customer_name}</td>
                            <td hidden={cd_col8_val==1} >{curElm.Total}</td>
                            <td hidden={cd_col9_val==1} ><button class="btn btn-primary" onClick={()=>{
                              //Setting Session and Redirect to Invoice Page
                              sessionStorage.setItem("Trno00",curElm.Tr_no); window.location.replace("businesstools-user-salesreturn-invoice");
                            }}>View</button> </td>
                            <td hidden={cd_col10_val==1} >{curElm.Updated_by}</td>
                            <td hidden={cd_col11_val==1} >{curElm.Date_updated} ({curElm.Time_updated})</td>
                            <td hidden={cd_col12_val==1} ><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting edit Id and Redirect to Edit
                      sessionStorage.setItem("Editid00",curElm.Id);sessionStorage.setItem("Trno00",curElm.Tr_no);getsalesreturndatabyId();document.getElementById('edit-form').style.display = 'block'; }}></i></td>
                            <td hidden={cd_col13_val==1} ><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting edit Id for Delete
                      sessionStorage.setItem("DEditId00",curElm.Id);      sessionStorage.setItem("DTrno00",curElm.Tr_no); document.getElementById('delete-card').style.display = 'block'; }}></i></td>

                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                          
                       
                          
                         
                        </tbody>
                      </table>
                    </div>
                     {/* PAGINATION SECTION START */}
                     <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel}><i class="mdi mdi-file-excel text-dark"></i></button>
                    
                  </div>
                </div>
              </div>
               {/* SALESreturn TRANSACTIONS LIST END */}
            

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
        {/*}  <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

//}
}
export default UserSalesreturn;