import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';
import axios from '../../axios.js';
import moment from 'moment/moment';


function Create_sales(){

  
   //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");


 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 {/* if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{

   */}

    
      
//Variables
const[Tbanks,setTbanks] = useState([]);//SetData Variable
const[customers,setcustomers] = useState([],);//SetData Variable
const[cdefault,setcdefault] = useState(0);

//Error Variable
const[errorvar,seterrorvar] = useState();

//customers_count
const[customers_count,setcustomers_count] = useState(1);
//csuccess
//const[ccsuccess,setccsuccess] = useState(0);
//date
const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState();
//type
const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
//voucher_no
const[voucher_no,setvoucher_no]=useState();const updatevoucher_no = (event) => { setvoucher_no(event.target.value);}; const[voucher_no_er,setvoucher_no_er]=useState();
//gstin
const[gstin,setgstin]=useState();const updategstin = (event) => { setgstin(event.target.value);}; const[gstin_er,setgstin_er]=useState();
//license_no
const[license_no,setlicense_no]=useState();const updatelicense_no = (event) => { setlicense_no(event.target.value);}; const[license_no_er,setlicense_no_er]=useState();
//bank_id
const[bank_id,setbank_id]=useState();const updatebank_id = (event) => { setbank_id(event.target.value);}; const[bank_id_er,setbank_id_er]=useState();
//customer_id
const[customer_id,setcustomer_id]=useState();const updatecustomer_id = (event) => { setcustomer_id(event.target.value);}; const[customer_id_er,setcustomer_id_er]=useState();
//customer_name
const[customer_name,setcustomer_name]=useState();const updatecustomer_name = (event) => { setcustomer_name(event.target.value);getcustomerbyname(); }; const[customer_name_er,setcustomer_name_er]=useState();
//customer_contact
const[customer_contact,setcustomer_contact]=useState();const updatecustomer_contact = (event) => { setcustomer_contact(event.target.value);}; const[customer_contact_er,setcustomer_contact_er]=useState();
//customer_wtp
const[customer_wtp,setcustomer_wtp]=useState();const updatecustomer_wtp = (event) => { setcustomer_wtp(event.target.value);}; const[customer_wtp_er,setcustomer_wtp_er]=useState();
//customer_email
const[customer_email,setcustomer_email]=useState();const updatecustomer_email = (event) => { setcustomer_email(event.target.value);}; const[customer_email_er,setcustomer_email_er]=useState();
//customer_address
const[customer_address,setcustomer_address]=useState();const updatecustomer_address = (event) => { setcustomer_address(event.target.value);}; const[customer_address_er,setcustomer_address_er]=useState();
//customer_city
const[customer_city,setcustomer_city]=useState();const updatecustomer_city = (event) => { setcustomer_city(event.target.value);}; const[customer_city_er,setcustomer_city_er]=useState();
//customer_state
const[customer_state,setcustomer_state]=useState();const updatecustomer_state = (event) => { setcustomer_state(event.target.value);}; const[customer_state_er,setcustomer_state_er]=useState();
//customer_country
const[customer_country,setcustomer_country]=useState();const updatecustomer_country = (event) => { setcustomer_country(event.target.value);}; const[customer_country_er,setcustomer_country_er]=useState();
//customer_pincode
const[customer_pincode,setcustomer_pincode]=useState();const updatecustomer_pincode = (event) => { setcustomer_pincode(event.target.value);}; const[customer_pincode_er,setcustomer_pincode_er]=useState();




//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();




useEffect(()=>{
   
  getData();
  
  },[])
 

  
    
  
//******************* GET BANKS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
 
 axios.post("bt-get-banks", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setTbanks(response.data.data);
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET BANKS DATA FUNCTION END  ********************//



 
  
//******************* GET CUSTOMER DATA BY NAME FUNCTION START  ********************//
function getcustomerbyname(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
     const cname = document.getElementById("customer_name").value;
     const cnlength = cname.length;
     if((cdefault==0)&&(cnlength>=3)){
     
 
 axios.post("bt-get-customers-spr", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Name:document.getElementById("customer_name").value,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setcustomers(response.data.customers);
   setcustomers_count(response.data.customers_count);
   document.getElementById("customers-list-box").style.display="block";
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !"); 
  // console.log("customer_not_found");
  seterrorvar("Customer Not Found do you want to add this to !"); 
  }
 
 
 });
}
//set customers_count so that user can add new customer
else{ setcustomers_count(1); }
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }

 }
  
 //******************* GET CUSTOMER DATA BY NAME FUNCTION END  ********************//

 
  
//******************* CREATE SALES FUNCTION START ********************//
function createsales(){

  //Setting Error Variables
setdate_er(" ");settype_er(" ");setbank_id_er(" ");setgstin_er(" ");setvoucher_no_er(" ");setlicense_no_er(" ");setcustomer_id_er(" ");setcustomer_name_er(" ");setcustomer_email_er(" ");
setcustomer_contact_er(" ");setcustomer_wtp_er(" ");setcustomer_address_er();setcustomer_city_er();setcustomer_state_er();setcustomer_country_er();setcustomer_pincode_er();seterror_msg(" ");


  //Check if related fields selected right
  //Bank Transation
  if((type=="bank")&&((bank_id==" ")||(bank_id==null))){ seterror_msg("Select Bank for Bank Sales !");return; }
  //Credit Transation
  if((type=="credit")&&((customer_name==" ")||(customer_name==null))){ seterror_msg("Enter Customer Name for Credit Transaction !");return; }

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-create-sales", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Date : moment(date).format('YYYY-MM-DD'),
   Type:type,
   Bank_id:bank_id,
   Voucher_no : voucher_no,
   Gstin : gstin,
   License_no:license_no,
   Customer_id:customer_id,
   Customer_name:customer_name,
   Customer_email:customer_email,
   Customer_contact:customer_contact,
   Customer_wtp:customer_wtp,
   Customer_address:customer_address,
   Customer_city:customer_city,
   Customer_state:customer_state,
   Customer_country:customer_country,
   Customer_pincode:customer_pincode
 })
 .then((response) => {

  
  //Invalid Entries
  if(response.data.error==105){     seterror_msg("Invoice Number Already Exists ! ");  return; }
  if(response.data.error==104){     seterror_msg("Select Bank! "); return; }
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  return; }

  if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }
  
  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");

  }

   //Validation Errors
 if(response.data.validatorerror){
  setdate_er(response.data.validatorerror.Date);
  settype_er(response.data.validatorerror.Type);
  setvoucher_no_er(response.data.validatorerror.Voucher_no);
  setgstin_er(response.data.validatorerror.Gstin);
  setlicense_no_er(response.data.validatorerror.License_no);
  setcustomer_id_er(response.data.validatorerror.Customer_id);
  setcustomer_name_er(response.data.validatorerror.Customer_name);
  setcustomer_email_er(response.data.validatorerror.Customer_email);
  setcustomer_contact_er(response.data.validatorerror.Customer_contact);
  setcustomer_wtp_er(response.data.validatorerror.Customer_wtp);
  setcustomer_address_er(response.data.validatorerror.Customer_address);
  setcustomer_city_er(response.data.validatorerror.Customer_city);
  setcustomer_state_er(response.data.validatorerror.Customer_state);
  setcustomer_country_er(response.data.validatorerror.Customer_country);
  setcustomer_pincode_er(response.data.validatorerror.Customer_pincode);
  
  return;

}
//Invalid Entries
if(response.data.error==102){ 
  
  setdate_er(response.data.date_er);
  settype_er(response.data.type_er);
  setvoucher_no_er(response.data.voucher_no_er);
  setgstin_er(response.data.gstin_er);
  setlicense_no_er(response.data.license_no_er);
  setcustomer_id_er(response.data.customer_id_er);
  setcustomer_name_er(response.data.customer_name_er);
  setcustomer_email_er(response.data.customer_email_er);
  setcustomer_contact_er(response.data.customer_contact_er);
  setcustomer_wtp_er(response.data.customer_wtp_er);
  setcustomer_address_er(response.data.customer_address_er);
  setcustomer_city_er(response.data.customer_city_er);
  setcustomer_state_er(response.data.customer_state_er);
  setcustomer_country_er(response.data.customer_country_er);
  setcustomer_pincode_er(response.data.customer_pincode_er);
 
  seterror_msg("Cannot Add Transaction!");  return; }

   

   //Transaction Entered Successfully
   if(response.data.successvar==1){        sessionStorage.setItem("Trno00",response.data.Tr_no); document.getElementById("success-card").style.display="block";  }
   //Cannot Add Transaction
   else{  seterror_msg("Cannot Add Transaction !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* CREATE SALES FUNCTION END ********************//



 
//******************* ADD CUSTOMER FUNCTION START ********************//
function addcustomer(){

//Setting Error Variables
setcustomer_name_er(" ");setcustomer_email_er(" ");setcustomer_contact_er(" ");setcustomer_wtp_er(" ");setcustomer_address_er();setcustomer_city_er();setcustomer_state_er();setcustomer_country_er();setcustomer_pincode_er();/*settype_er();setcategory_er();*/setgstin_er();setlicense_no_er();seterror_msg(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-add-customer", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Name : customer_name,
   Email:customer_email,
   Contact : customer_contact,
   Whatsapp:customer_wtp,
   Address:customer_address,
   City:customer_city,
   State:customer_state,
   Country:customer_country,
   Pincode:customer_pincode,
   /*Type:type,
   Category:category,*/
   Gstin:gstin,
   License_no:license_no


  })
  .then((response) => {

      //Customers Limit Reached
      if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }

      
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setcustomer_name_er(response.data.validatorerror.Name);
  setcustomer_email_er(response.data.validatorerror.Email);
  setcustomer_contact_er(response.data.validatorerror.Contact);
  setcustomer_wtp_er(response.data.validatorerror.Whatsapp);
  setcustomer_address_er(response.data.validatorerror.Address);
  setcustomer_city_er(response.data.validatorerror.City);
  setcustomer_state_er(response.data.validatorerror.State);  
  setcustomer_country_er(response.data.validatorerror.Country);
  setcustomer_pincode_er(response.data.validatorerror.Pincode);  
 /* settype_er(response.data.validatorerror.Type);
  setcategory_er(response.data.validatorerror.Category); */
  setgstin_er(response.data.validatorerror.Gstin);
  setlicense_no_er(response.data.validatorerror.License_no);

}
//Invalid Entries
if(response.data.error==102){ 
  
  setcustomer_name_er(response.data.name_er);
  setcustomer_email_er(response.data.email_er);
  setcustomer_contact_er(response.data.contact_er);
  setcustomer_wtp_er(response.data.whatsapp_er);
  setcustomer_address_er(response.data.address_er);
  setcustomer_city_er(response.data.city_er);
  setcustomer_state_er(response.data.state_er);
  setcustomer_country_er(response.data.country_er);
  setcustomer_pincode_er(response.data.pincode_er);
 /* settype_er(response.data.type_er);
  setcategory_er(response.data.category_er); */
  setgstin_er(response.data.gstin_er);
  setlicense_no_er(response.data.license_no_er);
  seterror_msg("Cannot Add Customer!");  }

   //Transaction Entered Successfully
   if(response.data.successvar==1){     
   // setccsuccess(1);
    document.getElementById("cst-customer").style.display="block";  }
   //Cannot Add Transaction
   else{  seterror_msg("Cannot Add Customer !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* ADD CUSTOMER FUNCTION END ********************//




return(
    <>

        {/* CUSTOMERS LIST BOX START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-4 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"1rem",display:"none"}} id="customers-list-box">
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div>
                    <h4 style={{padding:'1rem'}}>Customers</h4>
                    </div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ setcdefault(1); document.getElementById("customers-list-box").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    
                    <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                        {/*}  <th>Customer Id</th> */}
                          <th>Customer Name</th>
                       {/*}   <th>Email</th>  */}
                        <th>Contact</th>  
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                       customers.map((curElm7,ind)=>{

                         return(
                         <>
                        <tr key={curElm7.Customer_id}>
                        {/*}  <td>{curElm7.Customer_id}</td> */}
                          <td>{curElm7.Customer_name}</td>
                         {/*} <td>{curElm7.Email}</td> */}
                          <td>{curElm7.Contact}</td>  
                          <td><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Customer Details for 
                        setcustomer_name(curElm7.Customer_name);setcustomer_id(curElm7.Customer_id);setcustomer_contact(curElm7.Contact);setcustomer_wtp(curElm7.Whastapp);setcustomer_email(curElm7.Email);setgstin(curElm7.Gstin);setlicense_no(curElm7.License_no);
                        setcustomer_wtp(curElm7.Whatsapp);setcustomer_address(curElm7.Address);setcustomer_city(curElm7.City);setcustomer_state(curElm7.State);setcustomer_country(curElm7.Country);setcustomer_pincode(curElm7.Pincode);
                        document.getElementById("customers-list-box").style.display="none";
                              }}></i></td>

                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                      </tbody>
                    </table>
                    </div>
              
                </div>
              </div>
              </div>
              {/* CUSTOMERS LIST BOX END */}


                <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Create Sales</h4>
                    <div class="form-sample">
                      <p class="card-description">add your sales details here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date" value={date} onChange={updatedate} />
                              <code>{date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Invoice No.<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="VN87766" id="voucher_no" value={voucher_no} onChange={updatevoucher_no} />
                            <code>{voucher_no_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="type" value={type} onChange={updatetype} >
                              <option>Select Type</option>
                              <option value="cash">Cash</option>
                              <option value="bank">Bank</option>
                              <option value="credit">Credit</option>
                              </select>
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} placeholder="Select Bank" id="bank_id" value={bank_id} onChange={updatebank_id}>
                              <option value="">Select Bank</option>
                              {
                              Tbanks.map((curElm2,ind)=>{

                              return(
                              <>
                              <option value={curElm2.Bank_id}>{curElm2.Bank_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{bank_id_er}</code>
                            </div>
                          </div>
                        </div>

                      </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Customer Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Suman Soni Sharma" id="customer_name" value={customer_name} onChange={updatecustomer_name}/>
                              <code>{customer_name_er}</code>
                              <button class="btn btn-light" hidden={customers_count>0} onClick={addcustomer}>Add this as new customer ....</button>
                              <p class="text-success" style={{display:"none"}} id="cst-customer"><b>New Customer Added!</b></p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 8000000000" id="customer_contact" value={customer_contact} onChange={updatecustomer_contact} />
                            <code>{customer_contact_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Sumansonisharma34@gmail.com" id="customer_email" value={customer_email} onChange={updatecustomer_email} />
                            <code>{customer_email_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">WhatsApp</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 8000000000" id="customer_wtp" value={customer_wtp} onChange={updatecustomer_wtp} />
                            <code>{customer_wtp_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">GSTIN</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="HGSSSFS55S4S4S4" id="gstin" value={gstin} onChange={updategstin} />
                              <code>{gstin_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">License No.</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="LIC9900000" id="license_no" value={license_no} onChange={updatelicense_no} />
                              <code>{license_no_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Address</label>
                        <textarea class="form-control" rows="2"  id="customer_address" value={customer_address} onChange={updatecustomer_address} ></textarea>
                        <code>{customer_address_er}</code>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Lucknow" id="customer_city" value={customer_city} onChange={updatecustomer_city} />
                              <code>{customer_city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Uttar Pradesh" id="customer_state" value={customer_state} onChange={updatecustomer_state} />
                              <code>{customer_state_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="India"  id="customer_country" value={customer_country} onChange={updatecustomer_country}/>
                              <code>{customer_country_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" pincode="420110" id="customer_pincode" value={customer_pincode} onChange={updatecustomer_pincode} />
                              <code>{customer_pincode_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={createsales}>Create</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
    </>

);

}
//}

export default Create_sales;