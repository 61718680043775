import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

//import './Pricingdummycss.css';
import Navbar from '../navbar';

import '../Resources/dashboard.css';

function Free_pricing(){



    //SETTING PRICING VARIABLES

    //INDIVIDUAL PLAN
    const [ip_days, setip_days] = useState(28);
    const [ip_val, setip_val] = useState(1);
    const [ip_plan1, setip_plan1] = useState(0);const [ipu_plan1, setipu_plan1] = useState(1);
    const [ip_plan2, setip_plan2] = useState(119);const [ipu_plan2, setipu_plan2] = useState(199);
    const [ip_plan3, setip_plan3] = useState(199);const [ipu_plan3, setipu_plan3] = useState(349);
    const [ip_plan4, setip_plan4] = useState(329);const [ipu_plan4, setipu_plan4] = useState(599);

    //INDIVIDUAL PLAN
    const [cp_days, setcp_days] = useState(28);
    const [cp_val, setcp_val] = useState(1);
    const [cp_plan1, setcp_plan1] = useState(0);const [cpu_plan1, setcpu_plan1] = useState(1);
    const [cp_plan2, setcp_plan2] = useState(239);const [cpu_plan2, setcpu_plan2] = useState(399);
    const [cp_plan3, setcp_plan3] = useState(389);const [cpu_plan3, setcpu_plan3] = useState(749);
    const [cp_plan4, setcp_plan4] = useState(449);const [cpu_plan4, setcpu_plan4] = useState(999);
    
    //BUSINESS PLAN
    const [bp_days, setbp_days] = useState(28);
    const [bp_val, setbp_val] = useState(1);
    const [bp_plan1, setbp_plan1] = useState(0);const [bpu_plan1, setbpu_plan1] = useState(1);
    const [bp_plan2, setbp_plan2] = useState(479);const [bpu_plan2, setbpu_plan2] = useState(799);
    const [bp_plan3, setbp_plan3] = useState(794);const [bpu_plan3, setbpu_plan3] = useState(1499);
    const [bp_plan4, setbp_plan4] = useState(1259);const [bpu_plan4, setbpu_plan4] = useState(2799);

      return(
      <>


<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Get your startup services relating to website development, consultancy, taxation, business registration, software services, intern hiring, social media management, digital marketing and much more.  "></meta>
    
   {/* <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />*/}
        <link rel="icon" href="./images/startupconnect_icon.ico" />
    <link rel="apple-touch-icon" href="./images/startupconnect_icon.png" />

      {/* plugins:css */}
      <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="./images/startupconnect_icon.png" />


    {/*} Bootstrap CSS */}
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />
 
    
    <title>Startup Connect | Business Tools and Services</title>
  </head>
  <body>
{/*}
<h1>New Body Heading</h1>
<h2>No one here</h2>
<h3>Go oemme</h3>
<h4>OKNSSNMNJ djc hehrhrh </h4>
<h5>jguy uugyr yfr645</h5>
<h6>jhuyt 6565dx uty565d45d343</h6>
<p>jhdwuicyu GGY yfydddfl</p>
*/}

<div class="container-scroller">

<div class="container-fluid page-body-wrapper">

  
 
    {/*} partial */}
    <div class="main-panel">

    <div class="content-wrapper pb-0">


    <div class="row">

    <h1><b>Business Tools Pricing</b></h1>
    <p>Plans that suites the need of individual business owners, Retail and chain stores and large enterprises with multiple businesses.</p>


                        {/* INDIVIDUAL PLAN CARDS START */}
                        <div className="col-sm-12 stretch-card grid-margin" style={{marginTop:"5rem"}}>
                        <div className="card" id="individaul-tag">
                       <div className="card-body">
               
                   {/*}   <h3>Individual Plans</h3>
                      <p>Best for Small and Medium Sized Businesses with a single shop and counter with moderate customer footfall.</p>

                   <hr />*/}

                      <div className="row">

                   {/*}   <div class="dropdown">
                        <button class="btn btn-outline-info dropdown-toggle" type="button" id="dropdownMenuOutlineButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Select Plan Days</button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuOutlineButton1">
                          <h6 class="dropdown-header">Select Plan Days</h6>
                          <a class="dropdown-item" onClick={()=>{ setip_days(28); setip_val(1); }}>28 Days</a>
                          <a class="dropdown-item" onClick={()=>{ setip_days(56); setip_val(2); }}>56 Days</a>
                          <a class="dropdown-item" onClick={()=>{ setip_days(84); setip_val(3); }}>84 Days</a>
                          <a class="dropdown-item" onClick={()=>{ setip_days(166); setip_val(6); }}>162 Days</a>
                          <a class="dropdown-item" onClick={()=>{ setip_days(365); setip_val(12); }}>365 Days</a>
                        </div>
                      </div> */}
                        <div >

                        {/* INDIVIDUAL PLAN 28 DAYS CARDS START */}
                        <div id="individaul-28days" class="row col-12 grid-margin ">

                        {/* INDIVIDUAL 28 DAYS FREE PLAN CARD START */}
                        <div id="i-basic-28" className="col-md-3"  style={{marginTop:"3rem", boxShadow:"2px 1px 1px 2px #c6cbcd",borderRadius:"15px",padding:"1rem"}}>
                        <div style={{backgroundColor:"#0078b5", color:"#ffffff", borderRadius:"15px",padding:"0.5rem"}}>
                        <h4><b>INDIVIDUAL PLAN</b></h4>
                        <p>suited for small business</p>
                        </div><div><div></div>
                        <ul style={{textAlign:"left",fontSize:"105%", listStyleType:"none"}}>                    
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>1000 Transactions per month</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>1 Active Business</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>1 Active Counter</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>50 Customers in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>50 Suppliers in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>100 Products in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>2 Team Accounts</li> 
                        </ul>
                        <hr />
                        <h4 style={{color:"#18728b"}}><b>₹ 0/- for 30 days</b></h4>
                        <label style={{textDecoration:"line-through"}}>₹ 199/- for 30 days</label>
                        <br/>
                        <p style={{textAlign:"right",color:"#0091b9"}}><b>100% off</b></p>
                        <hr/>
                        <button class="btn btn-lg btn-info" style={{backgroundColor:"#0078b5",border:"none"}} onClick={()=>{
                        
                        // Assign value to pricingplan
                        sessionStorage.setItem("pricingplan00", "IFREE0001"); sessionStorage.setItem("planname00", "INDIVIDUAL FREE PLAN");
                        window.location.replace("/bt-freetrial");
                       }}><b>Get Started for Free</b></button>
                        </div>
                        </div>
                          {/* INDIVIDUAL 28 DAYS FREE PLAN CARD END */}


                        {/* INDIVIDUAL 28 DAYS BASIC PLAN CARD START */}
                        <div id="i-basic-28" className="col-md-3"  style={{marginTop:"3rem", boxShadow:"2px 1px 1px 2px #c6cbcd",borderRadius:"15px",padding:"1rem"}}>
                        <div style={{backgroundColor:"#26c78c", color:"#ffffff", borderRadius:"15px",padding:"0.5rem"}}>
                        <h4><b>COUNTERS PLAN</b></h4>
                        <p>suitable for medium businesses with counters</p>
                        </div><div><div></div>
                        <ul style={{textAlign:"left",fontSize:"105%", listStyleType:"none"}}>                    
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>1000 Transactions per month</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>1 Active Business</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>2 Active Counter</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>50 Customers in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>50 Suppliers in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>100 Products in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>2 Team Accounts</li> 
                        </ul>
                        <hr />
                        <h4 style={{color:"#18728b"}}><b>₹ 0/- for 30 days</b></h4>
                        <label style={{textDecoration:"line-through"}}>₹ 249/- for 30 days</label>
                        <br/>
                        <p style={{textAlign:"right",color:"#0091b9"}}><b>100% off</b></p>
                        <hr/>
                        <button class="btn btn-lg btn-info" style={{backgroundColor:"#26c78c",border:"none"}} onClick={()=>{
                        
                        // Assign value to pricingplan
                        sessionStorage.setItem("pricingplan00", "CFREE0001");sessionStorage.setItem("planname00", "COUNTERS FREE PLAN");
                        window.location.replace("/bt-freetrial");
                       }}><b>Get Started for Free</b></button>
                        </div>
                        </div>
                          {/* INDIVIDUAL 28 DAYS BASIC PLAN CARD END */}


                        {/* INDIVIDUAL 28 DAYS STANDARD PLAN CARD START */}
                        <div id="i-standard-28" className="col-md-3"  style={{marginTop:"3rem", boxShadow:"2px 1px 1px 2px #c6cbcd",borderRadius:"15px",padding:"1rem"}}>
                        <div style={{backgroundColor:"#f49938", color:"#ffffff", borderRadius:"15px",padding:"0.5rem"}}>
                        <h4><b>BUSINESS PLAN</b></h4>
                        <p>suitable for owners having more than one business</p>
                        </div><div><div></div>
                        <ul style={{textAlign:"left",fontSize:"105%", listStyleType:"none"}}>                    
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>1000 Transactions per month</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>2 Active Business</li>
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#28c878", fontSize:"110%",marginRight:"1rem"}}></i>2 Active Counter</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>50 Customers in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>50 Suppliers in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>100 Products in List Access</li> 
                        <li><i class="mdi mdi-checkbox-marked-circle" style={{color:"#ffeb00", fontSize:"110%",marginRight:"1rem"}}></i>2 Team Accounts</li> 
                        </ul>
                        <hr />
                        <h4 style={{color:"#18728b"}}><b>₹ 0/- for 30 days</b></h4>
                        <label style={{textDecoration:"line-through"}}>₹ 499/- for 30 days</label>
                        <br/>
                        <p style={{textAlign:"right",color:"#0091b9"}}><b>100% off</b></p>
                        <hr/>
                        <button class="btn btn-lg btn-info" style={{backgroundColor:"#f49938",border:"none"}} onClick={()=>{
                        
                        // Assign value to pricingplan
                        sessionStorage.setItem("pricingplan00", "BFREE0001");sessionStorage.setItem("planname00", "BUSINESS FREE PLAN");
                        window.location.replace("/bt-freetrial");
                       }}><b>Get Started for Free</b></button>
                        </div>
                        </div>
                        {/* INDIVIDUAL 28 DAYS STANDARD PLAN CARD END */}
                         

                        {/* INDIVIDUAL 28 DAYS FREE PLAN CARD START */}
                        <div id="i-basic-28" className="col-md-3"  style={{marginTop:"3rem", boxShadow:"2px 1px 1px 2px #c6cbcd",borderRadius:"15px",padding:"1rem"}}>
                        <div style={{backgroundColor:"#f53838", color:"#ffffff", borderRadius:"15px",padding:"0.5rem"}}>
                        <h4><b>CONTACT SALES</b></h4>
                        <p>get best customized plans suited to your bussiness needs</p>
                        </div><div><div></div>
                        <img src="https://cdn-icons-png.flaticon.com/128/870/870124.png" style={{height:"60%",width:"80%", marginTop:"1rem"}}></img>
                        
                        <hr/>
                        <button class="btn btn-lg btn-info" style={{backgroundColor:"#f53838",border:"none"}} onClick={()=>{
                        //Setting Values to redirect to Contact Sales Page
                        sessionStorage.setItem("Smodule00","Book Keeping");sessionStorage.setItem("Sservice00","NA"); window.location.replace("/businesstools-contactsales");
                       }}><b>Contact Sales</b></button>
                        </div>
                        </div>
                        {/* INDIVIDUAL 28 DAYS FREE PLAN CARD END */}


                        </div>
                        {/* INDIVIDUAL PLAN 28 DAYS CARDS END */}

                     {/*}   <p>Currently Showing for {ip_days} Days Plan (all prices excluding GST)</p> */}

                    
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        {/* INDIVIDUAL PLAN CARDS END */}





    </div>
    </div>
                        
                        
    

      </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
      );

}

export default Free_pricing;