import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';

import '../Resources/dashboard.css';
import Navbar from '../navbar';
import Sidebar from '../Sidebar';
import Add_inventory from './add_inventory';

import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';

//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function Userinventory(){
  
  
 //Report Print Function
 const componentRef = useref();
 const handlePrint = useReactToPrint({
   content: () => componentRef.current,
 });

 //Report Print Function 1
 const componentRef1 = useref();
 const handlePrint1 = useReactToPrint({
   content: () => componentRef1.current,
 });


  // Function to download the data as an Excel file
  const downloadExcel = () => {
    // Convert JSON data to Excel worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    
    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
    // Convert the workbook to a binary array
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    
    // Create a Blob from the binary data and trigger a download
    const excel_data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(excel_data, "inventory_data.xlsx");
  };
  
  
  
  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");


 {/* INVENTORY TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14
const[cd_col15_val,setcd_col15_val] = useState(0);//column15
const[cd_col16_val,setcd_col16_val] = useState(0);//column16
{/* INVENTORY TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES END */}

 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 /*if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00"); 

   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{


  */
    
      
//Variables

const[data,setData] = useState([]);//SetData Variable
const[summary,setsummary] = useState([]);//Setsummary Variable

//Error Variable
const[errorvar,seterrorvar] = useState();

//Variables
const[eproducts,seteproducts] = useState([],);//SetData Variable
const[ecdefault,setecdefault] = useState(0);



//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[filter_start_date,setfilter_start_date] = useState('1970-01-01'); const updatefilter_start_date = (event) => { setfilter_start_date(event.target.value);}; const[filter_start_date_er,setfilter_start_date_er]=useState();
const[filter_end_date,setfilter_end_date] = useState('2100-12-12'); const updatefilter_end_date = (event) => { setfilter_end_date(event.target.value);}; const[filter_end_date_er,setfilter_end_date_er]=useState();
const[filter_trno,setfilter_trno] = useState(); const updatefilter_trno = (event) => { setfilter_trno(event.target.value);}; const[filter_trno_er,setfilter_trno_er]=useState();
const[filter_transaction,setfilter_transaction] = useState(); const updatefilter_transaction = (event) => { setfilter_transaction(event.target.value);}; const[filter_transaction_er,setfilter_transaction_er]=useState();
const[filter_quantity_sign,setfilter_quantity_sign] = useState(); const updatefilter_quantity_sign = (event) => { setfilter_quantity_sign(event.target.value);}; const[filter_quantity_sign_er,setfilter_quantity_sign_er]=useState();
const[filter_quantity,setfilter_quantity] = useState(); const updatefilter_quantity = (event) => { setfilter_quantity(event.target.value);}; const[filter_quantity_er,setfilter_quantity_er]=useState();
const[filter_value_sign,setfilter_value_sign] = useState(); const updatefilter_value_sign = (event) => { setfilter_value_sign(event.target.value);}; const[filter_value_sign_er,setfilter_value_sign_er]=useState();
const[filter_value,setfilter_value] = useState(); const updatefilter_value = (event) => { setfilter_value(event.target.value);}; const[filter_value_er,setfilter_value_er]=useState();
const[filter_product_code,setfilter_product_code] = useState(); const updatefilter_product_code = (event) => { setfilter_product_code(event.target.value);}; const[filter_product_code_er,setfilter_product_code_er]=useState();
const[filter_product_hsn,setfilter_product_hsn] = useState(); const updatefilter_product_hsn = (event) => { setfilter_product_hsn(event.target.value);}; const[filter_product_hsn_er,setfilter_product_hsn_er]=useState();
const[filter_product_name,setfilter_product_name] = useState(); const updatefilter_product_name = (event) => { setfilter_product_name(event.target.value);}; const[filter_product_name_er,setfilter_product_name_er]=useState();
const[filter_rate,setfilter_rate] = useState(); const updatefilter_rate = (event) => { setfilter_rate(event.target.value);}; const[filter_rate_er,setfilter_rate_er]=useState();

const[filter_val1,setfilter_val1] = useState(0);
const[filter_product_name1,setfilter_product_name1] = useState();const updatefilter_product_name1 = (event) => { setfilter_product_name1(event.target.value);}; const[filter_product_name1_er,setfilter_product_name1_er]=useState();


//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);
    

useEffect(()=>{
   
  getData();
  
  },[])
 

  
 //SETTING DEFAULT STATE VARIABLES FOR CHART */
 
 //LINECHART VARIABLES
 const[lc_summary,setlc_summary] = useState([],);
 const[lc_inflow,setlc_inflow] = useState([],);
 const[lc_outflow,setlc_outflow] = useState([],);
 const[lc_netcashflow,setlc_netcashflow] = useState([],);

 //Line Chart
 const[lc_date,setlc_date] = useState();
 const[lc_default,setlc_default] = useState(0);
 const[lc_start_date,setlc_start_date] = useState(0);const updatelc_start_date = (event) => { setlc_start_date(event.target.value); }; 
 const[lc_end_date,setlc_end_date] = useState(0);const updatelc_end_date = (event) => { setlc_end_date(event.target.value); }; 
 const[lc_date_error,setlc_date_error] = useState();

 //Bar Chart
 const[bc_default,setbc_default] = useState(0);
 const[bc_start_date,setbc_start_date] = useState(0);const updatebc_start_date = (event) => { setbc_start_date(event.target.value); }; 
 const[bc_end_date,setbc_end_date] = useState(0);const updatebc_end_date = (event) => { setbc_end_date(event.target.value); }; 
 const[bc_date_error,setbc_date_error] = useState();
 const[bc_entry,setbc_entry] = useState();
 const[bc_drawings,setbc_drawings] = useState();
 const[bc_sales,setbc_sales] = useState();
 const[bc_purchases,setbc_purchases] = useState();
 const[bc_salesreturn,setbc_salesreturn] = useState();
 const[bc_purchasereturns,setbc_purchasereturns] = useState();

    
  
//******************* GET INVENTORY DATA FUNCTION START  ********************//
function getData(){

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
    
  //Fetching Filter Variables
  const $filter_start_date = filter_start_date;const $filter_end_date = filter_end_date;const $filter_trno = filter_trno;const $filter_transaction = filter_transaction;
  const $filter_quantity_sign = filter_quantity_sign;const $filter_quantity = filter_quantity;  const $filter_value_sign = filter_value_sign;const $filter_value = filter_value;
  const $filter_product_code = filter_product_code;const $filter_product_hsn = filter_product_hsn;const $filter_product_name = filter_product_name;const $filter_rate = filter_rate;
  const $filter_product_name1 = filter_product_name1;
 
 axios.post("bt-get-inventorybook-default", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0,
  //Line Chart
  lc_default:lc_default,
  lc_start_date:moment(lc_start_date).format('YYYY-MM-DD'),
  lc_end_date:moment(lc_end_date).format('YYYY-MM-DD'),
  //Bar Chart
  bc_default:bc_default,
  bc_start_date:moment(bc_start_date).format('YYYY-MM-DD'),
  bc_end_date:moment(bc_end_date).format('YYYY-MM-DD'),
   //Filters
   Filter_val : filter_val,
   Filter_start_date : moment($filter_start_date).format('YYYY-MM-DD'),
   Filter_end_date : moment($filter_end_date).format('YYYY-MM-DD'),
   Filter_trno : $filter_trno,
   Filter_transaction : $filter_transaction,
   Filter_quantity_sign : $filter_quantity_sign,
   Filter_quantity : $filter_quantity,
   Filter_value_sign : $filter_value_sign,
   Filter_value : $filter_value,
   Filter_product_code : $filter_product_code,
   Filter_product_hsn : $filter_product_hsn,
   Filter_product_name : $filter_product_name, 
   Filter_rate : $filter_rate,
   Filter_val1 : filter_val1,
   Filter_product_name1 : $filter_product_name1, 
   
   //Code Edits for pagination
   'page' : pageNumber


 })
 .then((response) => {

 
  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   setData(response.data.data.data);
   setsummary(response.data.summary.data);
   const lc_summary = response.data.lc_summary;
   // Extract data for line chart
   setlc_date(lc_summary.map((entry) => entry.Date));
   setlc_inflow(lc_summary.map((entry) => entry.Inflow));
   setlc_outflow(lc_summary.map((entry) => entry.Outflow)); 
   //extract data for bar chart
   setbc_entry(response.data.bc_entry);
   setbc_drawings(response.data.bc_drawings);
   setbc_sales(response.data.bc_sales);
   setbc_purchases(response.data.bc_purchases);
   setbc_salesreturn(response.data.bc_salesreturn);
   setbc_purchasereturns(response.data.bc_purchasereturns);

  
  }
   //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    setfilter_start_date_er(response.data.validatorerror.Filter_start_date);
    setfilter_end_date_er(response.data.validatorerror.Filter_end_date);
    setfilter_trno_er(response.data.validatorerror.Filter_trno);
    setfilter_transaction_er(response.data.validatorerror.Filter_transaction);
    setfilter_quantity_sign_er(response.data.validatorerror.Filter_quantity_sign);
    setfilter_quantity_er(response.data.validatorerror.Filter_quantity);
    setfilter_value_sign_er(response.data.validatorerror.Filter_value_sign);
    setfilter_value_er(response.data.validatorerror.Filter_value);
    setfilter_product_code_er(response.data.validatorerror.Filter_product_code);
    setfilter_product_hsn_er(response.data.validatorerror.Filter_product_hsn);
    setfilter_product_name_er(response.data.validatorerror.Filter_product_name);
    setfilter_rate_er(response.data.validatorerror.Filter_rate);
    setfilter_product_name1_er(response.data.validatorerror.Filter_product_name1);
  }

  //Data Violation
  if(response.data.error==102){ 
  
    setfilter_start_date_er(response.data.filter_start_date_er);
    setfilter_end_date_er(response.data.filter_end_date_er);
    setfilter_trno_er(response.data.filter_trno_er);
    setfilter_transaction_er(response.data.filter_transaction_er);
    setfilter_quantity_sign_er(response.data.filter_quantity_sign_er);
    setfilter_quantity_er(response.data.filter_quantity_er);
    setfilter_value_sign_er(response.data.filter_value_sign_er);
    setfilter_value_er(response.data.filter_value_er);
    setfilter_product_code_er(response.data.filter_product_code_er);
    setfilter_product_hsn_er(response.data.filter_product_hsn_er);
    setfilter_product_name_er(response.data.filter_product_name_er);
    setfilter_rate_er(response.data.filter_rate_er);
    setfilter_product_name1_er(response.data.validatorerror.Filter_product_name1);
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
  
 
 }
  
 //******************* GET INVENTORY DATA FUNCTION END  ********************//

 

 /* LINE CHART START */
 const data1 = {
  labels: lc_date,
  datasets: [
    {
      label: "Inventory Outflow",
      backgroundColor: ["rgb(255, 99, 132)" ],
      borderColor: "rgb(255, 99, 132)",
      data: lc_outflow,
    },
    {
      label: "Inventory Inflow",
      backgroundColor: ["rgb(54, 160, 255)"],
      borderColor: "rgb(54, 160, 255)",
      data: lc_inflow,
    },
  ],
};
/* LINE CHART END */ 

 /* BAR CHART VARIABLES START */
  const labels2 = ["Entry", "Drawings", "Sales", "Purchases","Salesreturn", "Purchasereturns"];

  const data2 = {
    labels: labels2,
    datasets: [
      {
        label: [],
        backgroundColor: ["#25d494", "#d42641", "#26cdd4", "#d45e26", "#010a9a", "#d943b9" ],
        borderColor: "rgb(255, 99, 132)",
        data: [bc_entry, bc_drawings, bc_sales, bc_purchases, bc_salesreturn, bc_purchasereturns],
      },
     
    ],
  };
  /* BAR CHART VARIABLES END */

 
//******************* GET INVENTORYBOOK DATA BY ID FUNCTION START  ********************//



//EDIT PRODUCT
//edate
const[edate,setedate]=useState();const updateedate = (event) => { setedate(event.target.value); }; const[edate_er,setedate_er]=useState();
//eproduct_name
const[eproduct_name,seteproduct_name]=useState();const updateeproduct_name = (event) => { seteproduct_name(event.target.value);geteproductbyname(); }; const[eproduct_name_er,seteproduct_name_er]=useState();
//eproduct_id
const[eproduct_id,seteproduct_id]=useState();const updateeproduct_id = (event) => { seteproduct_id(event.target.value);}; const[eproduct_id_er,seteproduct_id_er]=useState();
//eproduct_code
const[eproduct_code,seteproduct_code]=useState();const updateeproduct_code = (event) => { seteproduct_code(event.target.value);}; const[eproduct_code_er,seteproduct_code_er]=useState();
//eproduct_hsn
const[eproduct_hsn,seteproduct_hsn]=useState();const updateeproduct_hsn = (event) => { seteproduct_hsn(event.target.value);}; const[eproduct_hsn_er,seteproduct_hsn_er]=useState();
//etype
const[etype,setetype]=useState();const updateetype = (event) => { setetype(event.target.value);}; const[etype_er,setetype_er]=useState();
//erate
const[erate,seterate]=useState();const updateerate = (event) => { seterate(event.target.value); ecalculate(); }; const[erate_er,seterate_er]=useState();
//equantity
const[equantity,setequantity]=useState();const updateequantity = (event) => { setequantity(event.target.value); ecalculate(); }; const[equantity_er,setequantity_er]=useState();
//eunits
const[eunits,seteunits]=useState();const updateeunits = (event) => { seteunits(event.target.value);}; const[eunits_er,seteunits_er]=useState();
//eamount
const[eamount,seteamount]=useState();const updateeamount = (event) => { seteamount(event.target.value); ecalculate(); }; const[eamount_er,seteamount_er]=useState();
//eparticular
const[eparticular,seteparticular]=useState();const updateeparticular = (event) => { seteparticular(event.target.value); }; const[eparticular_er,seteparticular_er]=useState();

  //ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();

//Calculate Function
function ecalculate(){
  let erate = (document.getElementById("erate").value);if(erate==""){ erate=0;}else{ erate=parseFloat(erate);}
  let equantity = (document.getElementById("equantity").value);if(equantity==""){ equantity=0;}else{ equantity=parseFloat(equantity);}
  let eamount = parseFloat(erate*equantity);seteamount(eamount.toFixed(2));
}


//Edit Function
function getinventorydatabyId(){
 

  const ivno=sessionStorage.getItem("Ivno00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(busyid>0)&&(counterid>0)&&(ivno>0)){
 
 
 axios.post("bt-inventorybook-details-byid", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Ivno:ivno
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Ivno00");


     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data
   setedate(response.data.date);
   setetype(response.data.type);
   seteproduct_name(response.data.product_name);
   seteproduct_hsn(response.data.product_hsn);
   seteproduct_id(response.data.product_id);
   seteproduct_code(response.data.product_code);
   seteparticular(response.data.particular);
   setequantity((response.data.qty_in)+(response.data.qty_out));
   seterate((response.data.rate_in)+(response.data.rate_out));
   seteunits(response.data.units);
   seteamount((response.data.amount_in)+(response.data.amount_out));

  
  }
  //Cannot Fetch Data
  else{


      document.getElementById("edit-form").style.display="none";

     //Setting Variables to empty 
     setedate(" ");seteproduct_name(" ");setetype(" ");seteproduct_id(" ");seteproduct_code(" ");seteproduct_hsn(" ");seterate(" ");setequantity(" ");seteunits(" ");seteamount(" ");seteparticular(" ");
     seterror_msg(" ");
     
      document.getElementById("edit-error-card").style.display="block"; 
      sessionStorage.removeItem("Ivno00");
  }
 
 
 });
 
 }
 //User not Logedin
 else{

     //Checking if user loggedin
     if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))){
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
     }
 
  }
 
 }
  
 //******************* GET INVENTORYBOOK DATA BY ID FUNCTION END  ********************//


 //******************* EDIT INVENTORY FUNCTION START ********************//
function editinventory(){

  //Setting Error Variables
  setedate_er(" ");seteproduct_name_er(" ");setetype_er(" ");seteproduct_id_er(" ");seteproduct_code_er(" ");seteproduct_hsn_er(" ");seterate_er(" ");setequantity_er(" ");seteunits_er(" ");seteamount_er(" ");seteparticular_er(" ");
  seterror_msg(" ");

  const ivno = sessionStorage.getItem("Ivno00");
  const trno = sessionStorage.getItem("Trno00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((ivno!=null)||(ivno!=" "))&&((trno!=null)||(trno!=" "))){
 
 
  axios.post("bt-inventorybook-edit", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Ivno:ivno,
   Tr_no:trno,
   Date:moment(edate).format('YYYY-MM-DD'),
   Type:etype,
   Product_id:eproduct_id,
   Product_name:eproduct_name,
   Product_code : eproduct_code,
   Product_hsn : eproduct_hsn,
   Quantity:equantity,
   Rate:erate,
   Units:eunits,
   Particular:eparticular
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Trno00");
     sessionStorage.removeItem("Ivno00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setetype_er(response.data.validatorerror.Type);
  seteproduct_name_er(response.data.validatorerror.Product_name);
  seteproduct_id_er(response.data.validatorerror.Product_id);
  seteproduct_code_er(response.data.validatorerror.Product_code);
  seteproduct_hsn_er(response.data.validatorerror.Product_hsn);
  setedate_er(response.data.validatorerror.Date);
  setequantity_er(response.data.validatorerror.Quantity);
  seterate_er(response.data.validatorerror.Rate);
  seteunits_er(response.data.validatorerror.Units);
  seteparticular_er(response.data.validatorerror.Particular);


}
//Invalid Entries
if(response.data.error==102){ 
  
  setedate_er(response.data.date_er);
  setetype_er(response.data.type_er);
  seteproduct_name_er(response.data.product_name_er);
  seteproduct_code_er(response.data.product_code_er);
  seteproduct_id_er(response.data.product_id_er);
  seteproduct_hsn_er(response.data.product_hsn_er);
  seterate_er(response.data.rate_er);
  setequantity_er(response.data.quantity_er);
  seteunits_er(response.data.units_er);
  seteparticular_er(response.data.particular_er);
 
  seterror_msg("Cannot Update Transaction!");  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  }

   //Transaction Updated Successfully
   if(response.data.successvar==1){        
     //Setting Variables to empty 
     setedate(" ");seteproduct_name(" ");setetype(" ");seteproduct_id(" ");seteproduct_code(" ");seteproduct_hsn(" ");seterate(" ");setequantity(" ");seteunits(" ");seteamount(" ");seteparticular(" ");
     setedate_er(" ");seteproduct_name_er(" ");setetype_er(" ");seteproduct_id_er(" ");seteproduct_code_er(" ");seteproduct_hsn_er(" ");seterate_er(" ");setequantity_er(" ");seteunits_er(" ");seteamount_er(" ");seteparticular_er(" ");
     seterror_msg(" ");
    document.getElementById("edit-success-card").style.display="block"; 
    document.getElementById("edit-form").style.display="none"; 
  
  }

   //Cannot Update Transaction
   else{  seterror_msg("Cannot Update Transaction !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("Trno00");
   sessionStorage.removeItem("Ivno00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT INVENTORYBOOK FUNCTION END ********************//


 
 
   
//******************* DELETE INVENTORYBOOK FUNCTION START ********************//
function deleteinventory(){

  const Ivno=sessionStorage.getItem("DIvno00");
  const dtrno=sessionStorage.getItem("DTrno00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((Ivno!=null)||(Ivno!=" "))&&((dtrno!=null)||(dtrno!=" "))){
 
 axios.post("bt-inventorybook-delete", {
   Busy_id:busyid,
   Counter_id:counterid,
   Log_id:logid,
   Token:token,
   Ivno:Ivno,
   Tr_no:dtrno
 })
 .then((response) => {

  //console.log(response);

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("DIvno00");
     sessionStorage.removeItem("DTrno00");

     window.location.replace("businesstools-login");
  }

  //Counter Deleted Successfully
   if(response.data.successvar==1){        

      document.getElementById("delete-card").style.display="none";
      document.getElementById("delete-success-card").style.display="block";  
      sessionStorage.removeItem("DIvno00");
      sessionStorage.removeItem("DTrno00");

  }
  
      //Cannot Delete Counter
    else{  
    document.getElementById("delete-card").style.display="none";
    document.getElementById("delete-error-card").style.display="block";   }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("DIvno00");
   sessionStorage.removeItem("DTrno00");


   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* DELETE INVENTORYBOOK FUNCTION END ********************//


 
    //******************* GET PRODUCT DATA BY NAME FUNCTION START  ********************//
function geteproductbyname(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
     const cname = document.getElementById("eproduct_name").value;
     const cnlength = cname.length;
     if((ecdefault==0)&&(cnlength>=3)){
     
 
 axios.post("bt-get-productlist-spr", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Name:document.getElementById("eproduct_name").value,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {

  //console.log(response);

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   seteproducts(response.data.productlist);
   document.getElementById("eproducts-list-box").style.display="block";
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
}
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }

 }
  
 //******************* GET PRODUCT DATA BY NAME FUNCTION END  ********************//








return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

      <Sidebar />
    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">

            {/* SUCCESS CARD START */}
            <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">New Transaction Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="businesstools-user-inventory"><button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* SUCCESS CARD END */}
                    {/* EDIT SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Transaction Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT SUCCESS CARD END */}
               {/* EDIT ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Fetch Data !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT ERROR CARD END */}
               {/* DELETE CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Are you sure you want to delete this transaction?</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> This action cannot be reversed. You will lose all your data related to this transaction if you wish to continue this action.</p>
                      <button class="mt-3 btn btn-outline-success" onClick={deleteinventory}>Yes, I Know! Delete Transaction.</button>
                      <button class="mt-3 btn btn-outline-danger" style={{marginLeft:"1rem"}} onClick={()=>{  
                      //Cancel Delete and remove Counter_id
                      sessionStorage.removeItem("DIvno00");     sessionStorage.removeItem("DTrno00");  document.getElementById('delete-card').style.display = 'none'; }}>No, Don't Delete Transaction.</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE CARD END */}
              {/* DELETE SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Transaction Deleted Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE SUCCESS CARD END */}
               {/*  DELETE ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Delete Transaction !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE ERROR CARD END */}
          <div class="content-wrapper pb-0">

          <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form">
              <Add_inventory /> 
              </div>
          </div>


            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Inventory</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                  {/*}  <p class="m-0">ADE-00234</p> */}
                  </a>
                </div>
                <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i class="mdi mdi-plus-circle"></i> New Transaction </button>
              </div>
            </div>

            {/*} chart row starts here */}
            <div className="row">

{/* LINECHART START */}
  <div className="col-sm-6 stretch-card grid-margin">
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between">
        <div className="card-title"> Counter Inventory Flow
          </div>
       
          <div className="d-flex text-muted font-20">
          {/*  <i className="mdi mdi-printer mouse-pointer"></i>
            <i className="mdi mdi-help-circle-outline ms-2 mouse-pointer">  </i> */}
            <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("lc-date-search-box").style.display="block"; }}></i>
            <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("lc-date-search-box").style.display="none"; }}></i>
          
          </div>
        </div>
        {/* LINE CHART DATE FILTER START */}
        <div id="lc-date-search-box" style={{display:"none"}}>
        <hr />
        <p style={{marginTop:"2rem"}}>Make sure start date and end date are not more than 10 days apart for best results</p>
        <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label"> Start Date</label>
                <div className="col-sm-9">
                  <input type="date" className="form-control-sm"  id="lc_start_date" value={lc_start_date} onChange={updatelc_start_date} />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">End Date</label>
                <div className="col-sm-9">
                  <input type="date" className="form-control-sm" id="lc_end_date" value={lc_end_date} onChange={updatelc_end_date} />
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-primary mb-4" onClick={()=>{ setlc_default(1); getData(); }}>Update</button>
          <hr/>
          </div>
          {/* LINE CHART DATE FILTER END */}
        <div className="line-chart-wrapper">
      {/* CASH INFLOW OUTFLOW LINE CHART START */}
      <Line data={data1} /> 
      {/* CASH INFLOW OUTFLOW LINE CHART END */}
          
        </div>
      </div>
    </div>
  </div>
   {/* LINECHART END */}
    {/* BARCHART START */}
  <div className="col-sm-6 stretch-card grid-margin">
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <div className="card-title"> Business Transactions Summary
          </div>
          <div className="d-flex text-muted font-20">
            <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("bc-date-search-box").style.display="block"; }}></i>
            <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("bc-date-search-box").style.display="none"; }}></i>
          
          </div>
        </div>
            {/*BAR CHART DATE FILTER START */}
            <div id="bc-date-search-box" style={{display:"none"}}>
        <hr />
        <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label"> Start Date</label>
                <div className="col-sm-9">
                  <input type="date" className="form-control-sm"  id="bc_start_date" value={bc_start_date} onChange={updatebc_start_date} />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">End Date</label>
                <div className="col-sm-9">
                  <input type="date" className="form-control-sm" id="bc_end_date" value={bc_end_date} onChange={updatebc_end_date} />
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-primary mb-4" onClick={()=>{ setbc_default(1); getData(); }}>Update</button>
          <hr/>
          </div>
          {/* BAR CHART DATE FILTER END */}
        
          <div className="bar-chart-wrapper">
          {/* SUMMARY BARCHART START */}
          <Bar data={data2} />
          {/* SUMMARY BARCHART END */}
        </div>
      </div>
    </div>
  </div>
  {/* BARCHART END */}
</div>

            
                     {/* PRODUCT LIST BOX START */}
         <div class="d-flex justify-content-center">
          <div class="col-md-4 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"-21rem",display:"none"}} id="eproducts-list-box">
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div>
                    <h4 style={{padding:'1rem'}}>Products</h4>
                    </div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ setecdefault(1); document.getElementById("eproducts-list-box").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    
                    <table class="table table-hover">
                      <thead>
                        <tr>
                        {/*}  <th>Product Id</th> */}
                          <th>Product Name</th>
                        {/*}  <th>Product HSN</th>
                          <th>Product Code</th> */}
                          <th>Purchase Rate</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                       eproducts.map((curElm17,ind)=>{

                         return(
                         <>
                        <tr key={curElm17.Product_id}>
                          {/* <td>{curElm17.Product_id}</td> */}
                          <td>{curElm17.Product_name}</td>
                         {/*} <td>{curElm17.Product_hsn}</td>
                          <td>{curElm17.Product_code}</td> */}
                          <td>{curElm17.Purchase_rate}</td>
                          <td><i class="mdi mdi-assistant text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Product Details for 
                        seteproduct_code(curElm17.Product_code);seteproduct_hsn(curElm17.Product_hsn);seteproduct_name(curElm17.Product_name); seterate(curElm17.Purchase_rate);seteunits(curElm17.Units);
                        document.getElementById("eproducts-list-box").style.display="none";
                              }}></i></td>

                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                      </tbody>
                    </table>
              
                </div>
              </div>
              </div>
              {/* PRODUCT LIST BOX END */}

               {/* INVENTORYBOOK EDIT FORM START */}
               <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                        //close edit form button
                        document.getElementById("edit-form").style.display="none";
                        //Setting Variables to empty
                        //Setting Variables to empty 
                        setedate(" ");seteproduct_name(" ");setetype(" ");seteproduct_id(" ");seteproduct_code(" ");seteproduct_hsn(" ");seterate(" ");setequantity(" ");seteunits(" ");seteamount(" ");seteparticular(" ");
                        setedate_er(" ");seteproduct_name_er(" ");setetype_er(" ");seteproduct_id_er(" ");seteproduct_code_er(" ");seteproduct_hsn_er(" ");seterate_er(" ");setequantity_er(" ");seteunits_er(" ");seteamount_er(" ");seteparticular_er(" ");
                        seterror_msg(" ");                
                        sessionStorage.removeItem("Ivno00");
                        sessionStorage.removeItem("Trno00");
                            }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Inventory Transaction</h4>
                    <div class="form-sample">
                      <p class="card-description">edit your inventory transactions here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="edate" value={edate} onChange={updateedate}/>
                              <code>{edate_er}</code>
                            </div>
                          </div>
                        </div>  
                        </div>
                      <div class="row">    
                        <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Red T-Shirt" id="eproduct_name" value={eproduct_name} onChange={updateeproduct_name} />
                            <code>{eproduct_name_er}</code>
                            </div>
                          </div>
                        </div>   
                           <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type<code>*</code></label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} placeholder="Select Transaction" id="etype" value={etype} onChange={updateetype}>
                              <option value="">Select Transaction</option>
                              <option value="entry">Entry</option>
                              <option value="drawings">Drawings</option>
                              </select>
                              <code>{etype_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Code<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="214" id="eproduct_code" value={eproduct_code} onChange={updateeproduct_code} />
                              <code>{eproduct_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">HSN Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="755444" id="eproduct_hsn" value={eproduct_hsn} onChange={updateeproduct_hsn} />
                              <code>{eproduct_hsn_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                  
                    
                   
                 
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Quantity<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="150.00" id="equantity" value={equantity} onChange={updateequantity} />
                              <code>{equantity_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Units<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="pieces" id="eunits" value={eunits} onChange={updateeunits} />
                              <code>{eunits_er}</code>
                            </div>
                          </div>
                        </div>                 
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Rate<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="erate" value={erate} onChange={updateerate} />
                              <code>{erate_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Value</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="7500.00" id="eamount" value={eamount} onChange={updateeamount} />
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="form-group">
                        <label for="exampleTextarea1">Particular</label>
                        <textarea class="form-control" rows="2" id="eparticular" value={eparticular} onChange={updateeparticular} ></textarea>
                        <code>{eparticular_er}</code>
                      </div>


                      
                      <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={editinventory}>Submit</button>
                       
                       
    
                     
                   
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* INVENTORYBOOK EDIT FORM END */}
                  
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Inventory Transactions</h4>
                    <p class="card-description">view inventory transactions here
                    </p>

                                          {/* FILTER SECTION START */}
                                          <hr/>
                            <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Start Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control" id="filter_start_date" value={filter_start_date} onChange={updatefilter_start_date}  />
                              <code>{filter_start_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">End Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control"  id="filter_end_date" value={filter_end_date} onChange={updatefilter_end_date}  />
                              <code>{filter_end_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Tr No.</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="12345"   id="filter_trno" value={filter_trno} onChange={updatefilter_trno}  />
                              <code>{filter_trno_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Product Code</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="PC12345" id="filter_product_code" value={filter_product_code} onChange={updatefilter_product_code}  />
                              <code>{filter_product_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">HSN Code</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="487512"  id="filter_product_hsn" value={filter_product_hsn} onChange={updatefilter_product_hsn}  />
                              <code>{filter_product_hsn_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Product Name</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="Product ABC"   id="filter_product_name" value={filter_product_name} onChange={updatefilter_product_name}  />
                              <code>{filter_product_name_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Quantity</label>
                            <div class="col-sm-4">
                              <select class="form-control" style={{padding:"1rem"}} id="filter_quantity_sign" value={filter_quantity_sign} onChange={updatefilter_quantity_sign}  >
                              <option className="dropdown-item" value=" ">All</option>
                              <option className="dropdown-item" value="equals">equals</option>
                              <option className="dropdown-item" value="more than" >more than</option>
                              <option className="dropdown-item" value="less than">less than</option>
                              <option className="dropdown-item" value="more than or equals" >more than or equals</option>
                              <option className="dropdown-item" value="less than or equals" >less than or equals</option>
                              </select>
                              <code>{filter_quantity_sign_er}</code>
                            </div>
                            <div class="col-sm-4">
                              <input type="text" class="form-control" placeholder="50.00" id="filter_quantity" value={filter_quantity} onChange={updatefilter_quantity}   />
                              <code>{filter_quantity_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Transaction</label>
                            <div class="col-sm-8">
                             <select class="form-control" style={{padding:"1rem"}} id="filter_transaction" value={filter_transaction} onChange={updatefilter_transaction} >
                             <option value=" ">Select Transaction</option>
                             <option value="entry">entry</option>
                             <option value="drawings">drawings</option>
                             <option value="sales">sales</option>
                             <option value="purchases">purchases</option>
                             <option value="salesreturn">salesreturn</option>
                             <option value="purchasereturns">purchasereturns</option>
                             </select>                              
                             <code>{filter_transaction_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                      <div class="col-md-8">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Value</label>
                            <div class="col-sm-4">
                              <select class="form-control" style={{padding:"1rem"}} id="filter_value_sign" value={filter_value_sign} onChange={updatefilter_value_sign}  >
                              <option className="dropdown-item" value=" ">All</option>
                              <option className="dropdown-item" value="equals">equals</option>
                              <option className="dropdown-item" value="more than" >more than</option>
                              <option className="dropdown-item" value="less than">less than</option>
                              <option className="dropdown-item" value="more than or equals" >more than or equals</option>
                              <option className="dropdown-item" value="less than or equals" >less than or equals</option>
                              </select>
                              <code>{filter_value_sign_er}</code>
                            </div>
                            <div class="col-sm-4">
                              <input type="text" class="form-control" placeholder="5000.00" id="filter_value" value={filter_value} onChange={updatefilter_value}   />
                              <code>{filter_value_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Rate</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="500.00"   id="filter_rate" value={filter_rate} onChange={updatefilter_rate}  />
                              <code>{filter_rate_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                         
                          <button class="btn btn-primary" onClick={()=>{ setfilter_val(1); getData(); }}>Apply Filters</button>
                         <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val(0); getData(); }}>Remove Filters</button>
                       
                     
                        </div>
                      <div style={{marginBottom:"1rem"}}>
                  
                       
                      </div>
                            <hr/>                      
                            </div>
                            
                            {/* FILTER SECTION END */}

                    <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                            <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tr No.</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Transaction</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Product Code</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">HSN Code</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Product Name</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Quantity</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                         {/*}     <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Quantity Out</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li>  */}
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Rate</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Value In</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 12 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Value Out</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c12_yes" onClick={()=>{setcd_col12_val(1); document.getElementById("cd_c12_yes").style.display="none"; document.getElementById("cd_c12_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c12_no" style={{ display:"none"}} onClick={()=>{ setcd_col12_val(0); document.getElementById("cd_c12_yes").style.display="block"; document.getElementById("cd_c12_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 13 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated By</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c13_yes" onClick={()=>{setcd_col13_val(1); document.getElementById("cd_c13_yes").style.display="none"; document.getElementById("cd_c13_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c13_no" style={{ display:"none"}} onClick={()=>{ setcd_col13_val(0); document.getElementById("cd_c13_yes").style.display="block"; document.getElementById("cd_c13_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 14 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated At</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c14_yes" onClick={()=>{setcd_col14_val(1); document.getElementById("cd_c14_yes").style.display="none"; document.getElementById("cd_c14_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c14_no" style={{ display:"none"}} onClick={()=>{ setcd_col14_val(0); document.getElementById("cd_c14_yes").style.display="block"; document.getElementById("cd_c14_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 15 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c15_yes" onClick={()=>{setcd_col15_val(1); document.getElementById("cd_c15_yes").style.display="none"; document.getElementById("cd_c15_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c15_no" style={{ display:"none"}} onClick={()=>{ setcd_col15_val(0); document.getElementById("cd_c15_yes").style.display="block"; document.getElementById("cd_c15_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 16 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-danger">Delete</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c16_yes" onClick={()=>{setcd_col16_val(1); document.getElementById("cd_c16_yes").style.display="none"; document.getElementById("cd_c16_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c16_no" style={{ display:"none"}} onClick={()=>{ setcd_col16_val(0); document.getElementById("cd_c16_yes").style.display="block"; document.getElementById("cd_c16_no").style.display="none"; }}></i>
                              </div></div></li> 
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>

                    <div class="table-responsive" id="reports" ref={componentRef}>
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Sn</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Tr No.</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1} >Date</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1} >Transaction</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col5_val==1} >Product Code</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >HSN Code</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1} >Product Name</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col8_val==1} >Quantity</th>
                           {/*} <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1} >Quantity Out</th>*/}
                            <th  style={{fontWeight:"bold"}} hidden={cd_col10_val==1} >Rate</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col11_val==1} >Value In</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col12_val==1} >Value Out</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col13_val==1} >Updated By</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col14_val==1} >Updated At</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col15_val==1} ></th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col16_val==1} ></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                        <tr key={curElm.Id}>
                            <td hidden={(logtype == "user")||(cd_col1_val==1)}>{curElm.Admin_sn}</td>
                            <td hidden={(logtype == "admin")||(cd_col1_val==1)}>{curElm.User_sn}</td>
                            <td  hidden={cd_col2_val==1}>{curElm.Tr_no}</td>
                            <td  hidden={cd_col3_val==1}>{curElm.Date}</td>
                            <td  hidden={cd_col4_val==1}><label class="badge badge-danger" hidden={(curElm.Type == "entry")||(curElm.Type == "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")}>{curElm.Type}</label>
                            <label class="badge badge-success" hidden={(curElm.Type == "drawings")||(curElm.Type== "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")}>{curElm.Type}</label>
                            <label class="badge badge-primary" hidden={(curElm.Type == "drawings")||(curElm.Type== "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "entry")||(curElm.Type == "purchasereturns")}>{curElm.Type}</label>
                            <label class="badge badge-warning" hidden={(curElm.Type == "drawings")||(curElm.Type== "purchases")||(curElm.Type == "salesreturn")||(curElm.Type == "entry")||(curElm.Type == "purchasereturns")}>{curElm.Type}</label>
                            <label class="badge badge-info" hidden={(curElm.Type == "drawings")||(curElm.Type== "purchases")||(curElm.Type == "sales")||(curElm.Type == "entry")||(curElm.Type == "purchasereturns")}>{curElm.Type}</label>
                            <label class="badge badge-dark" hidden={(curElm.Type == "drawings")||(curElm.Type== "purchases")||(curElm.Type == "sales")||(curElm.Type == "entry")||(curElm.Type == "salesreturn")}>{curElm.Type}</label>
                            </td>
                            <td  hidden={cd_col5_val==1} >{curElm.Product_code}</td>
                            <td  hidden={cd_col6_val==1} >{curElm.Hsn_code}</td>
                            <td  hidden={cd_col7_val==1} >{curElm.Product_name}</td>
                            <td  hidden={cd_col8_val==1} >{(curElm.Qty_in+curElm.Qty_out)} {curElm.Units}</td>
                           {/*} <td  hidden={cd_col9_val==1} >{curElm.Qty_out} {curElm.Units}</td> */}
                            <td  hidden={cd_col10_val==1} >{curElm.Rate_in+curElm.Rate_out}</td>
                            <td  hidden={cd_col11_val==1} >{curElm.Amount_in}</td>
                            <td  hidden={cd_col12_val==1} >{curElm.Amount_out}</td>
                            <td  hidden={cd_col13_val==1} >{curElm.Updated_by}</td>
                            <td  hidden={cd_col14_val==1} >{curElm.Date_updated} ({curElm.Time_updated})</td>
                            <td  hidden={cd_col15_val==1} ><i  hidden={(curElm.Type!="entry")&&(curElm.Type!="drawings")} class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Cash Id and Redirect to Edit
                      sessionStorage.setItem("Ivno00",curElm.Ivno);sessionStorage.setItem("Trno00",curElm.Tr_no);getinventorydatabyId();document.getElementById('edit-form').style.display = 'block'; }}></i></td>
                            <td  hidden={cd_col16_val==1} ><i  hidden={(curElm.Type!="entry")&&(curElm.Type!="drawings")} class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Counter Id for Delete
                      sessionStorage.setItem("DIvno00",curElm.Ivno);      sessionStorage.setItem("DTrno00",curElm.Tr_no); document.getElementById('delete-card').style.display = 'block'; }}></i></td>

                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                          
                        
                        </tbody>
                      </table>
                    </div>
                      {/* PAGINATION SECTION START */}
                      <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel}><i class="mdi mdi-file-excel text-dark"></i></button>

                  </div>
                </div>
              </div>

            

              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Inventory Stock Value</h4>
                    <p class="card-description">Inventory Stock
                    </p>

                    
                        {/* FILTER SECTION 1 START */}
                        <hr/>
                            <div id="filter-top-card1" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card1").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                       
                      
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Product Name</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="Product ABC" id="filter_product_name1" value={filter_product_name1} onChange={updatefilter_product_name1}  />
                              <code>{filter_product_name1_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                        <button class="btn btn-primary" onClick={()=>{ setfilter_val1(1); getData(); }}>Apply Filters</button>
                        <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val1(0); getData(); }}>Remove Filters</button>
              
                        </div>
                      
                        </div>

                            <hr/>                      
                            </div>
                            
                      {/* FILTER SECTION 1 END */}

                    <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card1").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                          </div>
                          </div>
                          </div>

                    <div class="table-responsive" id="reports" ref={componentRef1}>
                      <table class="table table-striped">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}}>Product Name</th>
                            <th  style={{fontWeight:"bold"}}>Stock Inflow</th>
                            <th  style={{fontWeight:"bold"}}>Stock Outflow</th>
                            <th  style={{fontWeight:"bold"}}>Current Stock</th>
                            <th  style={{fontWeight:"bold"}}>Stock Value</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       summary.map((curElm15,ind)=>{

                         return(
                         <>
                        <tr key={curElm15.Product_name}>
                            <td>{curElm15.Product_name}</td>
                            <td>{parseFloat(curElm15.Quantity_in).toFixed(2)}</td>
                            <td>{parseFloat(curElm15.Quantity_out).toFixed(2)}</td>
                            <td>{parseFloat((curElm15.Quantity_in)-(curElm15.Quantity_out)).toFixed(2)}</td>
                           {/*} <td>{Math.round(((curElm15.Quantity_in)-(curElm15.Quantity_out))*(curElm15.Rate),2)}</td> */}
                            <td class="text-success" hidden={(curElm15.Quantity_in)<(curElm15.Quantity_out)}>{Math.round(((curElm15.Quantity_in)-(curElm15.Quantity_out))*(curElm15.Rate),2)}<i class="mdi mdi-arrow-up"></i></td>
                            <td class="text-danger" hidden={(curElm15.Quantity_in)>=(curElm15.Quantity_out)}>{Math.round(((curElm15.Quantity_in)-(curElm15.Quantity_out))*(curElm15.Rate),2)}<i class="mdi mdi-arrow-down"></i></td>
 
                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                        </tbody>
                      </table>
                    </div>
                        {/* PAGINATION SECTION START */}
                        <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint1}><i class="mdi mdi-cloud-print text-dark"></i></button>
      
                  </div>
                </div>
              </div>

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
        {/*}  <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

//}
}
export default Userinventory;