import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';


function Adminlogin(){

    
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");


 // Access value associated with the Username
//const User=sessionStorage.getItem("username00");

//saving default values with usestate
const state = useState();

  //username
  const[username,setusername]=useState();const updateusername = (event) => { setusername(event.target.value);}; const[username_er,setusername_er]=useState();
  //email
  const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};  const[email_er,setemail_er]=useState();
  //password
  const[password,setpassword]=useState();const updatepassword = (event) => { setpassword(event.target.value);};  const[password_er,setpassword_er]=useState();

 //ERROR VARIABLES 
 const[error_msg,seterror_msg]=useState();
    
  //Redirecting to Services Portal if LoggedIn or Sessions Set
  useEffect(() => { if((logstatus=="true")&&(logid>0)&&(token!=" ")){       window.location.replace("/businesstools-businesses");   } });



  /***************** ADMIN LOGIN FUNCTION START  ******************/
  function adminlogin(){



  //Setting Error Variables
  setusername_er(" ");
  setemail_er(" ");
  setpassword_er(" ");
  seterror_msg(" ");

   
  //Fetching Form Data
    let noerror = 0;let enoerror = 0; let $username =""; let $email="";
    $username = document.getElementById("username").value; if($username==""){ seterror_msg("Username or Email is required !");noerror = noerror+1; }//Fetching Username Value
    if($username==""){ $email = document.getElementById("email").value; if($email==""){ seterror_msg("Username or Email is required !");enoerror = enoerror+1; }}//Fetching Email Value
    const $password = document.getElementById("password").value; if($password==""){ setpassword_er("Password is required !");noerror = noerror+1;enoerror = enoerror+1; }//Fetching Password Value
   
  //No primary errors
if((noerror==0)||(enoerror==0)){
    //Sending Data through axios
    axios.post("bt-admin-login", { 
     Email:$email,
     Username:$username,
     Password:$password
   
})

   .then((response) => {


     //Validation Errors
   if(response.data.validatorerror){
     setusername_er(response.data.validatorerror.Username);
     setemail_er(response.data.validatorerror.Email);
     setpassword_er(response.data.validatorerror.Password);
   
   }
   //Invalid Entries
   if(response.data.error==102){ seterror_msg("Invalid Username, Email or Password!");  }
   if(response.data.error==106){ seterror_msg("Cannot Login, Try Again!"); }

   //Success
   if(response.data.successvar==1){

    //Setting Sessions 
    sessionStorage.setItem("logId00", response.data.log_id); //Assign value to log_id 
    sessionStorage.setItem("logToken00", response.data.token); //Assign value to token
    sessionStorage.setItem("logStatus00", "true"); //Assign value to logstatus
    sessionStorage.setItem("logType00", "admin"); //Assign value to logtype
    sessionStorage.setItem("logName00", response.data.name); //Assign value to token

   //alert("User Logged In Successfully!");
   //  window.location.replace("/candidatedetails");
   //window.location.replace("/businesstools-startworking");
  // document.getElementById("success-card").style.display="block";
  window.location.replace("/businesstools-businesses");  


   
   }
   //failure
   else{
   seterror_msg("Invalid Username, Email or Password!");

   
   }
 }    );

}
//Return Errors
else{

  

 return;
}

}
  /***************** ADMIN LOGIN FUNCTION END  ******************/




return(
    <>
        <div class="card">
                  <div class="card-body">
                 {/*} <div style={{backgroundColor:"#1b5ece",textAlign:"center",borderRadius:"5px"}}>
                    <h2 class="card-title" style={{color:"#ffffff",padding:"1rem",fontSize:"1.2rem"}}>Login to Startup Connect</h2>
                    </div> */}
                    <div style={{textAlign:"center",borderRadius:"5px", borderBottom:"0.2rem solid #1b5ece"}}>
                    <h2 class="card-title" style={{padding:"1rem",fontSize:"1.2rem"}}>Login to Startup Connect</h2>
                    </div>
                    <p  style={{textAlign:"center"}}><code>{error_msg}</code></p>
                    <div class="forms-sample">
                      <div class="form-group">
                        <label for="exampleInputUsername1">Username</label>
                        <input type="text" class="form-control" id="username" placeholder="Username" value={username} onChange={updateusername} />
                        <code>{username_er}</code>
                      </div>
                      <div style={{fontSize:"0.8rem",textAlign:"center"}}>Or</div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Email</label>
                        <input type="email" class="form-control" id="email" placeholder="Email" value={email} onChange={updateemail} />
                        <code>{email_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword1">Password</label>
                        <input type="password" class="form-control" id="password" placeholder="Password" value={password} onChange={updatepassword} />
                        <code>{password_er}</code>
                      </div>
                      <div class="form-check form-check-flat form-check-primary">
                        {/*<label class="form-check-label">
                          <input type="checkbox" class="form-check-input" /> Remember me </label> */}
                      </div>
                      <button type="submit" id="login-btn" class="btn-lg btn-primary me-2" onClick={adminlogin}>Login</button>
                      <br/>
                      <div style={{textAlign:"right"}}><a href="/businesstools-forgetpass"><p>Forget Password</p></a></div>
                    </div>
                    <p><span><b>New User </b></span><a href="/businesstools-admin-register"> register here</a></p>

                  </div>
                 <button class="btn btn-light" onClick={()=>{ window.location.replace("/businesstools-team-login"); }}><i class="mdi mdi-account-circle menu-icon" style={{fontSize:"1.5rem"}}></i> Login as User</button>
                </div>
    </>
);

}

export default Adminlogin;

