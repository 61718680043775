import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';


import '../Resources/dashboard.css';
import Navbar from '../navbar';
import Sidebar from '../Sidebar';


import Chart from "chart.js/auto";
import { Line, Pie } from "react-chartjs-2";

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';

//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function Usercreditors(){


 //Report Print Function
 const componentRef = useref();
 const handlePrint = useReactToPrint({
   content: () => componentRef.current,
 });

 //Report Print Function 1
 const componentRef1 = useref();
 const handlePrint1 = useReactToPrint({
   content: () => componentRef1.current,
 });
  

 
   // Function to download the data as an Excel file
   const downloadExcel = () => {
    // Convert JSON data to Excel worksheet
    const worksheet = XLSX.utils.json_to_sheet(summary);
    
    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
    // Convert the workbook to a binary array
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    
    // Create a Blob from the binary data and trigger a download
    const excel_data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(excel_data, "creditors_summary_data.xlsx");
  };
  
  
  // Function to download the data as an Excel file
  const downloadExcel1 = () => {
    // Convert JSON data to Excel worksheet
    const worksheet1 = XLSX.utils.json_to_sheet(data);
    
    // Create a new workbook and add the worksheet
    const workbook1 = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook1, worksheet1, "Sheet1");
    
    // Convert the workbook to a binary array
    const excelBuffer1 = XLSX.write(workbook1, { bookType: "xlsx", type: "array" });
    
    // Create a Blob from the binary data and trigger a download
    const excel_data1 = new Blob([excelBuffer1], { type: "application/octet-stream" });
    saveAs(excel_data1, "creditors_data.xlsx");
  };

  //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");


 
 
{/* CREDITOR TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14
const[cd_col15_val,setcd_col15_val] = useState(0);//column15


{/* CREDITOR TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES END */}


 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 /*if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{
*/



  

    
      
//Variables
const[banks,setbanks] = useState([]);//SetData Variable
const[data,setData] = useState([]);//SetData Variable
const[summary,setsummary] = useState([]);//Setsummary Variable
const [osn,setosn] = useState(0);

//Error Variable
const[errorvar,seterrorvar] = useState();



//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[filter_start_date,setfilter_start_date] = useState('1970-01-01'); const updatefilter_start_date = (event) => { setfilter_start_date(event.target.value);}; const[filter_start_date_er,setfilter_start_date_er]=useState();
const[filter_end_date,setfilter_end_date] = useState('2100-12-12'); const updatefilter_end_date = (event) => { setfilter_end_date(event.target.value);}; const[filter_end_date_er,setfilter_end_date_er]=useState();
const[filter_trno,setfilter_trno] = useState(); const updatefilter_trno = (event) => { setfilter_trno(event.target.value);}; const[filter_trno_er,setfilter_trno_er]=useState();
const[filter_transaction,setfilter_transaction] = useState(); const updatefilter_transaction = (event) => { setfilter_transaction(event.target.value);}; const[filter_transaction_er,setfilter_transaction_er]=useState();
const[filter_type,setfilter_type] = useState(); const updatefilter_type = (event) => { setfilter_type(event.target.value);}; const[filter_type_er,setfilter_type_er]=useState();
const[filter_amount_in_sign,setfilter_amount_in_sign] = useState(); const updatefilter_amount_in_sign = (event) => { setfilter_amount_in_sign(event.target.value);}; const[filter_amount_in_sign_er,setfilter_amount_in_sign_er]=useState();
const[filter_amount_in,setfilter_amount_in] = useState(); const updatefilter_amount_in = (event) => { setfilter_amount_in(event.target.value);}; const[filter_amount_in_er,setfilter_amount_in_er]=useState();
const[filter_amount_out_sign,setfilter_amount_out_sign] = useState(); const updatefilter_amount_out_sign = (event) => { setfilter_amount_out_sign(event.target.value);}; const[filter_amount_out_sign_er,setfilter_amount_out_sign_er]=useState();
const[filter_amount_out,setfilter_amount_out] = useState(); const updatefilter_amount_out = (event) => { setfilter_amount_out(event.target.value);}; const[filter_amount_out_er,setfilter_amount_out_er]=useState();
const[filter_cdname,setfilter_cdname] = useState(); const updatefilter_cdname = (event) => { setfilter_cdname(event.target.value);}; const[filter_cdname_er,setfilter_cdname_er]=useState();
const[filter_val1,setfilter_val1] = useState(0);
const[filter_cdname1,setfilter_cdname1] = useState();const updatefilter_cdname1 = (event) => { setfilter_cdname1(event.target.value);}; const[filter_cdname1_er,setfilter_cdname1_er]=useState();


//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

useEffect(()=>{
   
  getData();
  
  },[])
 

  
  
//SETTING DEFAULT STATE VARIABLES FOR CHART */
 
 //LINECHART VARIABLES
 const[lc_summary,setlc_summary] = useState([],);
 const[lc_inflow,setlc_inflow] = useState([],);
 const[lc_outflow,setlc_outflow] = useState([],);
 const[lc_balance,setlc_balance] = useState([],);

 //Line Chart
 const[lc_date,setlc_date] = useState();
 const[lc_default,setlc_default] = useState(0);
 const[lc_start_date,setlc_start_date] = useState(0);const updatelc_start_date = (event) => { setlc_start_date(event.target.value); }; 
 const[lc_end_date,setlc_end_date] = useState(0);const updatelc_end_date = (event) => { setlc_end_date(event.target.value); }; 
 const[lc_date_error,setlc_date_error] = useState();

 //Pie Chart
 const[pc_default,setpc_default] = useState(0);
 const[pc_start_date,setpc_start_date] = useState(0);const updatepc_start_date = (event) => { setpc_start_date(event.target.value); }; 
 const[pc_end_date,setpc_end_date] = useState(0);const updatepc_end_date = (event) => { setpc_end_date(event.target.value); }; 
 const[pc_date_error,setpc_date_error] = useState();
 const[pc_inflow,setpc_inflow] = useState();
 const[pc_outflow,setpc_outflow] = useState();
 const[pc_balance,setpc_balance] = useState();



  
  
//******************* GET CREDITORS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
 
  //Fetching Filter Variables
  const $filter_start_date = filter_start_date;const $filter_end_date = filter_end_date;const $filter_trno = filter_trno;const $filter_transaction = filter_transaction; const $filter_cdname = filter_cdname;const $filter_type = filter_type;
  const $filter_amount_in_sign = filter_amount_in_sign;const $filter_amount_in = filter_amount_in;  const $filter_amount_out_sign = filter_amount_out_sign;const $filter_amount_out = filter_amount_out; const $filter_cdname1 = filter_cdname1;



 axios.post("bt-get-creditors-default", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0,
   //Line Chart
   lc_default:lc_default,
   lc_start_date:moment(lc_start_date).format('YYYY-MM-DD'),
   lc_end_date:moment(lc_end_date).format('YYYY-MM-DD'),
   //Bar Chart
   pc_default:pc_default,
   pc_start_date:moment(pc_start_date).format('YYYY-MM-DD'),
   pc_end_date:moment(pc_end_date).format('YYYY-MM-DD'),
   //Filters
   Filter_val : filter_val,
   Filter_start_date : moment($filter_start_date).format('YYYY-MM-DD'),
   Filter_end_date : moment($filter_end_date).format('YYYY-MM-DD'),
   Filter_trno : $filter_trno,
   Filter_transaction : $filter_transaction,
   Filter_type : $filter_type,
   Filter_amount_in_sign : $filter_amount_in_sign,
   Filter_amount_in : $filter_amount_in,
   Filter_amount_out_sign : $filter_amount_out_sign,
   Filter_amount_out : $filter_amount_out,
   Filter_cdname : $filter_cdname,
   Filter_val1 : filter_val1,
   Filter_cdname1 : $filter_cdname1,

   //Code Edits for pagination
  'page' : pageNumber
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setbanks(response.data.banks);
   setData(response.data.data.data);
   setsummary(response.data.summary.data);
   const lc_summary = response.data.lc_summary;
   // Extract data for line chart
   setlc_date(lc_summary.map((entry) => entry.Date));
   setlc_inflow(lc_summary.map((entry) => entry.Inflow));
   setlc_outflow(lc_summary.map((entry) => entry.Outflow)); 
   setlc_balance(lc_summary.map((entry) => entry.Balance)); 
   //extract data for bar chart
   setpc_inflow(response.data.pc_inflow);
   setpc_outflow(response.data.pc_outflow);
   setpc_balance(response.data.pc_balance);
  
  }
    //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    setfilter_start_date_er(response.data.validatorerror.Filter_start_date);
    setfilter_end_date_er(response.data.validatorerror.Filter_end_date);
    setfilter_trno_er(response.data.validatorerror.Filter_trno);
    setfilter_type_er(response.data.validatorerror.Filter_type);
    setfilter_amount_in_sign_er(response.data.validatorerror.Filter_amount_in_sign);
    setfilter_amount_in_er(response.data.validatorerror.Filter_amount_in);
    setfilter_amount_out_sign_er(response.data.validatorerror.Filter_amount_out_sign);
    setfilter_amount_out_er(response.data.validatorerror.Filter_amount_out);
    setfilter_cdname_er(response.data.validatorerror.Filter_cdname);
    setfilter_cdname1_er(response.data.validatorerror.Filter_cdname1);
    setfilter_transaction_er(response.data.validatorerror.Filter_transaction);
  
  }

  //Data Violation
  if(response.data.error==102){ 
  
    setfilter_start_date_er(response.data.filter_start_date_er);
    setfilter_end_date_er(response.data.filter_end_date_er);
    setfilter_trno_er(response.data.filter_trno_er);
    setfilter_type_er(response.data.filter_type_er);
    setfilter_amount_in_sign_er(response.data.filter_amount_in_sign_er);
    setfilter_amount_in_er(response.data.filter_amount_in_er);
    setfilter_amount_out_sign_er(response.data.filter_amount_out_sign_er);
    setfilter_amount_out_er(response.data.filter_amount_out_er);
    setfilter_cdname_er(response.data.filter_cdname_er);
    setfilter_cdname1_er(response.data.filter_cdname1_er);
    setfilter_transaction_er(response.data.filter_transaction_er);
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET CREDITORS DATA FUNCTION END  ********************//

 
 
 /* LINE CHART START */
 const data1 = {
  labels: lc_date,
  datasets: [
    {
      label: "Creditors",
      backgroundColor: ["rgb(255, 99, 132)" ],
      borderColor: "rgb(255, 99, 132)",
      data: lc_inflow,
    },
    {
      label: "Paid",
      backgroundColor: ["rgb(54, 160, 255)"],
      borderColor: "rgb(54, 160, 255)",
      data: lc_outflow,
    },
    {
      label: "Balance",
      backgroundColor: ["#1da15b"],
      borderColor: "#1da15b",
      data: lc_balance,
    },
  ],
};
/* LINE CHART END */ 

  /* PIE CHART VARIABLES START */
const data2 = {
  labels: ["creditors", "paid", "balance"],
  datasets: [
    {
      label: "# transactions summary",
      data: [pc_inflow, pc_outflow, pc_balance],
      backgroundColor: [ "#3fe077", "#0b3190", "#d76721"],
      borderColor: [ "#ffffff", "#ffffff", "#ffffff"],
      borderWidth: 1,
    },
  ],
};
  /* PIE CHART VARIABLES END */


//******************* CREATE CREDITORS FUNCTION START ********************//
 
//date
const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState();
//voucher_no
const[voucher_no,setvoucher_no]=useState();const updatevoucher_no = (event) => { setvoucher_no(event.target.value);}; const[voucher_no_er,setvoucher_no_er]=useState();
//creditor_id
const[creditor_id,setcreditor_id]=useState();const updatecreditor_id = (event) => { setcreditor_id(event.target.value);}; const[creditor_id_er,setcreditor_id_er]=useState();
//creditor_name
const[creditor_name,setcreditor_name]=useState();const updatecreditor_name = (event) => { setcreditor_name(event.target.value);}; const[creditor_name_er,setcreditor_name_er]=useState();
//type
const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
//particular
const[particular,setparticular]=useState();const updateparticular = (event) => { setparticular(event.target.value);}; const[particular_er,setparticular_er]=useState();
//amount
const[amount,setamount]=useState();const updateamount = (event) => { setamount(event.target.value);calculate(); }; const[amount_er,setamount_er]=useState();
//bank_id
const[bank_id,setbank_id]=useState();const updatebank_id = (event) => { setbank_id(event.target.value);}; const[bank_id_er,setbank_id_er]=useState();
//total
const[total,settotal]=useState();const updatetotal = (event) => { settotal(event.target.value);calculate(); }; const[total_er,settotal_er]=useState();
//discount
const[discount,setdiscount]=useState();const updatediscount = (event) => { setdiscount(event.target.value); calculate(); }; const[discount_er,setdiscount_er]=useState();
//interest
const[interest,setinterest]=useState();const updateinterest = (event) => { setinterest(event.target.value); calculate(); }; const[interest_er,setinterest_er]=useState();


//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();


//Create Function
function createcreditors(){

  //Setting Error Variables
setdate_er(" ");setvoucher_no_er(" ");setcreditor_id_er(" ");settype_er(" ");setcreditor_name_er(" ");setparticular_er(" ");setamount_er(" ");settotal_er(" ");setbank_id_er(" ");setdiscount_er(" ");setinterest_er(" ");seterror_msg(" ");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-creditors-pay", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Date : moment(date).format('YYYY-MM-DD'),
   Voucher_no:voucher_no,
   Creditor_id:creditor_id,
   Creditor_name:creditor_name,
   Type:type,
   Bank_id:bank_id,
   Particular : particular,
   Amount : amount,
   Discount:discount,
   Interest:interest,

 })
 .then((response) => {

  ////console.log(response);

  if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }
  

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setdate_er(response.data.validatorerror.Date);
  settype_er(response.data.validatorerror.Type);
  setcreditor_name_er(response.data.validatorerror.Creditor_name);
  setvoucher_no_er(response.data.validatorerror.Voucher_no);
  setcreditor_id_er(response.data.validatorerror.Creditor_id);
  setbank_id_er(response.data.validatorerror.Bank_id);
  setparticular_er(response.data.validatorerror.Particular);
  setamount_er(response.data.validatorerror.Amount);
  setdiscount_er(response.data.validatorerror.Discount);
  setinterest_er(response.data.validatorerror.Interest);
 
}
//Invalid Entries
if(response.data.error==102){ 
  
  setdate_er(response.data.date_er);
  settype_er(response.data.type_er);
  setcreditor_name_er(response.data.creditor_name_er);
  setvoucher_no_er(response.data.voucher_no_er);
  setcreditor_id_er(response.data.creditor_id_er);
  setbank_id_er(response.data.bank_id_er);
  setparticular_er(response.data.particular_er);
  setamount_er(response.data.amount_er);
  setdiscount_er(response.data.discount_er);
  setinterest_er(response.data.interest_er);
  seterror_msg("Cannot Add Transaction!");  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  }


   //Transaction Entered Successfully
   if(response.data.successvar==1){        document.getElementById("success-card").style.display="block";  }
   //Cannot Add Transaction
   else{  seterror_msg("Cannot Add Transaction !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }


 


   //******************* CREATE CREDITORS FUNCTION END ********************//
 
  //******************* GET CREDITORS DATA BY ID FUNCTION START  ********************//


//edate
const[edate,setedate]=useState();const updateedate = (event) => { setedate(event.target.value);}; const[edate_er,setedate_er]=useState();
//evoucher_no
const[evoucher_no,setevoucher_no]=useState();const updateevoucher_no = (event) => { setevoucher_no(event.target.value);}; const[evoucher_no_er,setevoucher_no_er]=useState();
//ecreditor_id
const[ecreditor_id,setecreditor_id]=useState();const updateecreditor_id = (event) => { setecreditor_id(event.target.value);}; const[ecreditor_id_er,setecreditor_id_er]=useState();
//ecreditor_name
const[ecreditor_name,setecreditor_name]=useState();const updateecreditor_name = (event) => { setecreditor_name(event.target.value);}; const[ecreditor_name_er,setecreditor_name_er]=useState();
//etype
const[etype,setetype]=useState();const updateetype = (event) => { setetype(event.target.value);}; const[etype_er,setetype_er]=useState();
//eparticular
const[eparticular,seteparticular]=useState();const updateeparticular = (event) => { seteparticular(event.target.value);}; const[eparticular_er,seteparticular_er]=useState();
//eamount
const[eamount,seteamount]=useState();const updateeamount = (event) => { seteamount(event.target.value);ecalculate(); }; const[eamount_er,seteamount_er]=useState();
//ebank_id
const[ebank_id,setebank_id]=useState();const updateebank_id = (event) => { setebank_id(event.target.value);}; const[ebank_id_er,setebank_id_er]=useState();
//ebank_name
const[ebank_name,setebank_name]=useState();const updateebank_name = (event) => { setebank_name(event.target.value);}; const[ebank_name_er,setebank_name_er]=useState();
//etotal
const[etotal,setetotal]=useState();const updateetotal = (event) => { setetotal(event.target.value);ecalculate(); }; const[etotal_er,setetotal_er]=useState();
//ediscount
const[ediscount,setediscount]=useState();const updateediscount = (event) => { setediscount(event.target.value); ecalculate(); }; const[ediscount_er,setediscount_er]=useState();
//einterest
const[einterest,seteinterest]=useState();const updateeinterest = (event) => { seteinterest(event.target.value); ecalculate(); }; const[einterest_er,seteinterest_er]=useState();


//Edit Function
function getcreditorsdatabyId(){
 

  const osid=sessionStorage.getItem("Osid00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(busyid>0)&&(counterid>0)&&(osid>0)){
 
 
 axios.post("bt-creditors-details-byid", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Os_id:osid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0

 })
 .then((response) => {

  //console.log(response);

  if(response.data.error==108){     seterror_msg(response.data.error_msg); 
    setedate_er(" ");setevoucher_no_er(" ");setecreditor_id_er(" ");setetype_er(" ");setecreditor_name_er(" ");seteparticular_er(" ");seteamount_er(" ");setetotal_er(" ");setebank_id_er(" ");setediscount_er(" ");seteinterest_er(" ");seterror_msg(" ");
    setedate(" ");setevoucher_no(" ");setecreditor_id(" ");setetype(" ");setecreditor_name(" ");seteparticular(" ");seteamount(" ");setetotal(" ");setebank_id(" ");setediscount(" ");seteinterest(" ");

    document.getElementById("edit-error-card").style.display="block";  }

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Osid00");


     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data
   setedate(response.data.date);
   setecreditor_id(response.data.creditor_id);
   setevoucher_no(response.data.voucher_no);
   setecreditor_name(response.data.creditor_name);
   setetype(response.data.type);
   setebank_id(response.data.bank_id);
   setebank_name(response.data.bank_name);
   seteparticular(response.data.particular);
   seteamount(response.data.amount_out);
   setetotal(response.data.total);
   setediscount(response.data.discount);
   seteinterest(response.data.interest);
  
  }
  //Cannot Fetch Data
  else{


      document.getElementById("edit-form").style.display="none";

     //Setting Variables to empty 
    setedate_er(" ");setevoucher_no_er(" ");setecreditor_id_er(" ");setetype_er(" ");setecreditor_name_er(" ");seteparticular_er(" ");seteamount_er(" ");setetotal_er(" ");setebank_id_er(" ");setediscount_er(" ");seteinterest_er(" ");seterror_msg(" ");
    setedate(" ");setevoucher_no(" ");setecreditor_id(" ");setetype(" ");setecreditor_name(" ");seteparticular(" ");seteamount(" ");setetotal(" ");setebank_id(" ");setediscount(" ");seteinterest(" ");
 

      document.getElementById("edit-error-card").style.display="block"; 
      sessionStorage.removeItem("Osid00");
  }
 
 
 });
 
 }
 //User not Logedin
 else{

     //Checking if user loggedin
     if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))){
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("OsId00");

   window.location.replace("businesstools-login");
     }
 
  }
 
 }
  
 //******************* GET CREDITORS DATA BY ID FUNCTION END  ********************//


 
 
 //******************* EDIT CREDITORS FUNCTION START ********************//
function editcreditors(){

  //Setting Error Variables
  setedate_er(" ");setevoucher_no_er(" ");setecreditor_id_er(" ");setetype_er(" ");setecreditor_name_er(" ");seteparticular_er(" ");seteamount_er(" ");setetotal_er(" ");setebank_id_er(" ");setediscount_er(" ");seteinterest_er(" ");seterror_msg(" ");

  const osid = sessionStorage.getItem("Osid00");
  const trno = sessionStorage.getItem("Trno00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((osid!=null)||(osid!=" "))&&((trno!=null)||(trno!=" "))){
 
 
  axios.post("bt-creditors-edit", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Os_id:osid,
   Tr_no:trno,
   Date : moment(edate).format('YYYY-MM-DD'),
   Voucher_no:evoucher_no,
   Creditor_id:ecreditor_id,
   Creditor_name:ecreditor_name,
   Type:etype,
   Bank_id:ebank_id,
   Particular : eparticular,
   Amount : eamount,
   Discount:ediscount,
   Interest:einterest,
 })
 .then((response) => {

  //console.log(response);

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Trno00");
     sessionStorage.removeItem("Osid00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setedate_er(response.data.validatorerror.Date);
  setetype_er(response.data.validatorerror.Type);
  setecreditor_name_er(response.data.validatorerror.Creditor_name);
  setevoucher_no_er(response.data.validatorerror.Voucher_no);
  setecreditor_id_er(response.data.validatorerror.Creditor_id);
  setebank_id_er(response.data.validatorerror.Bank_id);
  seteparticular_er(response.data.validatorerror.Particular);
  seteamount_er(response.data.validatorerror.Amount);
  setediscount_er(response.data.validatorerror.Discount);
  seteinterest_er(response.data.validatorerror.Interest);
 

}
//Invalid Entries
if(response.data.error==102){ 
  
  setedate_er(response.data.date_er);
  setetype_er(response.data.type_er);
  setecreditor_name_er(response.data.creditor_name_er);
  setevoucher_no_er(response.data.voucher_no_er);
  setecreditor_id_er(response.data.creditor_id_er);
  setebank_id_er(response.data.bank_id_er);
  seteparticular_er(response.data.particular_er);
  seteamount_er(response.data.amount_er);
  setediscount_er(response.data.discount_er);
  seteinterest_er(response.data.interest_er);

 
  seterror_msg("Cannot Update Transaction!");  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  }
  if(response.data.error==104){     seterror_msg("Select Bank !");  }
    //if(response.data.error==105){     seterror_msg("Voucher Number Already Exists !");  }

   //Transaction Updated Successfully
   if(response.data.successvar==1){        
     //Setting Variables to empty 
     setedate_er(" ");setevoucher_no_er(" ");setecreditor_id_er(" ");setetype_er(" ");setecreditor_name_er(" ");seteparticular_er(" ");seteamount_er(" ");setetotal_er(" ");setebank_id_er(" ");setediscount_er(" ");seteinterest_er(" ");seterror_msg(" ");
     setedate(" ");setevoucher_no(" ");setecreditor_id(" ");setetype(" ");setecreditor_name(" ");seteparticular(" ");seteamount(" ");setetotal(" ");setebank_id(" ");setediscount(" ");seteinterest(" ");
       document.getElementById("edit-form").style.display="none"; 
    document.getElementById("edit-success-card").style.display="block";  }

   //Cannot Update Transaction
   else{  seterror_msg("Cannot Update Transaction !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("Trno00");
   sessionStorage.removeItem("OsId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT CREDITORS FUNCTION END ********************//





 
 //******************* DELETE CREDITORS FUNCTION START ********************//
 function deletecreditors(){

  const osId=sessionStorage.getItem("DOsId00");
  const dtrno=sessionStorage.getItem("DTrno00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((osId!=null)||(osId!=" "))&&((dtrno!=null)||(dtrno!=" "))){
 
 axios.post("bt-creditors-delete", {
   Busy_id:busyid,
   Counter_id:counterid,
   Log_id:logid,
   Token:token,
   Os_id:osId,
   Tr_no:dtrno
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("OsId00");
     sessionStorage.removeItem("DTrno00");

     window.location.replace("businesstools-login");
  }

  //Counter Deleted Successfully
   if(response.data.successvar==1){        

      document.getElementById("delete-card").style.display="none";
      document.getElementById("delete-success-card").style.display="block";  
      sessionStorage.removeItem("DOsId");
      sessionStorage.removeItem("DTrno00");

  }
  
      //Cannot Delete Counter
    else{  
    document.getElementById("delete-card").style.display="none";
    document.getElementById("delete-error-card").style.display="block";   }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("DOsId00");
   sessionStorage.removeItem("DTrno00");


   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* DELETE CREDITORS FUNCTION END ********************//






function calculate(){
  
  //Fetching Values
  let amount = (document.getElementById("amount").value);if(amount==""){ amount=0;}else{ amount=parseFloat(amount);}
  let discount = (document.getElementById("discount").value);if(discount==""){ discount=0;}else{ discount=parseFloat(discount);}
  let interest = (document.getElementById("interest").value);if(interest==""){ interest=0;}else{ interest=parseFloat(interest);}
 
  let total=(amount);
  if(amount>0){ total = amount-discount+interest;} settotal(total.toFixed(2));
}


function ecalculate(){
  
  //Fetching Values
  let eamount = (document.getElementById("eamount").value);if(eamount==""){ eamount=0;}else{ eamount=parseFloat(eamount);}
  let ediscount = (document.getElementById("ediscount").value);if(ediscount==""){ ediscount=0;}else{ ediscount=parseFloat(ediscount);}
  let einterest = (document.getElementById("einterest").value);if(einterest==""){ einterest=0;}else{ einterest=parseFloat(einterest);}
 
  let etotal=(eamount);
  if(eamount>0){ etotal = eamount-ediscount+einterest;} setetotal(etotal.toFixed(2));
}



return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

      <Sidebar />
    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">

             {/* SUCCESS CARD START */}
             <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-creditor_name  text-success">New Transaction Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="businesstools-user-creditor"><button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* SUCCESS CARD END */}
               {/* EDIT SUCCESS CARD START */}
             <div class="d-flex justify-content-center">
             <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-creditor_name  text-success">Transaction Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT SUCCESS CARD END */}
               {/* EDIT ERROR CARD START */}
            <div class="d-flex justify-content-center">
            <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-creditor_name  text-danger">Cannot Fetch Data !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT ERROR CARD END */}




                {/* DELETE CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-creditor_name  text-danger">Are you sure you want to delete this transaction?</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> This action cannot be reversed. You will lose all your data related to this transaction if you wish to continue this action.</p>
                      <button class="mt-3 btn btn-outline-success" onClick={deletecreditors}>Yes, I Know! Delete Transaction.</button>
                      <button class="mt-3 btn btn-outline-danger" style={{marginLeft:"1rem"}} onClick={()=>{  
                      //Cancel Delete and remove Os_id
                      sessionStorage.removeItem("DOsId00");     sessionStorage.removeItem("DTrno00");  document.getElementById('delete-card').style.display = 'none'; }}>No, Don't Delete Transaction.</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE CARD END */}
              {/* DELETE SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-creditor_name  text-success">Transaction Deleted Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE SUCCESS CARD END */}
               {/*  DELETE ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-creditor_name  text-danger">Cannot Delete Transaction !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE ERROR CARD END */}

          <div class="content-wrapper pb-0">
            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Creditors</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                  {/*}  <p class="m-0">ADE-00234</p> */}
                  </a>
                </div>
              </div>
            </div>


{/*} chart row starts here */}
<div class="row">
                       {/* LINECHART START */}
                       <div className="col-sm-8 stretch-card grid-margin">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                    <div className="card-title"> Creditors Summary
                      </div>                  
                      <div className="d-flex text-muted font-20">
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("lc-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("lc-date-search-box").style.display="none"; }}></i>                     
                      </div>
                    </div>
                    {/* LINE CHART DATE FILTER START */}
                    <div id="lc-date-search-box" style={{display:"none"}}>
                    <hr />
                    <p style={{marginTop:"2rem"}}>Make sure start date and end date are not more than 10 days apart for best results</p>
                    <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="lc_start_date" value={lc_start_date} onChange={updatelc_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="lc_end_date" value={lc_end_date} onChange={updatelc_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setlc_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* LINE CHART DATE FILTER END */}
                    <div className="line-chart-wrapper">
                  {/* CASH INFLOW OUTFLOW LINE CHART START */}
                  <Line data={data1} /> 
                  {/* CASH INFLOW OUTFLOW LINE CHART END */}                   
                    </div>
                  </div>
                </div>
              </div>
               {/* LINE CHART END */}
               {/* PIE CHART START */}
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Transactions Summary
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("pc-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("pc-date-search-box").style.display="none"; }}></i>
                      
                      </div>
                    </div>
                        {/*BAR CHART DATE FILTER START */}
                        <div id="pc-date-search-box" style={{display:"none"}}>
                    <hr />
                    <div className="row">
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="pc_start_date" value={pc_start_date} onChange={updatepc_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="pc_end_date" value={pc_end_date} onChange={updatepc_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setpc_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* BAR CHART DATE FILTER END */}
                       
                    
                   
             
                    <div class="bar-chart-wrapper">
                    <Pie data={data2} style={{width:"600px",height:"400px"}} maintainAspectRatio={false} />
                    </div>
                  </div>
                </div>
              </div>
               {/* PIE CHART END */}
        
            </div>


             {/* OUTSTANDING INCOME CREATE PAYMENT FORM START */}
            <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form">
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-creditor_name">Creditor Payment</h4>
                    <div class="form-sample">
                      <p class="card-description">record your creditor payment here</p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">Payment From</label>
                            <div class="col-sm-6">
                            <label class="col-sm-6 col-form-label">{creditor_name}</label>
                            </div>
                          </div>
                        </div>
                    
                        </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date" value={date} onChange={updatedate}  />
                              <code>{date_er}</code>
                            </div>
                          </div>
                        </div>
                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="type" value={type} onChange={updatetype} >
                              <option>Select Type</option>
                              <option value="cash">Cash</option>
                              <option value="bank">Bank</option>
                              </select>
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="bank_id" value={bank_id} onChange={updatebank_id} >
                              <option>Select Bank</option>
                              {
                              banks.map((curElm2,ind)=>{

                              return(
                              <>
                              <option value={curElm2.Bank_id}>{curElm2.Bank_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{bank_id_er}</code>
                            </div>
                          </div>
                        </div>

                      </div>
                    
                   
                 
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1000.00" id="amount" value={amount} onChange={updateamount} />
                            <code>{amount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{fontWeight:"bold"}} placeholder="1200.00" id="total" value={total} onChange={updatetotal} />
                            <code>{total_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="100.00" id="discount" value={discount} onChange={updatediscount} />
                            <code>{discount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Interest</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="300.00" id="interest" value={interest} onChange={updateinterest} />
                            <code>{interest_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="form-group">
                        <label for="exampleTextarea1">Particular</label>
                        <textarea class="form-control" rows="2" id="particular" value={particular} onChange={updateparticular}  ></textarea>
                        <code>{particular_er}</code>
                      </div>

                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={createcreditors}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* crediTORS CREATE PAYMENT FORM END */}


            {/* crediTORS SUMMARY START */}
                  
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-creditor_name">Creditors</h4>
                    <p class="card-description">creditor details
                    </p>

                            {/* FILTER SECTION 1 START */}
                            <hr/>
                            <div id="filter-top-card1" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card1").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                       
                      
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Creditor Name</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="VN123456" id="filter_cdname1" value={filter_cdname1} onChange={updatefilter_cdname1}  />
                              <code>{filter_cdname1_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                        <button class="btn btn-primary" onClick={()=>{ setfilter_val1(1); getData(); }}>Apply Filters</button>
                        <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val1(0); getData(); }}>Remove Filters</button>
              
                        </div>
                      
                        </div>

                            <hr/>                      
                            </div>
                            
                      {/* FILTER SECTION 1 END */}

                    <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card1").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                          </div>
                          </div>
                          </div>

                    <div class="table-responsive" id="reports" ref={componentRef}>
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}}>Creditor Name</th>
                            <th  style={{fontWeight:"bold"}}>Total</th>
                            <th  style={{fontWeight:"bold"}}>Received</th>
                            <th  style={{fontWeight:"bold"}}>Due</th>
                            <th  style={{fontWeight:"bold"}}>Status</th>
                            <th  style={{fontWeight:"bold"}}></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       summary.map((curElm,ind)=>{

                         return(
                         <>
                       
                        <tr key={curElm.Creditor_id}>
                            <td>{curElm.Creditor_name}</td>
                            <td>{(curElm.Total).toFixed(2)}</td>
                            <td>{(curElm.Paid).toFixed(2)}</td>
                            <td>{((curElm.Due)).toFixed(2)}</td>
                            <td><label hidden={((curElm.Total)>(curElm.Paid))} class="badge badge-success">paid</label>
                            <label hidden={((curElm.Paid)>=(curElm.Total))} class="badge badge-danger">due</label></td>
                            <td><button class="btn btn-primary" onClick={()=>{ 
                              //Setting Form Varaibles and Redirecting to Pay
                              setcreditor_name(curElm.Creditor_name);const dueamount = ((curElm.Due)).toFixed(2); setamount(dueamount);
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>Pay</button></td>
                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                         
                         
                        </tbody>
                      </table>
                    </div>
                          {/* PAGINATION SECTION START */}
                          <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel}><i class="mdi mdi-file-excel text-dark"></i></button>
                  

                  </div>
                </div>
              </div>
              {/* CREDITORSSUMMARY END */}


            {/* CREDITORS EDIT PAYMENT FORM START */}
            <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("edit-form").style.display="none";
                        //Setting Variables to empty
                        //Setting Variables to empty 
                        setedate_er(" ");setevoucher_no_er(" ");setecreditor_id_er(" ");setetype_er(" ");setecreditor_name_er(" ");seteparticular_er(" ");seteamount_er(" ");setetotal_er(" ");setebank_id_er(" ");setediscount_er(" ");seteinterest_er(" ");seterror_msg(" ");
                        setedate(" ");setevoucher_no(" ");setecreditor_id(" ");setetype(" ");setecreditor_name(" ");seteparticular(" ");seteamount(" ");setetotal(" ");setebank_id(" ");setediscount(" ");seteinterest(" ");
                        sessionStorage.removeItem("Osid00");
                        sessionStorage.removeItem("Trno00"); }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-creditor_name">Edit Creditors Payment</h4>
                    <div class="form-sample">
                      <p class="card-description">edit your creditors payment here</p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">Payment From</label>
                            <div class="col-sm-6">
                            <label class="col-sm-6 col-form-label">{ecreditor_name}</label>
                            </div>
                          </div>
                        </div>
                      
                        </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="edate" value={edate} onChange={updateedate}  />
                              <code>{edate_er}</code>
                            </div>
                          </div>
                        </div>
                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="etype" value={etype} onChange={updateetype} >
                              <option>Select Type</option>
                              <option value={etype}>{etype}</option>
                              <option value="cash">Cash</option>
                              <option value="bank">Bank</option>
                              </select>
                              <code>{etype_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="ebank_id" value={ebank_id} onChange={updateebank_id} >
                              <option value={ebank_id}>{ebank_name}</option>
                              {
                              banks.map((curElm2,ind)=>{

                              return(
                              <>
                              <option value={curElm2.Bank_id}>{curElm2.Bank_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{ebank_id_er}</code>
                            </div>
                          </div>
                        </div>

                      </div>
                    
                   
                 
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1000.00" id="eamount" value={eamount} onChange={updateeamount} />
                            <code>{eamount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{fontWeight:"bold"}} placeholder="1200.00" id="etotal" value={etotal} onChange={updateetotal} />
                            <code>{etotal_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="100.00" id="ediscount" value={ediscount} onChange={updateediscount} />
                            <code>{ediscount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Interest</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="300.00" id="einterest" value={einterest} onChange={updateeinterest} />
                            <code>{einterest_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="form-group">
                        <label for="exampleTextarea1">Particular</label>
                        <textarea class="form-control" rows="2" id="eparticular" value={eparticular} onChange={updateeparticular}  ></textarea>
                        <code>{eparticular_er}</code>
                      </div>

                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={editcreditors}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* CREDITORS EDIT PAYMENT FORM END */}

            
              {/* CREDITORS TRANSACTIONS HISTORY START */}
              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-creditor_name">Creditors Transaction History</h4>
                    <p class="card-description">creditors transaction history
                    </p>

                           {/* FILTER SECTION START */}
                            <hr/>
                            <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Start Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control" id="filter_start_date" value={filter_start_date} onChange={updatefilter_start_date}  />
                              <code>{filter_start_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">End Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control"  id="filter_end_date" value={filter_end_date} onChange={updatefilter_end_date}  />
                              <code>{filter_end_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Tr No.</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="12345"   id="filter_trno" value={filter_trno} onChange={updatefilter_trno}  />
                              <code>{filter_trno_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Creditor Name</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="Rajeev Kumar" id="filter_cdname" value={filter_cdname} onChange={updatefilter_cdname}  />
                              <code>{filter_cdname_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Transaction</label>
                            <div class="col-sm-8">
                            <select class="form-control" style={{padding:"1rem"}} id="filter_transaction" value={filter_transaction} onChange={updatefilter_transaction} >
                             <option value=" ">Select Transaction</option>
                             <option value="purchases">Purchase</option>
                             <option value="purchasereturns">Purchase Returns</option>
                             <option value="creditor payment">Creditor Payment</option>
                             </select>                              
                             <code>{filter_transaction_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Type</label>
                            <div class="col-sm-8">
                             <select class="form-control" style={{padding:"1rem"}} id="filter_type" value={filter_type} onChange={updatefilter_type} >
                             <option value=" ">Select Type</option>
                             <option value="cash">cash</option>
                             <option value="bank">bank</option>
                             </select>                              
                             <code>{filter_type_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Amount In</label>
                            <div class="col-sm-4">
                              <select class="form-control" style={{padding:"1rem"}} id="filter_amount_in_sign" value={filter_amount_in_sign} onChange={updatefilter_amount_in_sign}  >
                              <option className="dropdown-item" value=" ">All</option>
                              <option className="dropdown-item" value="equals">equals</option>
                              <option className="dropdown-item" value="more than" >more than</option>
                              <option className="dropdown-item" value="less than">less than</option>
                              <option className="dropdown-item" value="more than or equals" >more than or equals</option>
                              <option className="dropdown-item" value="less than or equals" >less than or equals</option>
                              </select>
                              <code>{filter_amount_in_sign_er}</code>
                            </div>
                            <div class="col-sm-4">
                              <input type="text" class="form-control" placeholder="5000.00" id="filter_amount_in" value={filter_amount_in} onChange={updatefilter_amount_in}   />
                              <code>{filter_amount_in_er}</code>
                            </div>
                          </div>
                        </div>
                    
                      </div>
                      <div class="row">
                      <div class="col-md-8">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Amount Out</label>
                            <div class="col-sm-4">
                              <select class="form-control" style={{padding:"1rem"}} id="filter_amount_out_sign" value={filter_amount_out_sign} onChange={updatefilter_amount_out_sign}  >
                              <option className="dropdown-item" value=" ">All</option>
                              <option className="dropdown-item" value="equals">equals</option>
                              <option className="dropdown-item" value="more than" >more than</option>
                              <option className="dropdown-item" value="less than">less than</option>
                              <option className="dropdown-item" value="more than or equals" >more than or equals</option>
                              <option className="dropdown-item" value="less than or equals" >less than or equals</option>
                              </select>
                              <code>{filter_amount_out_sign_er}</code>
                            </div>
                            <div class="col-sm-4">
                              <input type="text" class="form-control" placeholder="5000.00" id="filter_amount_out" value={filter_amount_out} onChange={updatefilter_amount_out}   />
                              <code>{filter_amount_out_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                         
                          <button class="btn btn-primary" onClick={()=>{ setfilter_val(1); getData(); }}>Apply Filters</button>
                         <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val(0); getData(); }}>Remove Filters</button>
                       
                     
                        </div>
                      </div>
                    
                      <div style={{marginBottom:"1rem"}}>
                  
                       
                      </div>
                            <hr/>                      
                            </div>
                            
                            {/* FILTER SECTION END */}

                    <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                            <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tr No.</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Transaction</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Type</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Particular</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Creditor Name</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Interest</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Discount</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Amount In</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Amount Out</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 12 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated By</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c12_yes" onClick={()=>{setcd_col12_val(1); document.getElementById("cd_c12_yes").style.display="none"; document.getElementById("cd_c12_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c12_no" style={{ display:"none"}} onClick={()=>{ setcd_col12_val(0); document.getElementById("cd_c12_yes").style.display="block"; document.getElementById("cd_c12_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 13 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated At</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c13_yes" onClick={()=>{setcd_col13_val(1); document.getElementById("cd_c13_yes").style.display="none"; document.getElementById("cd_c13_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c13_no" style={{ display:"none"}} onClick={()=>{ setcd_col13_val(0); document.getElementById("cd_c13_yes").style.display="block"; document.getElementById("cd_c13_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 14 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c14_yes" onClick={()=>{setcd_col14_val(1); document.getElementById("cd_c14_yes").style.display="none"; document.getElementById("cd_c14_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c14_no" style={{ display:"none"}} onClick={()=>{ setcd_col14_val(0); document.getElementById("cd_c14_yes").style.display="block"; document.getElementById("cd_c14_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 15 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-danger">Delete</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c15_yes" onClick={()=>{setcd_col15_val(1); document.getElementById("cd_c15_yes").style.display="none"; document.getElementById("cd_c15_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c15_no" style={{ display:"none"}} onClick={()=>{ setcd_col15_val(0); document.getElementById("cd_c15_yes").style.display="block"; document.getElementById("cd_c15_no").style.display="none"; }}></i>
                              </div></div></li> 
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>


                    <div class="table-responsive" id="reports" ref={componentRef1}>
                      <table class="table table-striped">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Sn</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Tr No.</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1} >Date</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1} >Transaction</th>
                            <th style={{fontWeight:"bold"}} hidden={cd_col5_val==1} >Type</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Particular</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1} >Creditor Name</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col8_val==1} >Interest</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1} >Discount</th>
                            <th style={{fontWeight:"bold"}} hidden={cd_col10_val==1} >Amount In</th>
                            <th style={{fontWeight:"bold"}} hidden={cd_col11_val==1} >Amount Out</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col12_val==1} >Updated By</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col13_val==1} >Updated At</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col14_val==1} ></th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col15_val==1} ></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm4,ind)=>{
          
                         return(
                          <>
                        <tr key={curElm4.Id}>
                            <td hidden={(logtype == "user")||(cd_col1_val==1)}>{curElm4.Admin_sn}</td>
                            <td hidden={(logtype == "admin")||(cd_col1_val==1)}>{curElm4.User_sn}</td>
                            <td hidden={cd_col2_val==1}>{curElm4.Tr_no}</td>
                            <td hidden={cd_col3_val==1} >{curElm4.Date}</td>
                            <td hidden={cd_col4_val==1} ><label hidden={(((curElm4.Type)=="purchases")||((curElm4.Type)=="purchasereturns"))}class="badge badge-success">Creditor Payment</label>
                            <label hidden={((curElm4.Type)=="purchasereturns")||(curElm4.Type=="cash")||(curElm4.Type=="bank")}class="badge badge-info">Purchases</label>
                            <label hidden={(curElm4.Type=="cash")||(curElm4.Type=="bank")||(curElm4.Type=="purchases")}class="badge badge-warning">Purchase Returns</label></td>
                            <td hidden={cd_col5_val==1} ><label class="badge badge-success" hidden={(curElm4.Type == "bank")||(curElm4.Type == "purchases")||(curElm4.Type == "purchasereturns")}>{curElm4.Type}</label>
                            <label class="badge badge-primary" hidden={(curElm4.Type == "cash")||((curElm4.Type == "purchases")||(curElm4.Type == "purchasereturns"))}>{curElm4.Type} ({curElm4.Bank_name})</label></td>
                            <td hidden={cd_col6_val==1} >{curElm4.Particular}</td>
                            <td hidden={cd_col7_val==1} >{curElm4.Creditor_name}</td>
                            <td hidden={cd_col8_val==1} >{curElm4.Interest}</td>
                            <td hidden={cd_col9_val==1} >{curElm4.Discount}</td>
                            <td hidden={cd_col10_val==1} >{curElm4.Amount_in}</td>
                            <td hidden={cd_col11_val==1} >{curElm4.Amount_out}</td>
                            <td hidden={cd_col12_val==1} >{curElm4.Updated_by}</td>
                            <td hidden={cd_col13_val==1} >{curElm4.Date_updated} ({curElm4.Time_updated})</td>
                            <td hidden={cd_col14_val==1} ><i hidden={(curElm4.Type=="purchases")||(curElm4.Type=="purchasereturns")}  class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Os Id and Redirect to Edit
                      sessionStorage.setItem("Osid00",curElm4.Id);sessionStorage.setItem("Trno00",curElm4.Tr_no);getcreditorsdatabyId();document.getElementById('edit-form').style.display = 'block'; }}></i></td>
                            <td hidden={cd_col15_val==1} ><i hidden={(curElm4.Type=="purchases")||(curElm4.Type=="purchasereturns")} class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Os Id for Delete
                      sessionStorage.setItem("DOsId00",curElm4.Id);      sessionStorage.setItem("DTrno00",curElm4.Tr_no); document.getElementById('delete-card').style.display = 'block'; }}></i></td>

                         </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                         
                       
                        </tbody>
                      </table>
                    </div>
                          {/* PAGINATION SECTION START */}
                          <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint1}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel1}><i class="mdi mdi-file-excel text-dark"></i></button>

                  </div>
                </div>
              </div>
              {/* CREDITORS TRANSACTIONS HISTORY END */}
            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
        {/*}  <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

//}
}
export default Usercreditors;