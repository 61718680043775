import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';

import '../Resources/dashboard.css';


function Add_bank(){

  
  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");


 
//saving default values with usestate
const state = useState();

//bank_name
const[bank_name,setbank_name]=useState();const updatebank_name = (event) => { setbank_name(event.target.value);}; const[bank_name_er,setbank_name_er]=useState();
//account_type
const[account_type,setaccount_type]=useState();const updateaccount_type = (event) => { setaccount_type(event.target.value);}; const[account_type_er,setaccount_type_er]=useState();
//account_no
const[account_no,setaccount_no]=useState();const updateaccount_no = (event) => { setaccount_no(event.target.value);}; const[account_no_er,setaccount_no_er]=useState();
//account_ifsc
const[account_ifsc,setaccount_ifsc]=useState();const updateaccount_ifsc = (event) => { setaccount_ifsc(event.target.value);}; const[account_ifsc_er,setaccount_ifsc_er]=useState();
//account_remarks
const[account_remarks,setaccount_remarks]=useState();const updateaccount_remarks = (event) => { setaccount_remarks(event.target.value);}; const[account_remarks_er,setaccount_remarks_er]=useState();
//branch
const[branch,setbranch]=useState();const updatebranch = (event) => { setbranch(event.target.value);}; const[branch_er,setbranch_er]=useState();



//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();




  
//******************* ADD BANK FUNCTION START ********************//
function addbank(){

  //Setting Error Variables
setbank_name_er(" ");setaccount_type_er(" ");setaccount_no_er(" ");setaccount_ifsc_er(" ");setaccount_remarks_er();setbranch_er();seterror_msg(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-add-bank", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Bank_name : bank_name,
   Bank_type:account_type,
   Bank_ifsc : account_ifsc,
   Account_number:account_no,
   Bank_remarks:account_remarks,
   Branch:branch


  })
  .then((response) => {

  //Bank Limit Reached
  if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }


  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setbank_name_er(response.data.validatorerror.Bank_name);
  setaccount_type_er(response.data.validatorerror.Bank_type);
  setaccount_ifsc_er(response.data.validatorerror.Bank_ifsc);
  setaccount_no_er(response.data.validatorerror.Account_number);
  setaccount_remarks_er(response.data.validatorerror.Bank_remarks);
  setbranch_er(response.data.validatorerror.Branch);

}
//Invalid Entries
if(response.data.error==102){ 
  
  setbank_name_er(response.data.bank_name_er);
  setaccount_type_er(response.data.bank_type_er);
  setaccount_ifsc_er(response.data.bank_ifsc_er);
  setaccount_no_er(response.data.account_number_er);
  setaccount_remarks_er(response.data.bank_remarks_er);
  setbranch_er(response.data.branch_er);
 
  seterror_msg("Cannot Add Bank!");  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg);  }

   //Transaction Entered Successfully
   if(response.data.successvar==1){        document.getElementById("success-card1").style.display="block";  }
   //Cannot Add Transaction
   else{  seterror_msg("Cannot Add Bank !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* ADD BANK FUNCTION END ********************//





return(
    <>
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form1").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Add Bank</h4>
                    <div class="form-sample">
                      <p class="card-description">enter your banks here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="ABC Bank" id="bank_name" value={bank_name} onChange={updatebank_name} />
                              <code>{bank_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Account Type</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} placeholder="Select Account Type" id="account_type" value={account_type} onChange={updateaccount_type}>
                              <option value="">Select Account Type</option>
                              <option value="Current">Current</option>
                              <option value="Savings">Savings</option>
                              </select>
                              <code>{account_type_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Account Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5844414444444444" id="account_no" value={account_no} onChange={updateaccount_no} />
                             <code>{account_no_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IFSC Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="HSBSY56555" id="account_ifsc" value={account_ifsc} onChange={updateaccount_ifsc} />
                             <code>{account_ifsc_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                    
                      <div class="form-group">
                        <label for="exampleTextarea1">Remarks</label>
                        <textarea  class="form-control"  rows="2" id="account_remarks" value={account_remarks} onChange={updateaccount_remarks}></textarea>
                      <code>{account_remarks_er}</code>
                      </div>
                 
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Branch</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Branch" id="branch" value={branch} onChange={updatebranch} />
                            <code>{branch_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={addbank} >Add Bank</button>
                        </div>                     
                      </div>
                      
                      
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
    </>

);

}

export default Add_bank;