import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';
import '../Resources/dashboard.css';


function Create_cash(){

  
 //Checking Sessions
 //Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");



//saving default values with usestate
const state = useState();

//date
const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState();
//type
const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
//particular
const[particular,setparticular]=useState();const updateparticular = (event) => { setparticular(event.target.value);}; const[particular_er,setparticular_er]=useState();
//amount
const[amount,setamount]=useState();const updateamount = (event) => { setamount(event.target.value);}; const[amount_er,setamount_er]=useState();


//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();






  
//******************* CREATE CASH FUNCTION START ********************//
function createcash(){

    //Setting Error Variables
  setdate_er(" ");settype_er(" ");setparticular_er(" ");setamount_er(" ");seterror_msg(" ");

 

    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
   
   
    axios.post("bt-create-cash", {
     Log_id:logid,
     Token:token,
     Busy_id:busyid,
     Counter_id:counterid,
     Date : moment(date).format('YYYY-MM-DD'),
     Type:type,
     Particular : particular,
     Amount : amount,
   })
   .then((response) => {

    //Transaction Limit Reached
    if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }

    //Invalid Session
    if((response.data.error)==100){ 
        //Remove Session Variables and redirect to login
       sessionStorage.removeItem("logStatus00");
       sessionStorage.removeItem("logId00");
       sessionStorage.removeItem("logToken00");
       sessionStorage.removeItem("BusyId00");
       sessionStorage.removeItem("BusyName00");
       sessionStorage.removeItem("CounterId00");

       window.location.replace("businesstools-login");
    }

     //Validation Errors
   if(response.data.validatorerror){
    setdate_er(response.data.validatorerror.Date);
    settype_er(response.data.validatorerror.Type);
    setparticular_er(response.data.validatorerror.Particular);
    setamount_er(response.data.validatorerror.Amount);
 
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
    
    setdate_er(response.data.date_er);
    settype_er(response.data.type_er);
    setparticular_er(response.data.particular_er);
    setamount_er(response.data.amount_er);
   
    seterror_msg("Cannot Add Transaction!");  }


    //Invalid Entries
    if(response.data.error==103){     seterror_msg(response.data.error_msg);  }

     //Transaction Entered Successfully
     if(response.data.successvar==1){        document.getElementById("success-card").style.display="block";  }
     //Cannot Add Transaction
     else{  seterror_msg("Cannot Add Transaction !"); }
    

});
}
   //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
   
    }
   
   }
    
   //******************* CREATE CASH FUNCTION END ********************//


return(
    <>
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Cash Transaction</h4>
                    <div class="form-sample">
                      <p class="card-description">enter your cash transactions here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control"  id="date" value={date} onChange={updatedate} />
                              <code>{date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Transaction<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" placeholder="Select Transaction" style={{padding:"1rem"}} id="type" value={type} onChange={updatetype}>
                              <option value="">Select Transaction</option>
                              <option value="entry">Entry</option>
                              <option value="drawings">Drawings</option>
                              </select>
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Particular<code>*</code></label>
                        <textarea  class="form-control"  rows="2" id="particular" value={particular} onChange={updateparticular}></textarea>
                        <code>{particular_er}</code>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000.00" id="amount" value={amount} onChange={updateamount} />
                              <code>{amount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={createcash}>Submit</button>
                        </div>
                       
                      </div>
                        
                       
    
                     
                   
                    </div>
                  </div>
                </div>
    </>

);

}

export default Create_cash;