import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';
import Navbar from '../navbar';
import axios from '../../axios.js';

function Businesstools_userregister(){

    

  //console.log
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");

 
 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 {/* if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))){
  
    sessionStorage.removeItem("logStatus00");
    sessionStorage.removeItem("logId00");
    sessionStorage.removeItem("logToken00");
    sessionStorage.removeItem("BusyId00");
    sessionStorage.removeItem("BusyName00");
    
  
     window.location.replace("/businesstools-login");
    }
   
    //Sessions Set
    else{
    */}


//saving default values with usestate
const state = useState();

//name
const[name,setname]=useState();const updatename = (event) => { setname(event.target.value);}; const[name_er,setname_er]=useState();
//username
const[username,setusername]=useState();const updateusername = (event) => { setusername(event.target.value);}; const[username_er,setusername_er]=useState();
//contact
const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
//email
const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};  const[email_er,setemail_er]=useState();
//password
const[password,setpassword]=useState();const updatepassword = (event) => { setpassword(event.target.value);};  const[password_er,setpassword_er]=useState();
//cpassword
const[cpassword,setcpassword]=useState();const updatecpassword = (event) => { setcpassword(event.target.value);};  const[cpassword_er,setcpassword_er]=useState();
//details
const[details,setdetails]=useState();const updatedetails = (event) => { setdetails(event.target.value);};  const[details_er,setdetails_er]=useState();


//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();


/***************** REGISTER FUNCTION START  ******************/
function register(){

        //Checking if user loggedin
        if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){


//Setting Error Variables
setname_er(" "); setusername_er(" ");setemail_er(" ");setcontact_er(" ");setpassword_er(" ");setcpassword_er(" ");setdetails_er(" ");
seterror_msg(" ");


//Fetching Form Data
  let noerror = 0;
  const $name = document.getElementById("name").value; if($name==""){ setname_er("Name is required !");noerror = noerror+1; }//Fetching Name Value
  const $username = document.getElementById("username").value; if($username==""){ setusername_er("Username is required !");noerror = noerror+1; }//Fetching Username Value
  const $email = document.getElementById("email").value; if($email==""){ setemail_er("Email is required !");noerror = noerror+1; }//Fetching Email Value
  const $contact = document.getElementById("contact").value; if($contact==""){ setcontact_er("Contact is required !");noerror = noerror+1; }//Fetching contact Value
  const $password = document.getElementById("password").value; if($password==""){ setpassword_er("Password is required !");noerror = noerror+1; }//Fetching Password Value
  const $cpassword = document.getElementById("cpassword").value; if($cpassword==""){ setcpassword_er("Confirm Password is required !");noerror = noerror+1; }//Fetching Confirm Password Value
  const $details = document.getElementById("details").value; if($details==""){ setdetails_er("details is required !");noerror = noerror+1; }//Fetching details Value

  
//No primary errors
if(noerror==0){
  //Sending Data through axios
  axios.post("bt-create-team", { 
    Log_id:logid,
    Token:token,
    Name:$name,
   Username:$username,
   Email:$email,
   Contact:$contact,
   Password:$password,
   Confirm_password:$cpassword,
   Details:$details
 
})

 .then((response) => {
   
    //Customers Limit Reached
    if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }

      //User Login
      if(response.data.error==201){     seterror_msg(response.data.error_msg); return; }

    //console.log(response);

   //Validation Errors
 if(response.data.validatorerror){
   setname_er(response.data.validatorerror.Name);
   setusername_er(response.data.validatorerror.Username);
   setcontact_er(response.data.validatorerror.Contact);
   setemail_er(response.data.validatorerror.Email);
   setpassword_er(response.data.validatorerror.Password);
   setcpassword_er(response.data.validatorerror.Confirm_password);
   setdetails_er(response.data.validatorerror.Details);
 
 }
 //Invalid Entries
 if(response.data.error==102){ 
    
    setname_er(response.data.name_er); 
    setusername_er(response.data.username_er); 
    setemail_er(response.data.email_er); 
    setcontact_er(response.data.contact_er); 
    setpassword_er(response.data.password_er); 
    setdetails_er(response.data.details_er); 


}

//Password and Confirm Password don't match
 if(response.data.error==103){ seterror_msg(response.data.password_er); }

 
//Username Already Exists
if(response.data.error==104){ seterror_msg(response.data.error_msg); }


//Name Already Exists
if(response.data.error==105){ seterror_msg(response.data.error_msg); }

 //Success
 if(response.data.successvar==1){
  
 
 //window.location.replace("/businesstools-startworking");
 document.getElementById("success-card").style.display="block";
 
 }
 //failure
 if(response.data.successvar==2){
 seterror_msg("Account Not Created!");
 }
}    );

}
        }
       //User not Logedin
 else{
   
    //Remove Session Variables and redirect to login
      sessionStorage.removeItem("logStatus00");
      sessionStorage.removeItem("logId00");
      sessionStorage.removeItem("logToken00");

 
      window.location.replace("businesstools-login");
    
     }

    
    

}
/***************** REGSITER FUNCTION END  ******************/


return(
    <>

<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
    </head>
    <body>
    {/* container-scroller */}
    <div class="container-scroller">

   {/* partial */}
   <div class="container-fluid page-body-wrapper">

          <Navbar/>

          <div class="main-panel">
          <div class="d-flex justify-content-center">

              {/* SUCCESS CARD START */}
              <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Account Created Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/businesstools-login"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              {/* SUCCESS CARD END */}

          <div class="col-12 grid-margin">
          <div class="content-wrapper pb-0">
                <div class="card" style={{textAlign:"left"}}>
                  <div class="card-body">
                    <h4 class="card-title">Team Member Registration</h4>
                    <div class="form-sample">
                      <p class="card-description">register your teeam user here.</p>
                      <p><code>{error_msg}</code></p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Name" id="name" value={name} onChange={updatename} />
                              <code>{name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Username</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Username" id="username" value={username} onChange={updateusername} />
                              <code>{username_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Password</label>
                            <div class="col-sm-9">
                              <input type="password" class="form-control" placeholder="Password" id="password" value={password} onChange={updatepassword} />
                              <code>{password_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Confirm Password</label>
                            <div class="col-sm-9">
                              <input type="password" class="form-control" placeholder="Confirm Password" id="cpassword" value={cpassword} onChange={updatecpassword} />
                              <code>{cpassword_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="Enter Email" id="email" value={email} onChange={updateemail} />
                            <code>{email_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Contact" id="contact" value={contact} onChange={updatecontact} />
                              <code>{contact_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Details</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter details" id="details" value={details} onChange={updatedetails} />
                              <code>{details_er}</code>
                            </div>
                          </div>
                        </div>
                     
                      </div>
                      <div class="row" style={{textAlign:"left"}}>
                      <div class="form-group row">
                      <div class="form-check mx-sm-2">
                        <label class="form-check-label">
                          <input type="checkbox" class="form-check-input"/></label><p style={{marginLeft:"2rem"}}>I hereby confirm that I am above 18 years of age and I agree to the <a href="">terms and conditions</a> and <a href="">privacy policy</a> of theStartupConnect Business tools. </p>
                      </div>
                      </div>
                      </div>
                      <button class="btn-lg btn-success" onClick={register}>Register</button>
                   
                    
                    </div>
                  
                  </div>
                </div>
                </div>
              </div>

            
             </div>
             </div>
             </div>
             </div>


    {/* plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/* endinject */}
    {/* Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/* End custom js for this page */}
  </body>
  </html>    
    </>
);

//}
}

export default Businesstools_userregister;