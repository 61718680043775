import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';


import '../Resources/dashboard.css';


function Create_expense(){


  
   //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");


 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
{/*} if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
   else{

   */}

    
      
//Variables
const[Tbanks,setTbanks] = useState([]);//SetData Variable

//Error Variable
const[errorvar,seterrorvar] = useState();

//date
const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState();
//voucher_no
const[voucher_no,setvoucher_no]=useState();const updatevoucher_no = (event) => { setvoucher_no(event.target.value);}; const[voucher_no_er,setvoucher_no_er]=useState();
//gstin
const[gstin,setgstin]=useState();const updategstin = (event) => { setgstin(event.target.value);}; const[gstin_er,setgstin_er]=useState();
//title
const[title,settitle]=useState();const updatetitle = (event) => { settitle(event.target.value);}; const[title_er,settitle_er]=useState();
//type
const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
//particular
const[particular,setparticular]=useState();const updateparticular = (event) => { setparticular(event.target.value);}; const[particular_er,setparticular_er]=useState();
//amount
const[amount,setamount]=useState();const updateamount = (event) => { setamount(event.target.value);calculate(); }; const[amount_er,setamount_er]=useState();
//bank_id
const[bank_id,setbank_id]=useState();const updatebank_id = (event) => { setbank_id(event.target.value);}; const[bank_id_er,setbank_id_er]=useState();
//total
const[total,settotal]=useState();const updatetotal = (event) => { settotal(event.target.value);calculate(); }; const[total_er,settotal_er]=useState();
//discount_per
const[discount_per,setdiscount_per]=useState();const updatediscount_per = (event) => { setdiscount_per(event.target.value); calculate(); }; const[discount_per_er,setdiscount_per_er]=useState();
//discount_amt
const[discount_amt,setdiscount_amt]=useState();const updatediscount_amt = (event) => { setdiscount_amt(event.target.value); calculate(); }; const[discount_amt_er,setdiscount_amt_er]=useState();
//sgst_per
const[sgst_per,setsgst_per]=useState();const updatesgst_per = (event) => { setsgst_per(event.target.value); calculate(); }; const[sgst_per_er,setsgst_per_er]=useState();
//sgst_amt
const[sgst_amt,setsgst_amt]=useState();const updatesgst_amt = (event) => { setsgst_amt(event.target.value); calculate(); }; const[sgst_amt_er,setsgst_amt_er]=useState();
//cgst_per
const[cgst_per,setcgst_per]=useState();const updatecgst_per = (event) => { setcgst_per(event.target.value); calculate(); }; const[cgst_per_er,setcgst_per_er]=useState();
//cgst_amt
const[cgst_amt,setcgst_amt]=useState();const updatecgst_amt = (event) => { setcgst_amt(event.target.value); calculate(); }; const[cgst_amt_er,setcgst_amt_er]=useState();
//igst_per
const[igst_per,setigst_per]=useState();const updateigst_per = (event) => { setigst_per(event.target.value); calculate(); }; const[igst_per_er,setigst_per_er]=useState();
//igst_amt
const[igst_amt,setigst_amt]=useState();const updateigst_amt = (event) => { setigst_amt(event.target.value); calculate();}; const[igst_amt_er,setigst_amt_er]=useState();
//tax_per
const[tax_per,settax_per]=useState();const updatetax_per = (event) => { settax_per(event.target.value); calculate();}; const[tax_per_er,settax_per_er]=useState();
//tax_amt
const[tax_amt,settax_amt]=useState();const updatetax_amt = (event) => { settax_amt(event.target.value); calculate(); }; const[tax_amt_er,settax_amt_er]=useState();



//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();




useEffect(()=>{
   
  getData();
  
  },[])
 

  
function calculate(){
  
  //Fetching Values
  let amount = (document.getElementById("amount").value);if(amount==""){ amount=0;}else{ amount=parseFloat(amount);}
  let discount_per = (document.getElementById("discount_per").value);if( discount_per==""){ discount_per=0;}else{ discount_per=parseFloat(discount_per);}
  let discount_amt = (document.getElementById("discount_amt").value);if(discount_amt==""){ discount_amt=0;}else{ discount_amt=parseFloat(discount_amt);}
  let sgst_per = (document.getElementById("sgst_per").value);if( sgst_per==""){ sgst_per=0;}else{ sgst_per=parseFloat(sgst_per);}
  let sgst_amt = (document.getElementById("sgst_amt").value);if(sgst_amt==""){ sgst_amt=0;}else{ sgst_amt=parseFloat(sgst_amt);}
  let cgst_per = (document.getElementById("cgst_per").value);if( cgst_per==""){ cgst_per=0;}else{ cgst_per=parseFloat(cgst_per);}
  let cgst_amt = (document.getElementById("cgst_amt").value);if(cgst_amt==""){ cgst_amt=0;}else{ cgst_amt=parseFloat(cgst_amt);}
  let igst_per = (document.getElementById("igst_per").value);if( igst_per==""){ igst_per=0;}else{ igst_per=parseFloat(igst_per);}
  let igst_amt = (document.getElementById("igst_amt").value);if(igst_amt==""){ igst_amt=0;}else{ igst_amt=parseFloat(igst_amt);}
  let tax_per = (document.getElementById("tax_per").value);if( tax_per==""){ tax_per=0;}else{ tax_per=parseFloat(tax_per);}
  let tax_amt = (document.getElementById("tax_amt").value);if(tax_amt==""){ tax_amt=0;}else{ tax_amt=parseFloat(tax_amt);}

  let total=(amount);
  if(amount>0){
    //Discount Calculation
    if(discount_per>0){discount_amt = ((amount)*(discount_per)/100);}
    let after_discount = amount-discount_amt;
   //Gst and Tax Calculation
   if(sgst_per>0){sgst_amt = ((after_discount)*(sgst_per)/100);}
   if(cgst_per>0){cgst_amt = ((after_discount)*(cgst_per)/100);}
   if(igst_per>0){igst_amt = ((after_discount)*(igst_per)/100);}
   if(tax_per>0){tax_amt = ((after_discount)*(tax_per)/100);}
   
   //Setting Final Values to display
    total = (after_discount+sgst_amt+cgst_amt+igst_amt+tax_amt);setdiscount_amt(discount_amt.toFixed(2));setsgst_amt(sgst_amt.toFixed(2));setcgst_amt(cgst_amt.toFixed(2));setigst_amt(igst_amt.toFixed(2));settax_amt(tax_amt.toFixed(2));settotal(total.toFixed(2));

}
}
    
  
//******************* GET BANKS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
 
 axios.post("bt-get-banks", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setTbanks(response.data.data);
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET BANKS DATA FUNCTION END  ********************//

 
  
//******************* CREATE EXPENSEBOOK FUNCTION START ********************//
function createexpense(){

  //Setting Error Variables
setdate_er(" ");setvoucher_no_er(" ");setgstin_er(" ");settype_er(" ");settitle_er(" ");setparticular_er(" ");setamount_er(" ");settotal_er(" ");setbank_id_er(" ");setdiscount_per_er(" ");setdiscount_amt_er(" ");
setsgst_per_er(" ");setsgst_amt_er(" ");setcgst_per_er(" ");setcgst_amt_er(" ");setigst_per_er(" ");setigst_amt_er(" ");settax_per_er(" ");settax_amt_er(" ");seterror_msg(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-create-expense", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Date : moment(date).format('YYYY-MM-DD'),
   Voucher_no:voucher_no,
   Gstin:gstin,
   Title:title,
   Type:type,
   Bank_id:bank_id,
   Particular : particular,
   Amount : amount,
   Discount_per:discount_per,
   Discount_amt:discount_amt,
   Sgst_per:sgst_per,
   Sgst_amt:sgst_amt,
   Cgst_per:cgst_per,
   Cgst_amt:cgst_amt,
   Igst_per:igst_per,
   Igst_amt:igst_amt,
   Tax_per:tax_per,
   Tax_amt:tax_amt,

 })
 .then((response) => {

  if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }
  
  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setdate_er(response.data.validatorerror.Date);
  settype_er(response.data.validatorerror.Type);
  settitle_er(response.data.validatorerror.Title);
  setvoucher_no_er(response.data.validatorerror.Voucher_no);
  setgstin_er(response.data.validatorerror.Gstin);
  setbank_id_er(response.data.validatorerror.Bank_id);
  setparticular_er(response.data.validatorerror.Particular);
  setamount_er(response.data.validatorerror.Amount);
  setdiscount_per_er(response.data.validatorerror.Discount_per);
  setdiscount_amt_er(response.data.validatorerror.Discount_amt);
  setsgst_per_er(response.data.validatorerror.Sgst_per);
  setsgst_amt_er(response.data.validatorerror.Sgst_amt);
  setcgst_per_er(response.data.validatorerror.Cgst_per);
  setcgst_amt_er(response.data.validatorerror.Cgst_amt);
  setigst_per_er(response.data.validatorerror.Igst_per);
  setigst_amt_er(response.data.validatorerror.Igst_amt);
  settax_per_er(response.data.validatorerror.Tax_per);
  settax_amt_er(response.data.validatorerror.Tax_amt);
  return;
}
//Invalid Entries
if(response.data.error==102){ 
  
  setdate_er(response.data.date_er);
  settype_er(response.data.type_er);
  settitle_er(response.data.title_er);
  setvoucher_no_er(response.data.voucher_no_er);
  setgstin_er(response.data.gstin_er);
  setbank_id_er(response.data.bank_id_er);
  setparticular_er(response.data.particular_er);
  setamount_er(response.data.amount_er);
  setdiscount_per_er(response.data.discount_per_er);
  setdiscount_amt_er(response.data.discount_amt_er);
  setsgst_per_er(response.data.sgst_per_er);
  setsgst_amt_er(response.data.sgst_amt_er);
  setcgst_per_er(response.data.cgst_per_er);
  setcgst_amt_er(response.data.cgst_amt_er);
  setigst_per_er(response.data.igst_per_er);
  setigst_amt_er(response.data.igst_amt_er);
  settax_per_er(response.data.tax_per_er);
  settax_amt_er(response.data.tax_amt_er);
  seterror_msg("Cannot Add Transaction!"); return;  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg); return; }
  if(response.data.error==104){     seterror_msg("Select Bank !"); return; }
  if(response.data.error==105){     seterror_msg("Voucher Number Already Exists !"); return;  }

   //Transaction Entered Successfully
   if(response.data.successvar==1){        document.getElementById("success-card").style.display="block";  }
   //Cannot Add Transaction
   else{  seterror_msg("Cannot Add Transaction !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* CREATE EXPENSEBOOK FUNCTION END ********************//

return(
    <>
                <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Create Expense</h4>
                    <div class="form-sample">
                      <p class="card-description">add your expense details here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date" value={date} onChange={updatedate} />
                              <code>{date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Voucher No.</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="VN87766" id="voucher_no" value={voucher_no} onChange={updatevoucher_no} />
                            <code>{voucher_no_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">GSTIN</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="HGSSSFS55S4S4S4" id="gstin" value={gstin} onChange={updategstin} />
                            <code>{gstin_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Title</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Regular expense" id="title" value={title} onChange={updatetitle} />
                            <code>{title_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="type" value={type} onChange={updatetype} >
                              <option>Select Type</option>
                              <option value="cash">Cash</option>
                              <option value="bank">Bank</option>
                              <option value="outstanding">Outstanding</option>
                              </select>
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="bank_id" value={bank_id} onChange={updatebank_id} >
                              <option>Select Bank</option>
                              {
                              Tbanks.map((curElm2,ind)=>{

                              return(
                              <>
                              <option value={curElm2.Bank_id}>{curElm2.Bank_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{bank_id_er}</code>
                            </div>
                          </div>
                        </div>

                      </div>
                    
                   
                 
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="2000.00" id="amount" value={amount} onChange={updateamount}  />
                            <code>{amount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{fontWeight:"bold"}} placeholder="1300.00" id="total" value={total} onChange={updatetotal}/>
                            <code>{total_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="discount_per" value={discount_per} onChange={updatediscount_per} />
                            <code>{discount_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1000.00" id="discount_amt" value={discount_amt} onChange={updatediscount_amt} />
                            <code>{discount_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">SGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="05.00" id="sgst_per" value={sgst_per} onChange={updatesgst_per} />
                              <code>{sgst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">SGST Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="sgst_amt" value={sgst_amt} onChange={updatesgst_amt} />
                              <code>{sgst_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">CGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="05.00" id="cgst_per" value={cgst_per} onChange={updatecgst_per} />
                              <code>{cgst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">CGST Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="cgst_amt" value={cgst_amt} onChange={updatecgst_amt} />
                              <code>{cgst_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="05.00" id="igst_per" value={igst_per} onChange={updateigst_per}/>
                              <code>{igst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IGST Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="igst_amt" value={igst_amt} onChange={updateigst_amt} />
                              <code>{igst_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Tax %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="05.00" id="tax_per" value={tax_per} onChange={updatetax_per}/>
                              <code>{tax_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Tax Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="tax_amt" value={tax_amt} onChange={updatetax_amt} />
                              <code>{tax_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="form-group">
                        <label for="exampleTextarea1">Particular</label>
                        <textarea  class="form-control" rows="2" id="particular" value={particular} onChange={updateparticular} ></textarea>
                        <code>{particular_er}</code>
                      </div>

                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={createexpense}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
    </>

);
}
//}

export default Create_expense;