import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from '../axios.js';

function Navbar(){

  //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");
 const logname=sessionStorage.getItem("logName00");
 
 
//planname
const[planname,setplanname]=useState();
//businesscount
const[businesscount,setbusinesscount]=useState(0);
//businesscount1
const[businesscount1,setbusinesscount1]=useState(0);
//counterscount
const[counterscount,setcounterscount]=useState(0);
//counterscount1
const[counterscount1,setcounterscount1]=useState(0);
//productlistcount
const[productlistcount,setproductlistcount]=useState(0);
//productlistcount1
const[productlistcount1,setproductlistcount1]=useState(0);
//teamcount
const[teamcount,setteamcount]=useState(0);
//teamcount1
const[teamcount1,setteamcount1]=useState(0);
//customerscount
const[customerscount,setcustomerscount]=useState(0);
//customerscount1
const[customerscount1,setcustomerscount1]=useState(0);
//supplierscount
const[supplierscount,setsupplierscount]=useState(0);
//supplierscount1
const[supplierscount1,setsupplierscount1]=useState(0);
//transcount
const[transcount,settranscount]=useState(0);
//tleftcount
const[tleftcount,settleftcount]=useState(0);
//planenddate
const[planenddate,setplanenddate]=useState();
//extratranscount
const[extratranscount,setextratranscount]=useState(0);
//extratrans_enddate
const[extratrans_enddate,setextratrans_enddate]=useState();

function defaultload(){
  
         //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
   
      //fetching login api
     axios.post("bt-navbar-info", {
        "Log_id":logid,
        "Token":token,
        "Logstatus":logstatus
      })
        .then((response) => {

          //Success and Data Exists
          if(response.data.successvar==1){
          
            setplanname(response.data.planname);
            setbusinesscount(response.data.max_business_limit);
            setbusinesscount1(response.data.businesscount);
            setcounterscount(response.data.max_counters_limit);
            setcounterscount1(response.data.counterscount);
            setteamcount(response.data.max_team_limit);
            setproductlistcount1(response.data.productlistcount);
            setproductlistcount(response.data.max_pl_limit);
            setteamcount1(response.data.teamcount);
            setcustomerscount(response.data.max_customers_limit);
            setcustomerscount1(response.data.customerscount);
            setsupplierscount(response.data.max_suppliers_limit);
            setsupplierscount1(response.data.supplierscount);
            settranscount(response.data.active_trans);
            settleftcount(response.data.trans_left);
            setplanenddate(response.data.fedate);

          }
          else if(response.data.successvar==5){
          
            //Redirecting to Pricing Page
            window.location.replace("/bt-freeplans");
          }
          else{
              //Remove Session Variables and redirect to login
         sessionStorage.removeItem("logStatus00");
         sessionStorage.removeItem("logId00");
         sessionStorage.removeItem("logToken00");
         sessionStorage.removeItem("logName00");
         window.location.replace("businesstools-login");
          }
     
        }); 
      

      }
      else{
         //Remove Session Variables and redirect to login
         sessionStorage.removeItem("logStatus00");
         sessionStorage.removeItem("logId00");
         sessionStorage.removeItem("logToken00");
         sessionStorage.removeItem("logName00");
         window.location.replace("businesstools-login");
         }
      }   

  
    
  useEffect(()=>{
    
    defaultload();
  
  },[])

  


return(
    <>
        {/* partial */}
        {/* partial:partials/_navbar.html */}
        <nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
          <div class="navbar-menu-wrapper d-flex align-items-stretch">
            <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
              <span class="mdi mdi-chevron-double-left"></span>
            </button>
            <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
              <a class="navbar-brand brand-logo-mini" href="index.html"><img src="panel/assets/images/logo-mini.svg" alt="logo" /></a>
            </div>
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <a class="nav-link" id="messageDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                {/*}  <i class="mdi mdi-email-outline"></i> */}  <i class="mdi mdi-account-circle" style={{fontSize:"2rem"}}></i>
                </a>
                <div class="dropdown-menu dropdown-menu-left navbar-dropdown preview-list" aria-labelledby="messageDropdown" style={{border:"0.2rem solid #1c4ecb"}}>
                  <h6 class="p-3 mb-0 font-weight-semibold">My Profile</h6>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item preview-item">
                  {/*} <div class="preview-thumbnail">
                      <img src="panel/assets/images/faces/face1.jpg" alt="image" class="profile-pic" />
                    </div>  */}
                    <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal"><b>Plan Details</b></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">{planname}</h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Businesses : <b>{businesscount1}/{businesscount}</b></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Counters : <b>{counterscount1}/{counterscount}</b></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Team : <b>{teamcount1}/{teamcount}</b> <span hidden={logtype=="user"}><a href="/businesstools-user-team"><i class="mdi mdi-chevron-double-right text-primary" style={{marginLeft:"1rem"}} /></a></span></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Customers : <b>{customerscount1}/{customerscount}</b></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Suppliers : <b>{supplierscount1}/{supplierscount}</b></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Product List : <b>{productlistcount1}/{productlistcount}</b></h6>
                      <a href="/businesstools-user-pricing"><p class="text-gray mb-0 text-primary">upgrade <i class="mdi mdi-chevron-double-right"></i></p></a>
                    </div>
                  </a>
                  <div class="dropdown-divider"></div>
                
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item preview-item" >
                  {/*}  <div class="preview-thumbnail">
                      <img src="panel/assets/images/faces/face7.jpg" alt="image" class="profile-pic" />
                    </div> */}
                    <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal"><b>Plan Summary</b></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Transactions : <b class="text-warning">{tleftcount}</b></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Left : <b class="text-success">{transcount}</b></h6>
                      <p class="text-gray mb-0">Valid Till : <b>{planenddate}</b></p>
                    </div>
                  </a>
                 {/*} <a class="dropdown-item preview-item" >
                  {/*}  <div class="preview-thumbnail">
                      <img src="panel/assets/images/faces/face7.jpg" alt="image" class="profile-pic" />
                    </div>  */}
                {/*}   <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 class="preview-subject ellipsis mb-1 font-weight-normal"><b>Additional</b></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Transactions : <b>150</b></h6>
                      <p class="text-gray mb-0">Valid Till : <b>24-02-2024 </b></p>
                    </div> 
                 */}
                  <div class="dropdown-divider"></div>
                  <h6 class="p-3 mb-0 text-center text-primary font-13" onClick={()=>{

                     //Remove Session Variables and redirect to login
                     sessionStorage.removeItem("logStatus00");
                     sessionStorage.removeItem("logId00");
                     sessionStorage.removeItem("logToken00");
                     sessionStorage.removeItem("BusyId00");
                     sessionStorage.removeItem("CounterId00");
                     sessionStorage.removeItem("logName00");
                     window.location.replace("businesstools-login");


                  }}>LogOut</h6>
                </div>
              </li>
              <li class="nav-item dropdown ms-3">
                <a class="nav-link" id="notificationDropdown" href="#" data-bs-toggle="dropdown">
                 {/*} <i class="mdi mdi-bell-outline"></i> */}<i class="mdi mdi-help-circle-outline" style={{fontSize:"2rem"}}></i>
                </a>
                <div class="dropdown-menu dropdown-menu-left navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                  <h6 class="px-3 py-3 font-weight-semibold mb-0">Help Box</h6>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item preview-item" href="/businesstools-feedback">
                    <div class="preview-thumbnail">
                      <div class="preview-icon bg-info">
                       {/*} <i class="mdi mdi-calendar"></i> */}<i class="mdi mdi-comment-text"></i>
                      </div>
                    </div>
                    <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 class="preview-subject font-weight-normal mb-0">Feedback</h6>
                      <p class="text-gray ellipsis mb-0"> available 24*7</p>
                    </div>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item preview-item" href="/businesstools-support">
                    <div class="preview-thumbnail">
                      <div class="preview-icon bg-warning">
                        {/*<i class="mdi mdi-settings"></i>*/}<i class="mdi mdi-headset"></i>
                      </div>
                    </div>
                    <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 class="preview-subject font-weight-normal mb-0">Support</h6>
                      <p class="text-gray ellipsis mb-0">from 10:00am to 8:00pm </p>
                    </div>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item preview-item" aria-label="Chat on WhatsApp" href="https://wa.me/919399395256?text=I%20want%20support%20on%20Startup%20Connect's%20Business%20Tools">
                    <div class="preview-thumbnail">
                      <div class="preview-icon bg-success">
                     <i class="mdi mdi-whatsapp"></i>
                      </div>
                    </div>
                    <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 class="preview-subject font-weight-normal mb-0">WhatsApp</h6>
                      <p class="text-gray ellipsis mb-0">from 10:00am to 8:00pm </p>
                    </div>
                  </a>
                  <div class="dropdown-divider"></div>
               {/*}   <h6 class="p-3 font-13 mb-0 text-primary text-center">View all notifications</h6> */}
                </div>
              </li>
            </ul>
            <ul class="navbar-nav navbar-nav-right">
             {/*} <li class="nav-item nav-logout d-none d-md-block me-3">
                <a class="nav-link" href="#">Status</a>
              </li> */}
            {/*}  <li class="nav-item nav-logout d-none d-md-block">
                <button class="btn btn-sm btn-danger">Trailing</button>
              </li> */}
          {/*}   <li class="nav-item nav-profile dropdown d-none d-md-block">
                <a class="nav-link dropdown-toggle" id="profileDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="mdi mdi-account-circle" style={{fontSize:"3rem"}}></i>
                </a>
                <div class="dropdown-menu center navbar-dropdown" aria-labelledby="profileDropdown" style={{marginRight:"2rem"}}>
                  <a class="dropdown-item" href="#">
                    <i class="flag-icon flag-icon-bl me-3"></i> My Profile </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">
                    <i class="flag-icon flag-icon-cn me-3"></i> Team </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">
                    <i class="flag-icon flag-icon-de me-3"></i> <b>Plan Details : </b> Basic </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">
                    <i class="flag-icon flag-icon-ru me-3"></i><b>Valid Till : </b> 12-02-2024</a>
                </div>
              </li> 
              <li></li>
              <li></li> */}
             {/*} <li class="nav-item nav-logout d-none d-lg-block">
                <a class="nav-link" href="index.html">
                  <i class="mdi mdi-home-circle"></i>
                </a>
              </li> */}
            </ul>
            <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
              <span class="mdi mdi-menu"></span>
            </button> 
          </div>
        </nav>
    </>
);

}
export default Navbar;