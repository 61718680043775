import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';

import '../Resources/dashboard.css';
import Navbar from '../navbar';
import Sidebar from '../Sidebar';
import Create_income from './create_income';

import Chart from "chart.js/auto";
import { Line, Bar, Pie } from "react-chartjs-2";

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';

//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function Userincomes(){

  
 //Report Print Function
 const componentRef = useref();
 const handlePrint = useReactToPrint({
   content: () => componentRef.current,
 });

       
//Variables
const[banks,setbanks] = useState([]);//SetData Variable
const[data,setData] = useState([]);//SetData Variable
 
  // Function to download the data as an Excel file
  const downloadExcel = () => {
    // Convert JSON data to Excel worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    
    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
    // Convert the workbook to a binary array
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    
    // Create a Blob from the binary data and trigger a download
    const excel_data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(excel_data, "incomes_data.xlsx");
  };
  
  

  //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");

 

 
 
{/* INCOMES TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14
const[cd_col15_val,setcd_col15_val] = useState(0);//column15
const[cd_col16_val,setcd_col16_val] = useState(0);//column16
const[cd_col17_val,setcd_col17_val] = useState(0);//column17
const[cd_col18_val,setcd_col18_val] = useState(0);//column18
const[cd_col19_val,setcd_col19_val] = useState(0);//column19
const[cd_col20_val,setcd_col20_val] = useState(0);//column20
const[cd_col21_val,setcd_col21_val] = useState(0);//column21
const[cd_col22_val,setcd_col22_val] = useState(0);//column22
const[cd_col23_val,setcd_col23_val] = useState(0);//column23
const[cd_col24_val,setcd_col24_val] = useState(0);//column24

{/* INCOMES TABLE COLUMNS MANAGEMENT VARIABLES END */}



 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
 /*if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))||((busyid==null)||(busyid==" "))||((counterid==null)||(counterid==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
  else*/{



    


//Error Variable
const[errorvar,seterrorvar] = useState();


//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[filter_start_date,setfilter_start_date] = useState('1970-01-01'); const updatefilter_start_date = (event) => { setfilter_start_date(event.target.value);}; const[filter_start_date_er,setfilter_start_date_er]=useState();
const[filter_end_date,setfilter_end_date] = useState('2100-12-12'); const updatefilter_end_date = (event) => { setfilter_end_date(event.target.value);}; const[filter_end_date_er,setfilter_end_date_er]=useState();
const[filter_trno,setfilter_trno] = useState(); const updatefilter_trno = (event) => { setfilter_trno(event.target.value);}; const[filter_trno_er,setfilter_trno_er]=useState();
const[filter_type,setfilter_type] = useState(); const updatefilter_type = (event) => { setfilter_type(event.target.value);}; const[filter_type_er,setfilter_type_er]=useState();
const[filter_amount_sign,setfilter_amount_sign] = useState(); const updatefilter_amount_sign = (event) => { setfilter_amount_sign(event.target.value);}; const[filter_amount_sign_er,setfilter_amount_sign_er]=useState();
const[filter_amount,setfilter_amount] = useState(); const updatefilter_amount = (event) => { setfilter_amount(event.target.value);}; const[filter_amount_er,setfilter_amount_er]=useState();
const[filter_total_sign,setfilter_total_sign] = useState(); const updatefilter_total_sign = (event) => { setfilter_total_sign(event.target.value);}; const[filter_total_sign_er,setfilter_total_sign_er]=useState();
const[filter_total,setfilter_total] = useState(); const updatefilter_total = (event) => { setfilter_total(event.target.value);}; const[filter_total_er,setfilter_total_er]=useState();
const[filter_voucher_no,setfilter_voucher_no] = useState(); const updatefilter_voucher_no = (event) => { setfilter_voucher_no(event.target.value);}; const[filter_voucher_no_er,setfilter_voucher_no_er]=useState();
const[filter_gstin,setfilter_gstin] = useState(); const updatefilter_gstin = (event) => { setfilter_gstin(event.target.value);}; const[filter_gstin_er,setfilter_gstin_er]=useState();
const[filter_title,setfilter_title] = useState(); const updatefilter_title = (event) => { setfilter_title(event.target.value);}; const[filter_title_er,setfilter_title_er]=useState();


//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);
    

useEffect(()=>{
   
  getData();
  
  },[])

   //SETTING DEFAULT STATE VARIABLES FOR CHART */
 
 //LINECHART VARIABLES
 const[lc_summary,setlc_summary] = useState([],);
 const[lc_amount,setlc_amount] = useState([],);
 /*const[lc_outflow,setlc_outflow] = useState([],);
 const[lc_netcashflow,setlc_netcashflow] = useState([],);*/

 //Line Chart
 const[lc_date,setlc_date] = useState();
 const[lc_default,setlc_default] = useState(0);
 const[lc_start_date,setlc_start_date] = useState(0);const updatelc_start_date = (event) => { setlc_start_date(event.target.value); }; 
 const[lc_end_date,setlc_end_date] = useState(0);const updatelc_end_date = (event) => { setlc_end_date(event.target.value); }; 
 const[lc_date_error,setlc_date_error] = useState();

 //Pie Chart
 const[pc_default,setpc_default] = useState(0);
 const[pc_start_date,setpc_start_date] = useState(0);const updatepc_start_date = (event) => { setpc_start_date(event.target.value); }; 
 const[pc_end_date,setpc_end_date] = useState(0);const updatepc_end_date = (event) => { setpc_end_date(event.target.value); }; 
 const[pc_date_error,setpc_date_error] = useState();
 const[pc_cash,setpc_cash] = useState();
 const[pc_bank,setpc_bank] = useState();
 const[pc_outstanding,setpc_outstanding] = useState();

 

  
  
//******************* GET INCOMEBOOK DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))){
 
    
  //Fetching Filter Variables
  const $filter_start_date = filter_start_date;const $filter_end_date = filter_end_date;const $filter_trno = filter_trno;const $filter_type = filter_type;
  const $filter_amount_sign = filter_amount_sign;const $filter_amount = filter_amount;  const $filter_total_sign = filter_total_sign;const $filter_total = filter_total;
  const $filter_voucher_no = filter_voucher_no;const $filter_gstin = filter_gstin;const $filter_title = filter_title;
   

 
 axios.post("bt-get-incomebook-default", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0,
     //Line Chart
  lc_default:lc_default,
  lc_start_date:moment(lc_start_date).format('YYYY-MM-DD'),
  lc_end_date:moment(lc_end_date).format('YYYY-MM-DD'),
  //Bar Chart
  pc_default:pc_default,
  pc_start_date:moment(pc_start_date).format('YYYY-MM-DD'),
  pc_end_date:moment(pc_end_date).format('YYYY-MM-DD'),
  //Filters
  Filter_val : filter_val,
  Filter_start_date : moment($filter_start_date).format('YYYY-MM-DD'),
  Filter_end_date : moment($filter_end_date).format('YYYY-MM-DD'),
  Filter_trno : $filter_trno,
  Filter_type : $filter_type,
  Filter_amount_sign : $filter_amount_sign,
  Filter_amount : $filter_amount,
  Filter_total_sign : $filter_total_sign,
  Filter_total : $filter_total,
  Filter_voucher_no : $filter_voucher_no,
  Filter_gstin : $filter_gstin,
  Filter_title : $filter_title,
  
//Code Edits for pagination
'page' : pageNumber

 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setbanks(response.data.banks);
   setData(response.data.data.data);
   const lc_summary = response.data.lc_summary;
   // Extract data for line chart
   setlc_date(lc_summary.map((entry) => entry.Date));
   setlc_amount(lc_summary.map((entry) => entry.Amount));
   //setlc_outflow(lc_summary.map((entry) => entry.Outflow)); 
   //extract data for bar chart
   setpc_outstanding(response.data.pc_outstanding);
   setpc_bank(response.data.pc_bank);
   setpc_cash(response.data.pc_cash);
  
  }
   //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    setfilter_start_date_er(response.data.validatorerror.Filter_start_date);
    setfilter_end_date_er(response.data.validatorerror.Filter_end_date);
    setfilter_trno_er(response.data.validatorerror.Filter_trno);
    setfilter_type_er(response.data.validatorerror.Filter_type);
    setfilter_amount_sign_er(response.data.validatorerror.Filter_amount_sign);
    setfilter_amount_er(response.data.validatorerror.Filter_amount);
    setfilter_total_sign_er(response.data.validatorerror.Filter_total_sign);
    setfilter_total_er(response.data.validatorerror.Filter_total);
    setfilter_voucher_no_er(response.data.validatorerror.Filter_voucher_no);
    setfilter_gstin_er(response.data.validatorerror.Filter_gstin);
    setfilter_title_er(response.data.validatorerror.Filter_title);
  
  }

  //Data Violation
  if(response.data.error==102){ 
  
    setfilter_start_date_er(response.data.filter_start_date_er);
    setfilter_end_date_er(response.data.filter_end_date_er);
    setfilter_trno_er(response.data.filter_trno_er);
    setfilter_type_er(response.data.filter_type_er);
    setfilter_amount_sign_er(response.data.filter_amount_sign_er);
    setfilter_amount_er(response.data.filter_amount_er);
    setfilter_total_sign_er(response.data.filter_total_sign_er);
    setfilter_total_er(response.data.filter_total_er);
    setfilter_voucher_no_er(response.data.filter_voucher_no_er);
    setfilter_gstin_er(response.data.filter_gstin_er);
    setfilter_title_er(response.data.filter_title_er);
  
  }
  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET INCOMEBOOK DATA FUNCTION END  ********************//



 /* LINE CHART START */
 const data1 = {
  labels: lc_date,
  datasets: [
    {
      label: "Transaction Summary",
      backgroundColor: ["rgb(255, 99, 132)" ],
      borderColor: "rgb(255, 99, 132)",
      data: lc_amount,
    },
   /* {
      label: "Bank to Cash",
      backgroundColor: ["rgb(54, 160, 255)"],
      borderColor: "rgb(54, 160, 255)",
      data: lc_inflow,
    },
    {
      label: "Cash to Bank",
      backgroundColor: ["rgb(54, 160, 255)"],
      borderColor: "rgb(54, 160, 255)",
      data: lc_inflow,
    },*/
  ],
};
/* LINE CHART END */ 

  /* PIE CHART VARIABLES START */
const data2 = {
  labels: ["cash", "bank", "outstanding"],
  datasets: [
    {
      label: "# transactions summary",
      data: [pc_cash, pc_bank, pc_outstanding],
      backgroundColor: [ "#3fe077", "#0b3190", "#d76721"],
      borderColor: [ "#ffffff", "#ffffff", "#ffffff"],
      borderWidth: 1,
    },
  ],
};
  /* PIE CHART VARIABLES END */



 
  //******************* GET INCOMEBOOK DATA BY ID FUNCTION START  ********************//


//edate
const[edate,setedate]=useState();const updateedate = (event) => { setedate(event.target.value);}; const[edate_er,setedate_er]=useState();
//evoucher_no
const[evoucher_no,setevoucher_no]=useState();const updateevoucher_no = (event) => { setevoucher_no(event.target.value);}; const[evoucher_no_er,setevoucher_no_er]=useState();
//egstin
const[egstin,setegstin]=useState();const updateegstin = (event) => { setegstin(event.target.value);}; const[egstin_er,setegstin_er]=useState();
//etitle
const[etitle,setetitle]=useState();const updateetitle = (event) => { setetitle(event.target.value);}; const[etitle_er,setetitle_er]=useState();
//etype
const[etype,setetype]=useState();const updateetype = (event) => { setetype(event.target.value);}; const[etype_er,setetype_er]=useState();
//eparticular
const[eparticular,seteparticular]=useState();const updateeparticular = (event) => { seteparticular(event.target.value);}; const[eparticular_er,seteparticular_er]=useState();
//eamount
const[eamount,seteamount]=useState();const updateeamount = (event) => { seteamount(event.target.value);ecalculate(); }; const[eamount_er,seteamount_er]=useState();
//ebank_id
const[ebank_id,setebank_id]=useState();const updateebank_id = (event) => { setebank_id(event.target.value);}; const[ebank_id_er,setebank_id_er]=useState();
//ebank_name
const[ebank_name,setebank_name]=useState();const updateebank_name = (event) => { setebank_name(event.target.value);}; const[ebank_name_er,setebank_name_er]=useState();
//etotal
const[etotal,setetotal]=useState();const updateetotal = (event) => { setetotal(event.target.value);ecalculate(); }; const[etotal_er,setetotal_er]=useState();
//ediscount_per
const[ediscount_per,setediscount_per]=useState();const updateediscount_per = (event) => { setediscount_per(event.target.value); ecalculate(); }; const[ediscount_per_er,setediscount_per_er]=useState();
//ediscount_amt
const[ediscount_amt,setediscount_amt]=useState();const updateediscount_amt = (event) => { setediscount_amt(event.target.value); ecalculate(); }; const[ediscount_amt_er,setediscount_amt_er]=useState();
//esgst_per
const[esgst_per,setesgst_per]=useState();const updateesgst_per = (event) => { setesgst_per(event.target.value); ecalculate(); }; const[esgst_per_er,setesgst_per_er]=useState();
//esgst_amt
const[esgst_amt,setesgst_amt]=useState();const updateesgst_amt = (event) => { setesgst_amt(event.target.value); ecalculate(); }; const[esgst_amt_er,setesgst_amt_er]=useState();
//ecgst_per
const[ecgst_per,setecgst_per]=useState();const updateecgst_per = (event) => { setecgst_per(event.target.value); ecalculate(); }; const[ecgst_per_er,setecgst_per_er]=useState();
//ecgst_amt
const[ecgst_amt,setecgst_amt]=useState();const updateecgst_amt = (event) => { setecgst_amt(event.target.value); ecalculate(); }; const[ecgst_amt_er,setecgst_amt_er]=useState();
//eigst_per
const[eigst_per,seteigst_per]=useState();const updateeigst_per = (event) => { seteigst_per(event.target.value); ecalculate(); }; const[eigst_per_er,seteigst_per_er]=useState();
//eigst_amt
const[eigst_amt,seteigst_amt]=useState();const updateeigst_amt = (event) => { seteigst_amt(event.target.value); ecalculate();}; const[eigst_amt_er,seteigst_amt_er]=useState();
//etax_per
const[etax_per,setetax_per]=useState();const updateetax_per = (event) => { setetax_per(event.target.value); ecalculate();}; const[etax_per_er,setetax_per_er]=useState();
//etax_amt
const[etax_amt,setetax_amt]=useState();const updateetax_amt = (event) => { setetax_amt(event.target.value); ecalculate(); }; const[etax_amt_er,setetax_amt_er]=useState();



//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();

//Edit Function
function getincomedatabyId(){
 

  const incomeid=sessionStorage.getItem("Incomeid00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(busyid>0)&&(counterid>0)&&(incomeid>0)){
 
 
 axios.post("bt-incomebook-details-byid", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Income_id:incomeid,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0

 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Incomeid00");


     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data
   setedate(response.data.date);
   setegstin(response.data.gstin);
   setevoucher_no(response.data.voucher_no);
   setetitle(response.data.title);
   setetype(response.data.type);
   setebank_id(response.data.bank_id);
   setebank_name(response.data.bank_name);
   seteparticular(response.data.particular);
   seteamount(response.data.amount);
   setetotal(response.data.total);
   setediscount_per(response.data.discount_per);
   setediscount_amt(response.data.discount_amt);
   setesgst_per(response.data.sgst_per);
   setesgst_amt(response.data.sgst_amt);
   setecgst_per(response.data.cgst_per);
   setecgst_amt(response.data.cgst_amt);
   seteigst_per(response.data.igst_per);
   seteigst_amt(response.data.igst_amt);
   setetax_per(response.data.tax_per);
   setetax_amt(response.data.tax_amt);
  
  }
  //Cannot Fetch Data
  else{


      document.getElementById("edit-form").style.display="none";

     //Setting Variables to empty 
    setedate_er(" ");setevoucher_no_er(" ");setegstin_er(" ");setetype_er(" ");setetitle_er(" ");seteparticular_er(" ");seteamount_er(" ");setetotal_er(" ");setebank_id_er(" ");setediscount_per_er(" ");setediscount_amt_er(" ");
    setesgst_per_er(" ");setesgst_amt_er(" ");setecgst_per_er(" ");setecgst_amt_er(" ");seteigst_per_er(" ");seteigst_amt_er(" ");setetax_per_er(" ");setetax_amt_er(" ");seterror_msg(" ");
    setedate(" ");setevoucher_no(" ");setegstin(" ");setetype(" ");setetitle(" ");seteparticular(" ");seteamount(" ");setetotal(" ");setebank_id(" ");setediscount_per(" ");setediscount_amt(" ");
    setesgst_per(" ");setesgst_amt(" ");setecgst_per(" ");setecgst_amt(" ");seteigst_per(" ");seteigst_amt(" ");setetax_per(" ");setetax_amt(" ");
 

      document.getElementById("edit-error-card").style.display="block"; 
      sessionStorage.removeItem("Incomeid00");
  }
 
 
 });
 
 }
 //User not Logedin
 else{

     //Checking if user loggedin
     if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))){
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("IncomeId00");

   window.location.replace("businesstools-login");
     }
 
  }
 
 }
  
 //******************* GET INCOMEBOOK DATA BY ID FUNCTION END  ********************//



 
 //******************* EDIT INCOMEBOOK FUNCTION START ********************//
function editincome(){

  //Setting Error Variables
  setedate_er(" ");setevoucher_no_er(" ");setegstin_er(" ");setetype_er(" ");setetitle_er(" ");seteparticular_er(" ");seteamount_er(" ");setetotal_er(" ");setebank_id_er(" ");setediscount_per_er(" ");setediscount_amt_er(" ");
  setesgst_per_er(" ");setesgst_amt_er(" ");setecgst_per_er(" ");setecgst_amt_er(" ");seteigst_per_er(" ");seteigst_amt_er(" ");setetax_per_er(" ");setetax_amt_er(" ");seterror_msg(" ");

  const incomeid = sessionStorage.getItem("Incomeid00");
  const trno = sessionStorage.getItem("Trno00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((incomeid!=null)||(incomeid!=" "))&&((trno!=null)||(trno!=" "))){
 
 
  axios.post("bt-incomebook-edit", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Income_id:incomeid,
   Tr_no:trno,
   Date : moment(edate).format('YYYY-MM-DD'),
   Voucher_no:evoucher_no,
   Gstin:egstin,
   Title:etitle,
   Type:etype,
   Bank_id:ebank_id,
   Particular : eparticular,
   Amount : eamount,
   Discount_per:ediscount_per,
   Discount_amt:ediscount_amt,
   Sgst_per:esgst_per,
   Sgst_amt:esgst_amt,
   Cgst_per:ecgst_per,
   Cgst_amt:ecgst_amt,
   Igst_per:eigst_per,
   Igst_amt:eigst_amt,
   Tax_per:etax_per,
   Tax_amt:etax_amt,
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("Trno00");
     sessionStorage.removeItem("Contraid00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
  setedate_er(response.data.validatorerror.Date);
  setetype_er(response.data.validatorerror.Type);
  setetitle_er(response.data.validatorerror.Title);
  setevoucher_no_er(response.data.validatorerror.Voucher_no);
  setegstin_er(response.data.validatorerror.Gstin);
  setebank_id_er(response.data.validatorerror.Bank_id);
  seteparticular_er(response.data.validatorerror.Particular);
  seteamount_er(response.data.validatorerror.Amount);
  setediscount_per_er(response.data.validatorerror.Discount_per);
  setediscount_amt_er(response.data.validatorerror.Discount_amt);
  setesgst_per_er(response.data.validatorerror.Sgst_per);
  setesgst_amt_er(response.data.validatorerror.Sgst_amt);
  setecgst_per_er(response.data.validatorerror.Cgst_per);
  setecgst_amt_er(response.data.validatorerror.Cgst_amt);
  seteigst_per_er(response.data.validatorerror.Igst_per);
  seteigst_amt_er(response.data.validatorerror.Igst_amt);
  setetax_per_er(response.data.validatorerror.Tax_per);
  setetax_amt_er(response.data.validatorerror.Tax_amt);
  return;

}
//Invalid Entries
if(response.data.error==102){ 
  
  setedate_er(response.data.date_er);
  setetype_er(response.data.type_er);
  setetitle_er(response.data.title_er);
  setevoucher_no_er(response.data.voucher_no_er);
  setegstin_er(response.data.gstin_er);
  setebank_id_er(response.data.bank_id_er);
  seteparticular_er(response.data.particular_er);
  seteamount_er(response.data.amount_er);
  setediscount_per_er(response.data.discount_per_er);
  setediscount_amt_er(response.data.discount_amt_er);
  setesgst_per_er(response.data.sgst_per_er);
  setesgst_amt_er(response.data.sgst_amt_er);
  setecgst_per_er(response.data.cgst_per_er);
  setecgst_amt_er(response.data.cgst_amt_er);
  seteigst_per_er(response.data.igst_per_er);
  seteigst_amt_er(response.data.igst_amt_er);
  setetax_per_er(response.data.tax_per_er);
  setetax_amt_er(response.data.tax_amt_er);

 
  seterror_msg("Cannot Update Transaction!");
return;  }


  //Invalid Entries
  if(response.data.error==103){     seterror_msg(response.data.error_msg); return; }
  if(response.data.error==104){     seterror_msg("Select Bank !"); return; }
  if(response.data.error==105){     seterror_msg("Voucher Number Already Exists !"); return;  }

   //Transaction Updated Successfully
   if(response.data.successvar==1){        
     //Setting Variables to empty 
     setedate_er(" ");setevoucher_no_er(" ");setegstin_er(" ");setetype_er(" ");setetitle_er(" ");seteparticular_er(" ");seteamount_er(" ");setetotal_er(" ");setebank_id_er(" ");setediscount_per_er(" ");setediscount_amt_er(" ");
     setesgst_per_er(" ");setesgst_amt_er(" ");setecgst_per_er(" ");setecgst_amt_er(" ");seteigst_per_er(" ");seteigst_amt_er(" ");setetax_per_er(" ");setetax_amt_er(" ");seterror_msg(" ");
     setedate(" ");setevoucher_no(" ");setegstin(" ");setetype(" ");setetitle(" ");seteparticular(" ");seteamount(" ");setetotal(" ");setebank_id(" ");setediscount_per(" ");setediscount_amt(" ");
     setesgst_per(" ");setesgst_amt(" ");setecgst_per(" ");setecgst_amt(" ");seteigst_per(" ");seteigst_amt(" ");setetax_per(" ");setetax_amt(" ");
      document.getElementById("edit-form").style.display="none"; 
    document.getElementById("edit-success-card").style.display="block";  }

   //Cannot Update Transaction
   else{  seterror_msg("Cannot Update Transaction !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("Trno00");
   sessionStorage.removeItem("IncomeId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT INCOMEBOOK FUNCTION END ********************//





 
 //******************* DELETE INCOMEBOOK FUNCTION START ********************//
function deleteincomebook(){

  const incomeId=sessionStorage.getItem("DIncomeId00");
  const dtrno=sessionStorage.getItem("DTrno00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))&&((incomeId!=null)||(incomeId!=" "))&&((dtrno!=null)||(dtrno!=" "))){
 
 axios.post("bt-incomebook-delete", {
   Busy_id:busyid,
   Counter_id:counterid,
   Log_id:logid,
   Token:token,
   Income_id:incomeId,
   Tr_no:dtrno
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");
     sessionStorage.removeItem("DIncomeId00");
     sessionStorage.removeItem("DTrno00");

     window.location.replace("businesstools-login");
  }

  //Counter Deleted Successfully
   if(response.data.successvar==1){        

      document.getElementById("delete-card").style.display="none";
      document.getElementById("delete-success-card").style.display="block";  
      sessionStorage.removeItem("DIncomeId");
      sessionStorage.removeItem("DTrno00");

  }
  
      //Cannot Delete Counter
    else{  
    document.getElementById("delete-card").style.display="none";
    document.getElementById("delete-error-card").style.display="block";   }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");
   sessionStorage.removeItem("DIncomeId00");
   sessionStorage.removeItem("DTrno00");


   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* DELETE INCOMEBOOK FUNCTION END ********************//


 
function ecalculate(){
  
  //Fetching Values
  let eamount = (document.getElementById("eamount").value);if(eamount==""){ eamount=0;}else{ eamount=parseFloat(eamount);}
  let ediscount_per = (document.getElementById("ediscount_per").value);if( ediscount_per==""){ ediscount_per=0;}else{ ediscount_per=parseFloat(ediscount_per);}
  let ediscount_amt = (document.getElementById("ediscount_amt").value);if(ediscount_amt==""){ ediscount_amt=0;}else{ ediscount_amt=parseFloat(ediscount_amt);}
  let esgst_per = (document.getElementById("esgst_per").value);if( esgst_per==""){ esgst_per=0;}else{ esgst_per=parseFloat(esgst_per);}
  let esgst_amt = (document.getElementById("esgst_amt").value);if(esgst_amt==""){ esgst_amt=0;}else{ esgst_amt=parseFloat(esgst_amt);}
  let ecgst_per = (document.getElementById("ecgst_per").value);if( ecgst_per==""){ ecgst_per=0;}else{ ecgst_per=parseFloat(ecgst_per);}
  let ecgst_amt = (document.getElementById("ecgst_amt").value);if(ecgst_amt==""){ ecgst_amt=0;}else{ ecgst_amt=parseFloat(ecgst_amt);}
  let eigst_per = (document.getElementById("eigst_per").value);if( eigst_per==""){ eigst_per=0;}else{ eigst_per=parseFloat(eigst_per);}
  let eigst_amt = (document.getElementById("eigst_amt").value);if(eigst_amt==""){ eigst_amt=0;}else{ eigst_amt=parseFloat(eigst_amt);}
  let etax_per = (document.getElementById("etax_per").value);if( etax_per==""){ etax_per=0;}else{ etax_per=parseFloat(etax_per);}
  let etax_amt = (document.getElementById("etax_amt").value);if(etax_amt==""){ etax_amt=0;}else{ etax_amt=parseFloat(etax_amt);}

  let etotal=(eamount);
  if(eamount>0){
    //Discount Calculation
    if(ediscount_per>0){ediscount_amt = ((eamount)*(ediscount_per)/100);}
    let after_discount = eamount-ediscount_amt;
   //Gst and Tax Calculation
   if(esgst_per>0){esgst_amt = ((after_discount)*(esgst_per)/100);}
   if(ecgst_per>0){ecgst_amt = ((after_discount)*(ecgst_per)/100);}
   if(eigst_per>0){eigst_amt = ((after_discount)*(eigst_per)/100);}
   if(etax_per>0){etax_amt = ((after_discount)*(etax_per)/100);}
   
   //Setting Final Values to display
    etotal = (after_discount+esgst_amt+ecgst_amt+eigst_amt+etax_amt);setediscount_amt(ediscount_amt.toFixed(2));setesgst_amt(esgst_amt.toFixed(2));setecgst_amt(ecgst_amt.toFixed(2));seteigst_amt(eigst_amt.toFixed(2));setetax_amt(etax_amt.toFixed(2));setetotal(etotal.toFixed(2));

}
}
    



return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

      <Sidebar />
    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">

        

             {/* SUCCESS CARD START */}
             <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">New Transaction Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="businesstools-user-incomes"><button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* SUCCESS CARD END */}
                 {/* EDIT SUCCESS CARD START */}
             <div class="d-flex justify-content-center">
             <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Transaction Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT SUCCESS CARD END */}
               {/* EDIT ERROR CARD START */}
            <div class="d-flex justify-content-center">
            <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Fetch Data !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT ERROR CARD END */}

                {/* DELETE CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Are you sure you want to delete this transaction?</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> This action cannot be reversed. You will lose all your data related to this transaction if you wish to continue this action.</p>
                      <button class="mt-3 btn btn-outline-success" onClick={deleteincomebook}>Yes, I Know! Delete Transaction.</button>
                      <button class="mt-3 btn btn-outline-danger" style={{marginLeft:"1rem"}} onClick={()=>{  
                      //Cancel Delete and remove Income_id
                      sessionStorage.removeItem("DIncomeId00");     sessionStorage.removeItem("DTrno00");  document.getElementById('delete-card').style.display = 'none'; }}>No, Don't Delete Transaction.</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE CARD END */}
              {/* DELETE SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Transaction Deleted Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE SUCCESS CARD END */}
               {/*  DELETE ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Delete Transaction !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE ERROR CARD END */}

          <div class="content-wrapper pb-0">


         {/* CREATE INCOME FORM START */}
          <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form">
              <Create_income /> 
              </div>
          </div>
          {/* CREATE INCOME FORM END */}

            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Incomes</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                   {/*} <p class="m-0">ADE-00234</p> */}
                  </a>
                </div>
                <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i class="mdi mdi-plus-circle"></i> New Transaction </button>
              </div>
            </div>

   {/*} chart row starts here */}
   <div class="row">
                       {/* LINECHART START */}
                       <div className="col-sm-8 stretch-card grid-margin">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                    <div className="card-title"> Incomes
                      </div>                  
                      <div className="d-flex text-muted font-20">
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("lc-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("lc-date-search-box").style.display="none"; }}></i>                     
                      </div>
                    </div>
                    {/* LINE CHART DATE FILTER START */}
                    <div id="lc-date-search-box" style={{display:"none"}}>
                    <hr />
                    <p style={{marginTop:"2rem"}}>Make sure start date and end date are not more than 10 days apart for best results</p>
                    <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="lc_start_date" value={lc_start_date} onChange={updatelc_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="lc_end_date" value={lc_end_date} onChange={updatelc_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setlc_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* LINE CHART DATE FILTER END */}
                    <div className="line-chart-wrapper">
                  {/* CASH INFLOW OUTFLOW LINE CHART START */}
                  <Line data={data1} /> 
                  {/* CASH INFLOW OUTFLOW LINE CHART END */}                   
                    </div>
                  </div>
                </div>
              </div>
               {/* LINE CHART END */}
               {/* PIE CHART START */}
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Transactions Summary
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("pc-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("pc-date-search-box").style.display="none"; }}></i>
                      
                      </div>
                    </div>
                        {/*BAR CHART DATE FILTER START */}
                        <div id="pc-date-search-box" style={{display:"none"}}>
                    <hr />
                    <div className="row">
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="pc_start_date" value={pc_start_date} onChange={updatepc_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="pc_end_date" value={pc_end_date} onChange={updatepc_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setpc_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* BAR CHART DATE FILTER END */}
                       
                    
                   
             
                    <div class="bar-chart-wrapper">
                    <Pie data={data2} style={{width:"600px",height:"400px"}} maintainAspectRatio={false} />
                    </div>
                  </div>
                </div>
              </div>
               {/* PIE CHART END */}
        
            </div>


             {/* EDIT INCOME FORM START */}
          <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon"  onClick={()=>{ 
                         //close edit form button
                         document.getElementById("edit-form").style.display="none";
                        //Setting Variables to empty
                        //Setting Variables to empty 
                        setedate_er(" ");setevoucher_no_er(" ");setegstin_er(" ");setetype_er(" ");setetitle_er(" ");seteparticular_er(" ");seteamount_er(" ");setetotal_er(" ");setebank_id_er(" ");setediscount_per_er(" ");setediscount_amt_er(" ");
                        setesgst_per_er(" ");setesgst_amt_er(" ");setecgst_per_er(" ");setecgst_amt_er(" ");seteigst_per_er(" ");seteigst_amt_er(" ");setetax_per_er(" ");setetax_amt_er(" ");seterror_msg(" ");
                        setedate(" ");setevoucher_no(" ");setegstin(" ");setetype(" ");setetitle(" ");seteparticular(" ");seteamount(" ");setetotal(" ");setebank_id(" ");setediscount_per(" ");setediscount_amt(" ");
                        setesgst_per(" ");setesgst_amt(" ");setecgst_per(" ");setecgst_amt(" ");seteigst_per(" ");seteigst_amt(" ");setetax_per(" ");setetax_amt(" ");
                        sessionStorage.removeItem("Incomeid00");
                        sessionStorage.removeItem("Trno00"); }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Edit Income</h4>
                    <div class="form-sample">
                      <p class="card-description">edit your income details here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="edate" value={edate} onChange={updateedate} />
                              <code>{edate_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Voucher No.</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="VN87766" id="evoucher_no" value={evoucher_no} onChange={updateevoucher_no} />
                            <code>{evoucher_no_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">GSTIN</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="HGSSSFS55S4S4S4" id="egstin" value={egstin} onChange={updateegstin} />
                            <code>{egstin_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Title</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Regular Income" id="etitle" value={etitle} onChange={updateetitle} />
                            <code>{etitle_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="etype" value={etype} onChange={updateetype} >
                              <option>Select Type</option>
                              <option value="cash">Cash</option>
                              <option value="bank">Bank</option>
                              <option value="outstanding">Outstanding</option>
                              </select>
                              <code>{etype_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="ebank_id" value={ebank_id} onChange={updateebank_id} >
                              <option hidden={etype == "bank"}>Select Bank</option>
                              <option hidden={(etype == "cash")||(etype=="outstanding")} value={ebank_id}>{ebank_name}</option>
                              {
                              banks.map((curElm4,ind)=>{

                              return(
                              <>
                              <option value={curElm4.Bank_id}>{curElm4.Bank_name}</option>
                              </>
                              )
                              }
	 				   
	                         		)
                              }
                              </select>
                              <code>{ebank_id_er}</code>
                            </div>
                          </div>
                        </div>

                      </div>
                    
                   
                 
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="2000.00" id="eamount" value={eamount} onChange={updateeamount}  />
                            <code>{eamount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" style={{fontWeight:"bold"}} placeholder="1300.00" id="etotal" value={etotal} onChange={updateetotal}/>
                            <code>{etotal_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="ediscount_per" value={ediscount_per} onChange={updateediscount_per} />
                            <code>{ediscount_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1000.00" id="ediscount_amt" value={ediscount_amt} onChange={updateediscount_amt} />
                            <code>{ediscount_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">SGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="05.00" id="esgst_per" value={esgst_per} onChange={updateesgst_per} />
                              <code>{esgst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">SGST Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="esgst_amt" value={esgst_amt} onChange={updateesgst_amt} />
                              <code>{esgst_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">CGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="05.00" id="ecgst_per" value={ecgst_per} onChange={updateecgst_per} />
                              <code>{ecgst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">CGST Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="ecgst_amt" value={ecgst_amt} onChange={updateecgst_amt} />
                              <code>{ecgst_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IGST %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="05.00" id="eigst_per" value={eigst_per} onChange={updateeigst_per}/>
                              <code>{eigst_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IGST Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="eigst_amt" value={eigst_amt} onChange={updateeigst_amt} />
                              <code>{eigst_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Tax %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="05.00" id="etax_per" value={etax_per} onChange={updateetax_per}/>
                              <code>{etax_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Tax Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50.00" id="etax_amt" value={etax_amt} onChange={updateetax_amt} />
                              <code>{etax_amt_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <div class="form-group">
                        <label for="exampleTextarea1">Particular</label>
                        <textarea  class="form-control" rows="2" id="eparticular" value={eparticular} onChange={updateeparticular} ></textarea>
                        <code>{eparticular_er}</code>
                      </div>

                        
                      
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={editincome}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* EDIT INCOME FORM END */}

            {/* INCOME TRANSCATIONS LIST START */}
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Income Transactions</h4>
                    <p class="card-description">income transaction details
                    </p>

                       {/* FILTER SECTION START */}
                       <hr/>
                            <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Start Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control" id="filter_start_date" value={filter_start_date} onChange={updatefilter_start_date}  />
                              <code>{filter_start_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">End Date</label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control"  id="filter_end_date" value={filter_end_date} onChange={updatefilter_end_date}  />
                              <code>{filter_end_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Tr No.</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="12345"   id="filter_trno" value={filter_trno} onChange={updatefilter_trno}  />
                              <code>{filter_trno_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Voucher No.</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="VN123456" id="filter_voucher_no" value={filter_voucher_no} onChange={updatefilter_voucher_no}  />
                              <code>{filter_voucher_no_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">GSTIN</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="GST838833333"  id="filter_gstin" value={filter_gstin} onChange={updatefilter_gstin}  />
                              <code>{filter_gstin_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Title</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="New Income"   id="filter_title" value={filter_title} onChange={updatefilter_title}  />
                              <code>{filter_title_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Amount</label>
                            <div class="col-sm-4">
                              <select class="form-control" style={{padding:"1rem"}} id="filter_amount_sign" value={filter_amount_sign} onChange={updatefilter_amount_sign}  >
                              <option className="dropdown-item" value=" ">All</option>
                              <option className="dropdown-item" value="equals">equals</option>
                              <option className="dropdown-item" value="more than" >more than</option>
                              <option className="dropdown-item" value="less than">less than</option>
                              <option className="dropdown-item" value="more than or equals" >more than or equals</option>
                              <option className="dropdown-item" value="less than or equals" >less than or equals</option>
                              </select>
                              <code>{filter_amount_sign_er}</code>
                            </div>
                            <div class="col-sm-4">
                              <input type="text" class="form-control" placeholder="5000.00" id="filter_amount" value={filter_amount} onChange={updatefilter_amount}   />
                              <code>{filter_amount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Type</label>
                            <div class="col-sm-8">
                             <select class="form-control" style={{padding:"1rem"}} id="filter_type" value={filter_type} onChange={updatefilter_type} >
                             <option value=" ">Select Type</option>
                             <option value="cash">cash</option>
                             <option value="bank">bank</option>
                             <option value="outstanding">outstanding</option>
                           
                             </select>                              
                             <code>{filter_type_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                      <div class="col-md-8">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Total</label>
                            <div class="col-sm-4">
                              <select class="form-control" style={{padding:"1rem"}} id="filter_total_sign" value={filter_total_sign} onChange={updatefilter_total_sign}  >
                              <option className="dropdown-item" value=" ">All</option>
                              <option className="dropdown-item" value="equals">equals</option>
                              <option className="dropdown-item" value="more than" >more than</option>
                              <option className="dropdown-item" value="less than">less than</option>
                              <option className="dropdown-item" value="more than or equals" >more than or equals</option>
                              <option className="dropdown-item" value="less than or equals" >less than or equals</option>
                              </select>
                              <code>{filter_total_sign_er}</code>
                            </div>
                            <div class="col-sm-4">
                              <input type="text" class="form-control" placeholder="5000.00" id="filter_total" value={filter_total} onChange={updatefilter_total}   />
                              <code>{filter_total_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                         
                          <button class="btn btn-primary" onClick={()=>{ setfilter_val(1); getData(); }}>Apply Filters</button>
                         <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val(0); getData(); }}>Remove Filters</button>
                       
                     
                        </div>
                      </div>
                    
                      <div style={{marginBottom:"1rem"}}>
                  
                       
                      </div>
                            <hr/>                      
                            </div>
                            
                            {/* FILTER SECTION END */}

                    <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                            <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                            <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tr No.</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Voucher No.</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">GSTIN</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Type</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Title</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Particular</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Amount</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Discount %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Discount Amount</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 12 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">SGST %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c12_yes" onClick={()=>{setcd_col12_val(1); document.getElementById("cd_c12_yes").style.display="none"; document.getElementById("cd_c12_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c12_no" style={{ display:"none"}} onClick={()=>{ setcd_col12_val(0); document.getElementById("cd_c12_yes").style.display="block"; document.getElementById("cd_c12_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 13 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">SGST Amount</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c13_yes" onClick={()=>{setcd_col13_val(1); document.getElementById("cd_c13_yes").style.display="none"; document.getElementById("cd_c13_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c13_no" style={{ display:"none"}} onClick={()=>{ setcd_col13_val(0); document.getElementById("cd_c13_yes").style.display="block"; document.getElementById("cd_c13_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 14 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">CGST %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c14_yes" onClick={()=>{setcd_col14_val(1); document.getElementById("cd_c14_yes").style.display="none"; document.getElementById("cd_c14_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c14_no" style={{ display:"none"}} onClick={()=>{ setcd_col14_val(0); document.getElementById("cd_c14_yes").style.display="block"; document.getElementById("cd_c14_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 15 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">CGST Amount</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c15_yes" onClick={()=>{setcd_col15_val(1); document.getElementById("cd_c15_yes").style.display="none"; document.getElementById("cd_c15_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c15_no" style={{ display:"none"}} onClick={()=>{ setcd_col15_val(0); document.getElementById("cd_c15_yes").style.display="block"; document.getElementById("cd_c15_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 16 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">IGST %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c16_yes" onClick={()=>{setcd_col16_val(1); document.getElementById("cd_c16_yes").style.display="none"; document.getElementById("cd_c16_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c16_no" style={{ display:"none"}} onClick={()=>{ setcd_col16_val(0); document.getElementById("cd_c16_yes").style.display="block"; document.getElementById("cd_c16_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 17 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">IGST Amount</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c17_yes" onClick={()=>{setcd_col17_val(1); document.getElementById("cd_c17_yes").style.display="none"; document.getElementById("cd_c17_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c17_no" style={{ display:"none"}} onClick={()=>{ setcd_col17_val(0); document.getElementById("cd_c17_yes").style.display="block"; document.getElementById("cd_c17_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 18 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tax %</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c18_yes" onClick={()=>{setcd_col18_val(1); document.getElementById("cd_c18_yes").style.display="none"; document.getElementById("cd_c18_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c18_no" style={{ display:"none"}} onClick={()=>{ setcd_col18_val(0); document.getElementById("cd_c18_yes").style.display="block"; document.getElementById("cd_c18_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 19 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tax Amount</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c19_yes" onClick={()=>{setcd_col19_val(1); document.getElementById("cd_c19_yes").style.display="none"; document.getElementById("cd_c19_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c19_no" style={{ display:"none"}} onClick={()=>{ setcd_col19_val(0); document.getElementById("cd_c19_yes").style.display="block"; document.getElementById("cd_c19_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 20 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Total</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c20_yes" onClick={()=>{setcd_col20_val(1); document.getElementById("cd_c20_yes").style.display="none"; document.getElementById("cd_c20_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c20_no" style={{ display:"none"}} onClick={()=>{ setcd_col20_val(0); document.getElementById("cd_c20_yes").style.display="block"; document.getElementById("cd_c20_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 21 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated By</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c21_yes" onClick={()=>{setcd_col21_val(1); document.getElementById("cd_c21_yes").style.display="none"; document.getElementById("cd_c21_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c21_no" style={{ display:"none"}} onClick={()=>{ setcd_col21_val(0); document.getElementById("cd_c21_yes").style.display="block"; document.getElementById("cd_c21_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 22 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated At</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c22_yes" onClick={()=>{setcd_col22_val(1); document.getElementById("cd_c22_yes").style.display="none"; document.getElementById("cd_c22_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c22_no" style={{ display:"none"}} onClick={()=>{ setcd_col22_val(0); document.getElementById("cd_c22_yes").style.display="block"; document.getElementById("cd_c22_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 23 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c23_yes" onClick={()=>{setcd_col23_val(1); document.getElementById("cd_c23_yes").style.display="none"; document.getElementById("cd_c23_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c23_no" style={{ display:"none"}} onClick={()=>{ setcd_col23_val(0); document.getElementById("cd_c23_yes").style.display="block"; document.getElementById("cd_c23_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 24 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-danger">Delete</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c24_yes" onClick={()=>{setcd_col24_val(1); document.getElementById("cd_c24_yes").style.display="none"; document.getElementById("cd_c24_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c24_no" style={{ display:"none"}} onClick={()=>{ setcd_col24_val(0); document.getElementById("cd_c24_yes").style.display="block"; document.getElementById("cd_c24_no").style.display="none"; }}></i>
                              </div></div></li> 
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>

                    <div class="table-responsive" id="reports" ref={componentRef}>
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Sn</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Tr No.</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1} >Voucher No.</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1} >Date</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col5_val==1} >GSTIN</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Type</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1} >Title</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col8_val==1} >Particular</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1} >Amount</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col10_val==1} >Discount %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col11_val==1} >Discount Amount</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col12_val==1} >SGST %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col13_val==1} >SGST Amount</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col14_val==1} >CGST %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col15_val==1} >CGST Amount</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col16_val==1} >IGST %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col17_val==1} >IGST Amount</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col18_val==1} >Tax %</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col19_val==1} >Tax Amount</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col20_val==1} >Total</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col21_val==1} >Updated By</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col22_val==1} >Updated At</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col23_val==1} ></th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col24_val==1} ></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                       
                        <tr key={curElm.Id}>
                            <td hidden={(logtype == "user")||(cd_col1_val == 1)}>{curElm.Admin_sn}</td>
                            <td hidden={(logtype == "admin")||(cd_col1_val == 1)}>{curElm.User_sn}</td>
                            <td hidden={cd_col2_val==1}  >{curElm.Tr_no}</td>
                            <td hidden={cd_col3_val==1}  >{curElm.Voucher_no}</td>
                            <td hidden={cd_col4_val==1}  >{curElm.Date}</td>
                            <td hidden={cd_col5_val==1}  >{curElm.GSTIN}</td>
                            <td hidden={cd_col6_val==1}  ><label class="badge badge-success" hidden={(curElm.Type == "bank")||(curElm.Type == "outstanding")}>{curElm.Type}</label>
                            <label class="badge badge-primary" hidden={(curElm.Type == "cash")||(curElm.Type== "outstanding")}>{curElm.Type} ({curElm.Bank_name})</label>
                            <label class="badge badge-warning" hidden={(curElm.Type == "cash")||(curElm.Type== "bank")}>{curElm.Type}</label></td>
                            <td hidden={cd_col7_val==1}  >{curElm.Title}</td>
                            <td hidden={cd_col8_val==1}  >{curElm.Particular}</td>
                            <td hidden={cd_col9_val==1}  >{curElm.Amount}</td>
                            <td hidden={cd_col10_val==1}  >{curElm.Dis_per}</td>
                            <td hidden={cd_col11_val==1}  >{curElm.Dis_amt}</td>
                            <td hidden={cd_col12_val==1}  >{curElm.Sgst_per}</td>
                            <td hidden={cd_col13_val==1}  >{curElm.Sgst_amt}</td>
                            <td hidden={cd_col14_val==1}  >{curElm.Cgst_per}</td>
                            <td hidden={cd_col15_val==1}  >{curElm.Cgst_amt}</td>
                            <td hidden={cd_col16_val==1}  >{curElm.Igst_per}</td>
                            <td hidden={cd_col17_val==1}  >{curElm.Igst_amt}</td>
                            <td hidden={cd_col18_val==1}  >{curElm.Tax_per}</td>
                            <td hidden={cd_col19_val==1}  >{curElm.Tax_amt}</td>
                            <td hidden={cd_col20_val==1}  >{curElm.Total}</td>
                            <td hidden={cd_col21_val==1}  >{curElm.Updated_by}</td>
                            <td hidden={cd_col22_val==1}  >{curElm.Date_updated} ({curElm.Time_updated})</td>
                            <td hidden={cd_col23_val==1}  ><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Income Id and Redirect to Edit
                      sessionStorage.setItem("Incomeid00",curElm.Id);sessionStorage.setItem("Trno00",curElm.Tr_no);getincomedatabyId();document.getElementById('edit-form').style.display = 'block'; }}></i></td>
                            <td hidden={cd_col24_val==1}  ><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Income Id for Delete
                      sessionStorage.setItem("DIncomeId00",curElm.Id);      sessionStorage.setItem("DTrno00",curElm.Tr_no); document.getElementById('delete-card').style.display = 'block'; }}></i></td>

                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                          
                         
                        </tbody>
                      </table>
                    </div>
                          {/* PAGINATION SECTION START */}
                          <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel}><i class="mdi mdi-file-excel text-dark"></i></button>

                  </div>
                </div>
              </div>
              {/* INCOME TRANSCATIONS LIST END */}
            

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
         {/*} <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);


}
}
export default Userincomes;