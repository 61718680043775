import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';


import '../Resources/dashboard.css';
import Navbar from '../navbar';
import Sidebar from '../Sidebar';
import Create_business from './create_business.js';
import Edit_business from './edit_business.js';

import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";

function Businesshome(){

    
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logtype=sessionStorage.getItem("logType00");

 //Checking if Sessions are Set

// alert(logstatus,loguser,logadminid);
 //Sessions Not Set
{/* if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))){
  
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   
 
    window.location.replace("/businesstools-login");
   }
  
   //Sessions Set
  else{ */}
    
//Variables
const[data,setData] = useState([]);//SetData Variable
const[transaction_history,settransaction_history] = useState([]);//Settransaction_history Variable

//Error Variable
const[errorvar,seterrorvar] = useState();
    

 //SETTING DEFAULT STATE VARIABLES FOR CHART */
 
 //LINECHART VARIABLES
 const[lc_summary,setlc_summary] = useState([],);
 const[lc_data,setlc_data] = useState([],);

 //Line Chart
 const[lc_date,setlc_date] = useState();
 const[lc_default,setlc_default] = useState(0);
 const[lc_start_date,setlc_start_date] = useState();const updatelc_start_date = (event) => { setlc_start_date(event.target.value); }; 
 const[lc_end_date,setlc_end_date] = useState();const updatelc_end_date = (event) => { setlc_end_date(event.target.value); }; 
 const[lc_date_error,setlc_date_error] = useState();
    

 //Bar Chart
 const[bc_default,setbc_default] = useState(0);
 const[bc_start_date,setbc_start_date] = useState();const updatebc_start_date = (event) => { setbc_start_date(event.target.value); }; 
 const[bc_end_date,setbc_end_date] = useState();const updatebc_end_date = (event) => { setbc_end_date(event.target.value); }; 
 const[bc_business,setbc_business] = useState();
 const[bc_data,setbc_data] = useState();

 
//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);
   
   useEffect(()=>{
   
    getData();
    
    },[])
   

  
//******************* GET BUSINESS DATA FUNCTION START  ********************//
function getData(){
 

    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
   
   
   axios.post("bt-business-default", {
     Log_id:logid,
     Token:token,
     Sn_start :0,
     Sn_end :0,
     Sn_total :0,
     Sn_limit :0,
       //Line Chart
    lc_default:lc_default,
    lc_start_date:moment(lc_start_date).format('YYYY-MM-DD'),
    lc_end_date:moment(lc_end_date).format('YYYY-MM-DD'),
    //Bar Chart
    bc_default:bc_default,
    bc_start_date:moment(bc_start_date).format('YYYY-MM-DD'),
    bc_end_date:moment(bc_end_date).format('YYYY-MM-DD'),
     
    //Code Edits for pagination
    'page' : pageNumber
   })
   .then((response) => {


    //Invalid Session
    if((response.data.error)==100){ 
        //Remove Session Variables and redirect to login
       sessionStorage.removeItem("logStatus00");
       sessionStorage.removeItem("logId00");
       sessionStorage.removeItem("logToken00");

       window.location.replace("businesstools-login");
    }
    //Success Data Fetched
    if(response.data.successvar==1){
     //Setting Data
     setData(response.data.data);
     settransaction_history(response.data.transactions_history.data);

     const lc_summary = response.data.lc_summary;
     // Extract data for line chart
     setlc_date(lc_summary.map((entry) => entry.Date_created));
     setlc_data(lc_summary.map((entry) => entry.Transaction_counts));

     const bc_summary = response.data.bc_summary;
     // Extract data for line chart
     setbc_business(bc_summary.map((entry) => entry.Business_name));
     setbc_data(bc_summary.map((entry) => entry.Transaction_counts)); 

    /* setdatacount(response.data.datacount);
     setloadcount(response.data.loadcount);
     setloadcount(1); */
    }
    //Cannot Fetch Data
    else{
     seterrorvar("Cannot Fetch Data !");
    }
   
   
   });
   
   }
   //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("businesstools-login");
   
    }
   
   }
    
   //******************* GET BUSINESS DATA FUNCTION END  ********************//


    
 /* LINE CHART START */
 const data1 = {
  labels: lc_date,
  datasets: [
    {
      label: "Transactions",
      backgroundColor: ["rgb(54, 160, 255)" ],
      borderColor: "rgb(54, 160, 255)",
      data: lc_data,
    },
   
  ],
};
/* LINE CHART END */ 


 /* BAR CHART VARIABLES START */
 const data2 = {
   labels: bc_business,
   datasets: [
     {
       label: [],
       backgroundColor: ["#25d494", "#d42641", "#26cdd4", "#d45e26", "#010a9a", "#d943b9", "#d0d943", "#d98843", "#5c108f", "#828f10", "#86efff", "#ffe585" ],
       borderColor: "rgb(255, 99, 132)",
       data: bc_data,
     },
    
   ],
 }; 
 /* BAR CHART VARIABLES END */

  
//******************* GET BUSINESS DATA BY ID FUNCTION START  ********************//


//ebusiness_name
const[ebusiness_name,setebusiness_name]=useState();const updateebusiness_name = (event) => { setebusiness_name(event.target.value);}; const[ebusiness_name_er,setebusiness_name_er]=useState();
//ebusiness_type
const[ebusiness_type,setebusiness_type]=useState();const updateebusiness_type = (event) => { setebusiness_type(event.target.value);}; const[ebusiness_type_er,setebusiness_type_er]=useState();
//ebusiness_email
const[ebusiness_email,setebusiness_email]=useState();const updateebusiness_email = (event) => { setebusiness_email(event.target.value);};  const[ebusiness_email_er,setebusiness_email_er]=useState();
//ebusiness_contact
const[ebusiness_contact,setebusiness_contact]=useState();const updateebusiness_contact = (event) => { setebusiness_contact(event.target.value);};  const[ebusiness_contact_er,setebusiness_contact_er]=useState();
//ebusiness_whatsapp
const[ebusiness_whatsapp,setebusiness_whatsapp]=useState();const updateebusiness_whatsapp = (event) => { setebusiness_whatsapp(event.target.value);};  const[ebusiness_whatsapp_er,setebusiness_whatsapp_er]=useState();
//ebusiness_address
const[ebusiness_address,setebusiness_address]=useState();const updateebusiness_address = (event) => { setebusiness_address(event.target.value);}; const[ebusiness_address_er,setebusiness_address_er]=useState();
//ebusiness_city
const[ebusiness_city,setebusiness_city]=useState();const updateebusiness_city = (event) => { setebusiness_city(event.target.value);}; const[ebusiness_city_er,setebusiness_city_er]=useState();
//ebusiness_state
const[ebusiness_state,setebusiness_state]=useState();const updateebusiness_state = (event) => { setebusiness_state(event.target.value);}; const[ebusiness_state_er,setebusiness_state_er]=useState();
//ebusiness_country
const[ebusiness_country,setebusiness_country]=useState();const updateebusiness_country = (event) => { setebusiness_country(event.target.value);}; const[ebusiness_country_er,setebusiness_country_er]=useState();
//ebusiness_pincode
const[ebusiness_pincode,setebusiness_pincode]=useState();const updateebusiness_pincode = (event) => { setebusiness_pincode(event.target.value);}; const[ebusiness_pincode_er,setebusiness_pincode_er]=useState();
//ebusiness_doe
const[ebusiness_doe,setebusiness_doe]=useState();const updateebusiness_doe = (event) => { setebusiness_doe(event.target.value);}; const[ebusiness_doe_er,setebusiness_doe_er]=useState();
//ebusiness_website
const[ebusiness_website,setebusiness_website]=useState();const updateebusiness_website = (event) => { setebusiness_website(event.target.value);}; const[ebusiness_website_er,setebusiness_website_er]=useState();
//ebusiness_gstin
const[ebusiness_gstin,setebusiness_gstin]=useState();const updateebusiness_gstin = (event) => { setebusiness_gstin(event.target.value);}; const[ebusiness_gstin_er,setebusiness_gstin_er]=useState();
//ebusiness_licenseno
const[ebusiness_licenseno,setebusiness_licenseno]=useState();const updateebusiness_licenseno = (event) => { setebusiness_licenseno(event.target.value);}; const[ebusiness_licenseno_er,setebusiness_licenseno_er]=useState();
//ebusiness_sector
const[ebusiness_sector,setebusiness_sector]=useState();const updateebusiness_sector = (event) => { setebusiness_sector(event.target.value);}; const[ebusiness_sector_er,setebusiness_sector_er]=useState();
//ebusiness_industry
const[ebusiness_industry,setebusiness_industry]=useState();const updateebusiness_industry = (event) => { setebusiness_industry(event.target.value);}; const[ebusiness_industry_er,setebusiness_industry_er]=useState();
//ebusiness_category
const[ebusiness_category,setebusiness_category]=useState();const updateebusiness_category = (event) => { setebusiness_category(event.target.value);}; const[ebusiness_category_er,setebusiness_category_er]=useState();
//ebusiness_about
const[ebusiness_about,setebusiness_about]=useState();const updateebusiness_about = (event) => { setebusiness_about(event.target.value);}; const[ebusiness_about_er,setebusiness_about_er]=useState();
//ebusiness_details
const[ebusiness_details,setebusiness_details]=useState();const updateebusiness_details = (event) => { setebusiness_details(event.target.value);}; const[ebusiness_details_er,setebusiness_details_er]=useState();



//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();


function getbusinessdatabyId(){
 
  const busy_id=sessionStorage.getItem("BusyId00");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(busy_id>0)){
 
 
 axios.post("bt-business-details-byid", {
   Log_id:logid,
   Token:token,
   Busy_id:busy_id,
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data
   setebusiness_name(response.data.business_name);
   setebusiness_type(response.data.type);
   setebusiness_email(response.data.email);
   setebusiness_contact(response.data.contact);
   setebusiness_whatsapp(response.data.whatsapp);
   setebusiness_website(response.data.website);
   setebusiness_address(response.data.address);
   setebusiness_city(response.data.city);
   setebusiness_state(response.data.state);
   setebusiness_country(response.data.country);
   setebusiness_pincode(response.data.pincode);
   setebusiness_gstin(response.data.gstin);
   setebusiness_licenseno(response.data.license_no);
   setebusiness_industry(response.data.industry);
   setebusiness_sector(response.data.sector);
   setebusiness_category(response.data.category);
   setebusiness_about(response.data.about);
   setebusiness_details(response.data.details);
   setebusiness_doe(response.data.doe);

 
  }
  //Cannot Fetch Data
  else{


      document.getElementById("edit-form").style.display="none";

     //Setting Variables to empty 
     setebusiness_name(" ");setebusiness_type(" ");setebusiness_email(" ");setebusiness_contact(" ");setebusiness_address(" ");setebusiness_city(" ");setebusiness_state(" ");setebusiness_country(" ");setebusiness_pincode(" ");
     setebusiness_doe(" ");setebusiness_website(" ");setebusiness_sector(" ");setebusiness_industry(" ");setebusiness_category(" ");setebusiness_gstin(" ");setebusiness_licenseno(" ");setebusiness_about(" ");setebusiness_details(" ");
     seterror_msg(" ");
     setebusiness_name_er(" ");setebusiness_type_er(" ");setebusiness_email_er(" ");setebusiness_contact_er(" ");setebusiness_address_er(" ");setebusiness_city_er(" ");setebusiness_state_er(" ");setebusiness_country_er(" ");setebusiness_pincode_er(" ");
     setebusiness_doe_er(" ");setebusiness_website_er(" ");setebusiness_sector_er(" ");setebusiness_industry_er(" ");setebusiness_category_er(" ");setebusiness_gstin_er(" ");setebusiness_licenseno_er(" ");setebusiness_about_er(" ");setebusiness_details_er(" ");
     seterror_msg(" ");

      document.getElementById("edit-error-card").style.display="block"; 
      sessionStorage.removeItem("BusyId00");
  }
 
 
 });
 
 }
 //User not Logedin
 else{

     //Checking if user loggedin
     if(((logstatus==null)||(logstatus==""))||((logid==null)||(logid==""))||((token==null)||(token==" "))){
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");

   window.location.replace("businesstools-login");
     }
 
  }
 
 }
  
 //******************* GET BUSINESS DATA BY ID FUNCTION END  ********************//





//******************* EDIT BUSINESS FUNCTION START ********************//
function editbusiness(){

  const busy_id=sessionStorage.getItem("BusyId00");


  //Setting Error Variables
setebusiness_name_er(" ");setebusiness_type_er(" ");setebusiness_email_er(" ");setebusiness_contact_er(" ");setebusiness_address_er(" ");setebusiness_city_er(" ");setebusiness_state_er(" ");setebusiness_country_er(" ");setebusiness_pincode_er(" ");
setebusiness_doe_er(" ");setebusiness_website_er(" ");setebusiness_sector_er(" ");setebusiness_industry_er(" ");setebusiness_category_er(" ");setebusiness_gstin_er(" ");setebusiness_licenseno_er(" ");setebusiness_about_er(" ");setebusiness_details_er(" ");
seterror_msg(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busy_id!=null)||(busy_id!=" "))){
 
   //Adjusting empty variables for input
   if(ebusiness_address==" "){ setebusiness_address("-");}if(ebusiness_website==" "){ setebusiness_website("-"); }if(ebusiness_about==" "){ setebusiness_about("-"); }if(ebusiness_details==" "){ setebusiness_details("-"); } 
 
 axios.post("bt-business-edit", {
   Busy_id:busy_id,
   Log_id:logid,
   Token:token,
   Business_name : ebusiness_name,
   Email : ebusiness_email,
   Contact : ebusiness_contact,
   Whatsapp : ebusiness_whatsapp,
   Address : ebusiness_address,
   City : ebusiness_city,
   State : ebusiness_state,
   Country : ebusiness_country, 
   Pincode : ebusiness_pincode,
   Google_profile : "-",
   Logo : "-",
   Gstin : ebusiness_gstin,
   License_no : ebusiness_licenseno,
   Website : ebusiness_website,
   Type : ebusiness_type,
   Category : ebusiness_category,
   Industry : ebusiness_industry,
   Sector : ebusiness_sector,
   About : ebusiness_about,
   Details : ebusiness_details,
   Doe : moment(ebusiness_doe).format('YYYY-MM-DD'),
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 if(response.data.validatorerror){
//  setebusiness_name_er(response.data.validatorerror.Business_name);
  setebusiness_email_er(response.data.validatorerror.Email);
  setebusiness_contact_er(response.data.validatorerror.Contact);
  setebusiness_whatsapp_er(response.data.validatorerror.Whatsapp);
  setebusiness_address_er(response.data.validatorerror.Address);
  setebusiness_city_er(response.data.validatorerror.City);
  setebusiness_state_er(response.data.validatorerror.State);
  setebusiness_country_er(response.data.validatorerror.Country);
  setebusiness_pincode_er(response.data.validatorerror.Pincode);
  setebusiness_gstin_er(response.data.validatorerror.Gstin);
  setebusiness_website_er(response.data.validatorerror.Website);
  setebusiness_licenseno_er(response.data.validatorerror.License_no);
  setebusiness_type_er(response.data.validatorerror.Type);
  setebusiness_category_er(response.data.validatorerror.Category);
  setebusiness_industry_er(response.data.validatorerror.Industry);
  setebusiness_sector_er(response.data.validatorerror.Sector);
  setebusiness_about_er(response.data.validatorerror.About);
  setebusiness_details_er(response.data.validatorerror.Details);
  setebusiness_doe_er(response.data.validatorerror.Doe);

}
//Invalid Entries
if(response.data.error==102){ 
  
//  setebusiness_name_er(response.data.business_name_er);
  setebusiness_email_er(response.data.email_er);
  setebusiness_contact_er(response.data.contact_er);
  setebusiness_whatsapp_er(response.data.whatsapp_er);
  setebusiness_address_er(response.data.address_er);
  setebusiness_city_er(response.data.city_er);
  setebusiness_state_er(response.data.state_er);
  setebusiness_country_er(response.data.country_er);
  setebusiness_pincode_er(response.data.pincode_er);
  setebusiness_gstin_er(response.data.gstin_er);
  setebusiness_website_er(response.data.website_er);
  setebusiness_licenseno_er(response.data.license_no_er);
  setebusiness_type_er(response.data.type_er);
  setebusiness_category_er(response.data.category_er);
  setebusiness_industry_er(response.data.industry_er);
  setebusiness_sector_er(response.data.sector_er);
  setebusiness_about_er(response.data.about_er);
  setebusiness_details_er(response.data.details_er);
  setebusiness_doe_er(response.data.doe_er);
  seterror_msg("Cannot Update Business Information!");  }


  //eBusiness Name Already Exists
//  if(response.data.error==103){     seterror_msg("Business Name Already Exists!");  }

  //Business Editted Successfully
   if(response.data.successvar==1){        

      document.getElementById("edit-form").style.display="none";
      document.getElementById("edit-success-card").style.display="block";  

      //Setting Variables to empty 
      setebusiness_name(" ");setebusiness_type(" ");setebusiness_email(" ");setebusiness_contact(" ");setebusiness_address(" ");setebusiness_city(" ");setebusiness_state(" ");setebusiness_country(" ");setebusiness_pincode(" ");
      setebusiness_doe(" ");setebusiness_website(" ");setebusiness_sector(" ");setebusiness_industry(" ");setebusiness_category(" ");setebusiness_gstin(" ");setebusiness_licenseno(" ");setebusiness_about(" ");setebusiness_details(" ");
      seterror_msg(" ");
      setebusiness_name_er(" ");setebusiness_type_er(" ");setebusiness_email_er(" ");setebusiness_contact_er(" ");setebusiness_address_er(" ");setebusiness_city_er(" ");setebusiness_state_er(" ");setebusiness_country_er(" ");setebusiness_pincode_er(" ");
      setebusiness_doe_er(" ");setebusiness_website_er(" ");setebusiness_sector_er(" ");setebusiness_industry_er(" ");setebusiness_category_er(" ");setebusiness_gstin_er(" ");setebusiness_licenseno_er(" ");setebusiness_about_er(" ");setebusiness_details_er(" ");
      seterror_msg(" ");
      sessionStorage.removeItem("BusyId00");

  }
  
      //Cannot Edit Business
   else{  seterror_msg("Cannot Update Business Information !"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT BUSINESS FUNCTION END ********************//



   
//******************* DELETE BUSINESS FUNCTION START ********************//
function deletebusiness(){

  const busyId=sessionStorage.getItem("DBusyId00")
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyId!=null)||(busyId!=" "))){
 
 axios.post("bt-business-delete", {
   Busy_id:busyId,
   Log_id:logid,
   Token:token,
 })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("businesstools-login");
  }

  //Business Deleted Successfully
   if(response.data.successvar==1){        

      document.getElementById("delete-card").style.display="none";
      document.getElementById("delete-success-card").style.display="block";  
      sessionStorage.removeItem("DBusyId");

  }
  
      //Cannot Delete Business
    else{  
    document.getElementById("delete-card").style.display="none";
    document.getElementById("delete-error-card").style.display="block";   }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* DELETE BUSINESS FUNCTION END ********************//



return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">

         {/* SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">New Business Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="businesstools-businesses"><button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* SUCCESS CARD END */}
                   {/* EDIT SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Business Information Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT SUCCESS CARD END */}
               {/* EDIT ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Fetch Data !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT ERROR CARD END */}
               {/* DELETE CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Are you sure you want to delete this business?</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> This action cannot be reversed. You will lose all your data related to this business if you wish to continue this action.</p>
                      <button class="mt-3 btn btn-outline-success" onClick={deletebusiness}>Yes, I Know! Delete Business.</button>
                      <button class="mt-3 btn btn-outline-danger" style={{marginLeft:"1rem"}} onClick={()=>{  
                      //Cancel Delete and remove Busy_id
                      sessionStorage.removeItem("DBusyId00");document.getElementById('delete-card').style.display = 'none'; }}>No, Don't Delete Business.</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE CARD END */}
                           {/* DELETE SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Business Deleted Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-success-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* DELETE SUCCESS CARD END */}
               {/* EDIT ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="delete-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Delete Business !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("delete-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* EDIT ERROR CARD END */}

          <div class="content-wrapper pb-0">

         
          {/* BUSINESS CREATE FORM START */}
          <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form">
              <Create_business /> 
              </div>
          </div>
          {/* BUSINESS CREATE FORM START */}


            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Businesses Dashboard</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    {/*<p class="m-0">ADE-00234</p>*/}
                  </a>
                </div>
                <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" hidden={logtype=="user"}  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i class="mdi mdi-plus-circle"></i> Add New Business </button>
              </div>
            </div>
            {/*} first row starts here */}
       {/*}     <div class="row">
              <div class="col-xl-9 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div>
                        <div class="card-title mb-0">Sales Revenue</div>
                        <h3 class="font-weight-bold mb-0">$32,409</h3>
                      </div>
                      <div>
                        <div class="d-flex flex-wrap pt-2 justify-content-between sales-header-right">
                          <div class="d-flex me-5">
                            <button type="button" class="btn btn-social-icon btn-outline-sales">
                              <i class="mdi mdi-inbox-arrow-down"></i>
                            </button>
                            <div class="ps-2">
                              <h4 class="mb-0 font-weight-semibold head-count"> $8,217 </h4>
                              <span class="font-10 font-weight-semibold text-muted">TOTAL SALES</span>
                            </div>
                          </div>
                          <div class="d-flex me-3 mt-2 mt-sm-0">
                            <button type="button" class="btn btn-social-icon btn-outline-sales profit">
                              <i class="mdi mdi-cash text-info"></i>
                            </button>
                            <div class="ps-2">
                              <h4 class="mb-0 font-weight-semibold head-count"> 2,804 </h4>
                              <span class="font-10 font-weight-semibold text-muted">TOTAL PROFIT</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="text-muted font-13 mt-2 mt-sm-0"> Your sales monitoring dashboard template. <a class="text-muted font-13" href="#"><u>Learn more</u></a>
                    </p>
                    <div class="flot-chart-wrapper">
                      <div id="flotChart" class="flot-chart">
                        <canvas class="flot-base"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 stretch-card grid-margin">
                <div class="card card-img">
                  <div class="card-body d-flex align-items-center">
                    <div class="text-white">
                      <h1 class="font-20 font-weight-semibold mb-0"> Get premium </h1>
                      <h1 class="font-20 font-weight-semibold">account!</h1>
                      <p>to optimize your selling prodcut</p>
                      <p class="font-10 font-weight-semibold"> Enjoy the advantage of premium. </p>
                      <button class="btn bg-white font-12">Get Premium</button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/*} chart row starts here */}
            <div class="row">
              {/* LINE CHART START */}
              <div className="col-sm-6 stretch-card grid-margin">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                    <div className="card-title"> Transactions
                      </div>
                   
                      <div className="d-flex text-muted font-20">
                      {/*  <i className="mdi mdi-printer mouse-pointer"></i>
                        <i className="mdi mdi-help-circle-outline ms-2 mouse-pointer">  </i> */}
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("lc-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("lc-date-search-box").style.display="none"; }}></i>
                      
                      </div>
                    </div>
                    {/* LINE CHART DATE FILTER START */}
                    <div id="lc-date-search-box" style={{display:"none"}}>
                    <hr />
                    <p style={{marginTop:"2rem"}}>Make sure start date and end date are not more than 10 days apart for best results</p>
                    <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="lc_start_date" value={lc_start_date} onChange={updatelc_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="lc_end_date" value={lc_end_date} onChange={updatelc_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setlc_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* LINE CHART DATE FILTER END */}
                      <div className="line-chart-wrapper">
                     <Line data={data1} /> 
                     {/* LINE CHART END */}      
                    </div>
                  </div>
                </div>
              </div>
               {/* LINECHART END */}

                {/* BARCHART START */}
                <div className="col-sm-6 stretch-card grid-margin">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div className="card-title"> Business Summary
                      </div>
                      <div className="d-flex text-muted font-20">
                        <i className="mdi mdi-arrow-down-drop-circle text-primary" onClick={()=>{ document.getElementById("bc-date-search-box").style.display="block"; }}></i>
                        <i className="mdi mdi-close-box text-danger"  onClick={()=>{ document.getElementById("bc-date-search-box").style.display="none"; }}></i>
                      
                      </div>
                    </div>
                        {/*BAR CHART DATE FILTER START */}
                        <div id="bc-date-search-box" style={{display:"none"}}>
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label"> Start Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm"  id="bc_start_date" value={bc_start_date} onChange={updatebc_start_date} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">End Date</label>
                            <div className="col-sm-9">
                              <input type="date" className="form-control-sm" id="bc_end_date" value={bc_end_date} onChange={updatebc_end_date} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-primary mb-4" onClick={()=>{ setbc_default(1); getData(); }}>Update</button>
                      <hr/>
                      </div>
                      {/* BAR CHART DATE FILTER END */}
                      <div className="bar-chart-wrapper">
                      {/* SUMMARY BARCHART START */}
                      <Bar data={data2} />
                      {/* SUMMARY BARCHART END */}
                    </div>
                  </div>
                </div>
              </div>
              {/* BARCHART END */}
          
            </div>

           {/* BUSINESS EDIT FORM START */}
            <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("edit-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Edit Business</h4>
                    <div class="form-sample">
                      <p class="card-description">enter your ebusiness details here</p>
                      <p><code>{error_msg}</code></p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">eBusiness Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="ABC Private Limited" id="ebusiness_name" value={ebusiness_name} /*onChange={updateebusiness_name}*/ />
                              <p class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</p>
                            <code>{ebusiness_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" placeholder="Select eBusiness Type"  id="ebusiness_type" value={ebusiness_type} onChange={updateebusiness_type}>
                              <option value="">Select eBusiness Type</option>
                              <option value="Public Limited Company">Public Limited Company</option>
                              <option value="Private Limited Company">Private Limited Company</option>
                              <option value="One Person Company (OPC)">One Person Company (OPC)</option>
                              <option value="Limited Liability Partnership (LLP)">Limited Liability Partnership</option>
                              <option value="Partnership">Partnership</option>
                              <option value="Sole Proprietorship">Sole Proprietorship</option>
                              <option value="Hindu Undivided Family eBusiness (HUFB)">Hindu Undivided Family eBusiness (HUFB)</option>
                              <option value="Others">Others</option>
                              </select>
                              <code>{ebusiness_type_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="abccompany@company.com"  id="ebusiness_email" value={ebusiness_email} onChange={updateebusiness_email} />
                            {ebusiness_email_er}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="9100000000000"  id="ebusiness_contact" value={ebusiness_contact} onChange={updateebusiness_contact} />
                            <code>{ebusiness_contact_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Category"  id="ebusiness_category" value={ebusiness_category} onChange={updateebusiness_category} />
                            <code>{ebusiness_category_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">WhatsApp</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter WhatsApp Number"  id="ebusiness_whatsapp" value={ebusiness_whatsapp} onChange={updateebusiness_whatsapp} />
                            <code>{ebusiness_whatsapp_er}</code>
                            </div>
                          </div>
                        </div>
                       
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Address</label>
                        <textarea  class="form-control" rows="2"  id="ebusiness_address" value={ebusiness_address} onChange={updateebusiness_address} ></textarea>
                       <code>{ebusiness_address_er}</code>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="Enter City"  id="ebusiness_city" value={ebusiness_city} onChange={updateebusiness_city} />
                            <code>{ebusiness_city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter State"  id="ebusiness_state" value={ebusiness_state} onChange={updateebusiness_state} />
                            <code>{ebusiness_state_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="Enter Country"  id="ebusiness_country" value={ebusiness_country} onChange={updateebusiness_country} />
                            <code>{ebusiness_country_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Pincode"  id="ebusiness_pincode" value={ebusiness_pincode} onChange={updateebusiness_pincode} />
                            <code>{ebusiness_pincode_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">GSTIN</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="Enter GSTIN"  id="ebusiness_gstin" value={ebusiness_gstin} onChange={updateebusiness_gstin} />
                           <code>{ebusiness_gstin_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">License No.</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter License No."  id="ebusiness_licenseno" value={ebusiness_licenseno} onChange={updateebusiness_licenseno} />
                            <code>{ebusiness_licenseno_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Website</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="Enter Website"  id="ebusiness_website" value={ebusiness_website} onChange={updateebusiness_website} />
                            <code>{ebusiness_website_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date Established</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" placeholder=""  id="ebusiness_doe" value={ebusiness_doe} onChange={updateebusiness_doe} />
                            <code>{ebusiness_doe_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Industry</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="Enter Industry"  id="ebusiness_industry" value={ebusiness_industry} onChange={updateebusiness_industry} />
                            <code>{ebusiness_industry_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Sector</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Sector"  id="ebusiness_sector" value={ebusiness_sector} onChange={updateebusiness_sector} />
                            <code>{ebusiness_sector_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">About</label>
                        <textarea  class="form-control"  rows="2"   id="ebusiness_about" value={ebusiness_about} onChange={updateebusiness_about} ></textarea>
                      <code>{ebusiness_about_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Details</label>
                        <textarea  class="form-control"  rows="2"  id="ebusiness_details" value={ebusiness_details} onChange={updateebusiness_details} ></textarea>
                      <code>{ebusiness_details_er}</code>
                      </div>
                    
                      <button class="btn-lg btn-warning" style={{fontWeight:"bold"}} onClick={editbusiness} >Edit Business</button>
                        
                       
    
                     
                   
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* BUSINESS EDIT FORM END */}


            {/*} image card row starts here */}
            <div class="row">
            
            {
                       data.map((curElm,ind)=>{

return(
  <>
            {/* CARD 1 */}
              <div class="col-sm-4 stretch-card grid-margin" key={curElm.Busy_id}>
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://img.freepik.com/free-vector/newsstand-concept-illustration_114360-15989.jpg" alt="" style={{height:"36vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <h6 class="text-muted  mb-0">#{curElm.Busy_id}</h6>
                      <i class="mdi mdi-heart-outline"></i>
                    </div>
                    <h4 class="font-weight-semibold">{curElm.Business_name}</h4>
                    <hr/>
                   
                    <div class="d-flex justify-content-between ">
                      <p class="mb-0">
                        <i class="mdi mdi-phone-in-talk text-primary pe-1"></i>{curElm.Contact}
                      </p>
                    </div>
                    <div class="d-flex justify-content-between ">
                        <p class="mb-0"><i class="mdi mdi-gmail pe-1 text-warning"></i> {curElm.Email}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0">
                        <span class=" font-weight-semibold">Type: </span> {curElm.Type}
                      </p>
                      </div>
                    <div class="d-flex justify-content-between">
                        <p class="mb-0"><span class=" font-weight-semibold">GSTIN: </span> {curElm.Gstin}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                        <p class="mb-0"><span class=" font-weight-semibold">License No.:</span> {curElm.License_no}</p>
                    </div>
                    <p class="text-muted  mt-3 mb-3">{curElm.About}</p>
                    <button class="btn btn-success" onClick={()=>{ 
                      //Setting Business Id,name and redirecting to counters page
                      sessionStorage.setItem("BusyId00",curElm.Busy_id);sessionStorage.setItem("BusyName00",curElm.Business_name); window.location.replace("/businesstools-pos")
                    }}>Start Working</button>
                     
                    <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                      <button type="button" class="btn btn-social-icon" hidden={logtype=="user"} onClick={()=>{ 
                        //Setting Busy Id and Redirect to Edit
                      sessionStorage.setItem("BusyId00",curElm.Busy_id);getbusinessdatabyId();document.getElementById('edit-form').style.display = 'block'; }}>
                      <i class="mdi mdi-pencil-box text-warning" style={{fontSize:"2rem"}}></i>
                      </button>
                      <button type="button" class="btn btn-social-icon" hidden={logtype=="user"} onClick={()=>{ 
                        //Setting Busy Id for Delete
                      sessionStorage.setItem("DBusyId00",curElm.Busy_id);document.getElementById('delete-card').style.display = 'block'; }}>
                      <i class="mdi mdi-delete-forever text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    </div>
                    </div>
                    </div>
              
                    </>
      )
      }
					   
			)}

           </div>
                  
                
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Transactions History</h4>
                    <p class="card-description">your transactions today
                    </p>
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}}>Tr_no</th>
                            <th  style={{fontWeight:"bold"}}>Business Id</th>
                            <th  style={{fontWeight:"bold"}}>Transaction</th>
                            <th  style={{fontWeight:"bold"}}>Date_created</th>
                            <th  style={{fontWeight:"bold"}}>Time_created</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                      transaction_history.map((curElm1,ind)=>{

return(
  <>
                        <tr key={curElm1.Transaction_id}>
                            <td>{curElm1.Tr_no}</td>
                            <td>{curElm1.Busy_id}</td>
                            <td>{curElm1.Particular}</td>
                            <td>{curElm1.Date_created}</td>
                            <td>{curElm1.Time_created}</td>       
                          </tr>
                          </>
      )
      }
					   
			)}
                       
                        </tbody>
                      </table>
                    </div>
                        {/* PAGINATION SECTION START */}
                        <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}
                  </div>
                </div>
              </div>
            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

   }

//}
export default Businesshome;