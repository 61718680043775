import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';
import axios from '../../axios.js';


function Otp_verify(){

    //Fetching Sessions set in Registartion
    const otp_sn = sessionStorage.getItem("otp_sn");
    const otp_id = sessionStorage.getItem("otp_id");

      //otp
  const[otp,setotp]=useState();const updateotp = (event) => { setotp(event.target.value);};  const[otp_er,setotp_er]=useState();

  //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();

  /* OTP VERIFICATION FUNCTION START */
  function otpverification(){



  //Checking if variables set
 if(((otp_sn!=null)||(otp_sn!=""))&&((otp_id!=null)||(otp_id!=" "))){
 
    const otp_value = otp;

    if(otp_value == " "){ seterror_msg("OTP is required! "); return; }
 
    axios.post("bt-admin-register-email-otp-verify", {
     Admin_id:otp_id,
     Admin_sn:otp_sn,
     Otp:otp_value
  
    })
    .then((response) => {
  

    //Invalid Entries
    if(response.data.error==106){     seterror_msg(response.data.error_msg);  }
  
     //Transaction Entered Successfully
    else if(response.data.successvar==1){        
      //remove sessions
      sessionStorage.removeItem("otp_sn");
      sessionStorage.removeItem("otp_id");

      //redirect to login successcard
      document.getElementById("success-card").style.display="block"; }
     //Cannot Add Transaction
     else{  seterror_msg("Cannot Verify OTP !"); return; }
    
  
  });
  }
  //Variables Not Set
else{
 alert("Email Verification Failed!");
}
   
    
    
  }
  /* OTP VERIFIACTION FUNCTION END */

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

    <div class="container-fluid page-body-wrapper">


    {/*}   <Navbar /> */}
        {/*} partial */}
        <div class="main-panel">

        <div class="d-flex justify-content-center">
          {/* SUCCESS CARD START */}
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Email Verified Successfully!!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                    {/*}  <a href="/businesstools-login"><button class="mt-3 btn btn-outline-success">Continue</button></a> */}
                      <a href="/businesstools-login"><button class="mt-3 btn btn-outline-success">Continue</button></a> 
                    </blockquote>
                  </div>
              
                </div>
              </div>
              {/* SUCCESS CARD END */}
            </div>

                <div class="card">
                 
                  <div class="card-body">
                    <h4 class="card-title">Verify OTP</h4>
                    <div class="form-sample">
                      <p class="card-description">enter your six digit OTP sent to <a class="text-primary">help.startupconnectgmail.com</a> valid for 10 minutes.</p>
                      <code>{error_msg}</code>
                     <div class="row">
                        <div class="col-md-9">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label" style={{textAlign:"left"}}>Enter OTP</label>
                            <div class="col-sm-8">
                            <input type="text" class="form-control" style={{fontWeight:"bold"}} placeholder="451421" id="otp" value={otp} onChange={updateotp} />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group row">
                          <button class="btn btn-primary btn-lg" style={{fontWeight:"bold"}} onClick={otpverification}>Verify OTP</button>
                          </div>
                        </div>   
                        </div>
                        <div style={{textAlign:"left"}}>
                        <button class="btn text-primary">Resend OTP</button>
                        </div>
    
                     
                   
                    </div>
                  </div>
                </div>

                
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);

}

export default Otp_verify;