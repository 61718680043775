import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';
import axios from '../../axios.js';

function Forget_pass(){

    
  //username
  const[username,setusername]=useState();const updateusername = (event) => { setusername(event.target.value);}; const[username_er,setusername_er]=useState();
  //email
  const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};  const[email_er,setemail_er]=useState();
  //contact
  const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);};  const[contact_er,setcontact_er]=useState();

  //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();

  function forgetpass(){

    
//Setting Error Variables
 setusername_er(" ");setemail_er(" ");setcontact_er(" ");
seterror_msg(" ");


//Fetching Form Data
  let noerror = 0;
  const $username = document.getElementById("username").value; if($username==""){ setusername_er("Username is required !");noerror = noerror+1; }//Fetching Username Value
  const $email = document.getElementById("email").value; if($email==""){ setemail_er("Email is required !");noerror = noerror+1; }//Fetching Email Value
  const $contact = document.getElementById("contact").value; if($contact==""){ setcontact_er("Contact is required !");noerror = noerror+1; }//Fetching contact Value
   

//No primary errors
if(noerror==0){
  //Sending Data through axios
  axios.post("bt-reset-pass-generate-otp", { 
   Username:$username,
   Email:$email,
   Contact:$contact
 
})

 .then((response) => {
   

   //Validation Errors
 if(response.data.validatorerror){
   setusername_er(response.data.validatorerror.Username);
   setcontact_er(response.data.validatorerror.Contact);
   setemail_er(response.data.validatorerror.Email);
   return;
 }
 //Invalid Entries
 if(response.data.error==102){ 
    
    setusername_er(response.data.username_er); 
    setemail_er(response.data.email_er); 
    setcontact_er(response.data.contact_er); 
    return;

}


 //Success
 if(response.data.successvar==1){
  
  //Setting Sessions for OTP verification
  sessionStorage.setItem("otp_sn", response.data.admin_sn);
  sessionStorage.setItem("otp_id", response.data.admin_id);
  //redirect to otp verification page
  window.location.replace("businesstools-fgotpverify");

 //window.location.replace("/businesstools-startworking");
 //document.getElementById("success-card").style.display="block";
 
 }
 //failure
 if(response.data.successvar==6){
 seterror_msg("Cannot Generate OTP! ");
 return
 }
 if(response.data.error==105){
    seterror_msg("Credentials Don't Match !");
    return;
 }
}    );

}
//Return Errors
else{
  return;
  }

  }

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

    <div class="container-fluid page-body-wrapper">


    {/*}   <Navbar /> */}
        {/*} partial */}
        <div class="main-panel">
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card">
        <div class="card" style={{textAlign:"left"}}>
                  <div class="card-body">
                 
                    <h2 class="card-title" style={{fontSize:"2rem"}}>Forget Password</h2>
                    <p>Reset in Just Few Steps</p>
                   
                    <p  style={{textAlign:"center"}}><code>{error_msg}</code></p>
                    <div class="forms-sample">
                      <div class="form-group">
                        <label for="exampleInputUsername1">Username</label>
                        <input type="text" class="form-control" id="username" placeholder="ABC123" value={username} onChange={updateusername} />
                        <code>{username_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Email</label>
                        <input type="email" class="form-control" id="email" placeholder="abc123@gmail.com" value={email} onChange={updateemail} />
                        <code>{email_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputcontact1">Contact</label>
                        <input type="contact" class="form-control" id="contact" placeholder="910000000000" value={contact} onChange={updatecontact} />
                        <code>{contact_er}</code>
                      </div>
                      <div class="form-check form-check-flat form-check-primary">
                        {/*<label class="form-check-label">
                          <input type="checkbox" class="form-check-input" /> Remember me </label> */}
                      </div>
                      <button type="submit" id="login-btn" class="btn-lg btn-primary me-2" onClick={forgetpass}>Send Email </button> or 
                      <button class="btn text-primary">reset using Pass Key</button>
                      <br/>
                      
                      {/*<div style={{textAlign:"right"}}><a href="">Forget contact</a></div>*/}
                    </div>
                  
                  </div>
                </div>
                </div>
                </div>
                  
             

                
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);

}

export default Forget_pass;